/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Modal,
  DirtyFormAlert,
  Label,
  TextInput,
  Selector,
  Notification,
} from 'shared/components';
import api from 'shared/api';
import {
  getCategories,
  getSegments,
} from 'redux/kiosk/actions';
import './styles.scss';

const mapSegmentsToSelector = list => list.map(l => ({ value: l, label: l }));

const getInitialSegment = (productSegment, list = []) => {
  const segment = list.find(i => i === productSegment);
  if (!productSegment || !segment) {
    return ([]);
  }
  return ([{ value: segment, label: segment }]);
};

const mapDataToSelector = (list, labelField, valueField) => list.map(c => ({
  value: c[valueField],
  label: c[labelField],
  data: c,
}));

const getInitialCategory = (productCategory, list = []) => {
  const category = list.find(i => i.name === productCategory);
  if (!productCategory || !category) {
    return ([]);
  }
  return ([{
    value: get(category, 'name'),
    label: get(category, 'name'),
    data: category,
  }]);
};

const getInitialUsageMaterialValues = (productValues, list = []) => {
  if (!productValues || !productValues.length) {
    return [];
  }
  const foundInList = [];
  productValues.forEach(pv => {
    const found = list.find(l => l.code === pv.code);
    if (found) {
      const item = { label: found.code, value: found.code, data: found };
      foundInList.push(item);
    }
  });
  return foundInList;
};

const ProductForm = ({
  product,
  isOpen,
  closeCb,
  refetchProducts,
}) => {
  const productID = get(product, 'ean');
  const locationID = useSelector(state => get(state, 'kiosk.activeLocation.id') || null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);

  const segmentsList = useSelector(state => get(state, 'kiosk.segments') || []);
  const categoriesList = useSelector(state => get(state, 'kiosk.categories') || []);
  const usagesList = useSelector(state => get(state, 'kiosk.usages') || []);
  const materialsList = useSelector(state => get(state, 'kiosk.materials') || []);

  // product fields
  const [model, setModel] = useState(get(product, 'model') || '');
  const [modelError, setModelError] = useState('');
  const [ean, setEan] = useState(get(product, 'ean') || '');
  const [eanError, setEanError] = useState('');
  const [code, setCode] = useState(get(product, 'code') || '');
  const [powerSource, setPowerSource] = useState(get(product, 'power_source') || '');
  const [power, setPower] = useState(get(product, 'power') || '');
  const [voltage, setVoltage] = useState(get(product, 'voltage') || '');
  const [scopeOfDelivery, setScopeOfDelivery] = useState(get(product, 'scope_of_delivery') || '');

  const [selectedSegment, setSelectedSegment] = useState(getInitialSegment(get(product, 'segment'), segmentsList));
  const [shouldNewSegment, setShouldNewSegment] = useState(false);
  const [newSegment, setNewSegment] = useState('');
  const [newSegmentError, setNewSegmentError] = useState('');

  const [selectedCategory, setSelectedCategory] = useState(getInitialCategory(get(product, 'category.name'), categoriesList));
  const [shouldNewCategory, setShouldNewCategory] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [newCategoryError, setNewCategoryError] = useState('');

  const [selectedUsages, setSelectedUsages] = useState(getInitialUsageMaterialValues(get(product, 'usages'), usagesList));
  const [selectedMaterials, setSelectedMaterials] = useState(getInitialUsageMaterialValues(get(product, 'materials'), materialsList));

  // additional fields
  const [details, setDetails] = useState(Object.entries(get(product, 'details') || {}));

  const isDataValid = () => {
    if (!model) {
      setModelError(t('Ovo polje je obavezno'));
      return false;
    }
    if (!ean) {
      setEanError(t('Ovo polje je obavezno'));
      return false;
    }
    return true;
  };

  const updateProduct = (formData) => {
    api.patch(`/api/v1/products/${productID}/?location=${locationID}`, formData)
      .then(() => {
        refetchProducts();
        Notification('success', t('Promjene su uspješno spremljene'));

        if (shouldNewSegment && !!newSegment) {
          dispatch(getSegments(locationID));
        }

        if (shouldNewCategory && !!newCategory) {
          dispatch(getCategories(locationID));
        }
        closeCb();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Dogodila se pogreška'));
      });
  };

  const createProduct = (formData) => {
    api.post(`/api/v1/products/?location=${locationID}`, formData)
      .then(() => {
        refetchProducts();
        Notification('success', t('Promjene su uspješno spremljene'));

        if (shouldNewSegment && !!newSegment) {
          dispatch(getSegments(locationID));
        }

        if (shouldNewCategory && !!newCategory) {
          dispatch(getCategories(locationID));
        }
        closeCb();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Dogodila se pogreška'));
      });
  };

  const handleClose = () => {
    if (isDirty) {
      setDirtyFormAlertDisplay(true);
    } else {
      closeCb();
    }
  };

  const getDetailItem = (index) => {
    const itemKey = document.getElementById(`key-${index}`).value;
    const itemValue = document.getElementById(`value-${index}`).value;

    return ({
      [itemKey]: itemValue,
    });
  };

  const getDetailsObject = () => {
    const obj = {};
    details.forEach((d, i) => {
      const item = getDetailItem(i);
      Object.assign(obj, item);
    });
    return obj;
  };

  const handleSubmit = () => {
    const isValid = isDataValid();
    if (isLoading || !isValid) {
      return false;
    }

    setLoading(true);
    const detailsObject = getDetailsObject();
    const data = {
      ean: product ? undefined : ean,
      model,
      code,
      power,
      power_source: powerSource,
      voltage,
      scope_of_delivery: scopeOfDelivery,
      details: detailsObject,
      segment: (shouldNewSegment && !!newSegment) ? newSegment : get(selectedSegment, '[0].value'),
      category: (shouldNewCategory && !!newCategory) ? newCategory : get(selectedCategory, '[0].value'),
      usages: selectedUsages.map(s => s.value),
      materials: selectedMaterials.map(s => s.value),
      product_locations: [locationID],
    };

    if (product) {
      updateProduct(data);
    } else {
      createProduct(data);
    }
    return true;
  };

  return (
    <Modal
      size="lg"
      closeCb={handleClose}
      isOpen={isOpen}
      title={product ? t('Uredi proizvod') : t('Dodaj proizvod')}
    >
      <div className="ProductForm">
        <div className="ProductForm-main">
          <div className="ProductForm-left">
            <div className="row">
              <Label text={`${t('Ime proizvoda')}*`} inputId="model" />
              <TextInput
                value={model}
                error={modelError}
                onChange={e => {
                  const val = e.target.value;
                  setDirty(true);
                  setModel(val);
                  setModelError('');
                }}
                name="model"
                id="model"
              />
            </div>
            <div className="row">
              <Label text={`${t('EAN')}*`} inputId="ean" />
              <TextInput
                value={ean}
                error={eanError}
                onChange={e => {
                  const val = e.target.value;
                  setDirty(true);
                  setEan(val);
                  setEanError('');
                }}
                name="ean"
                id="ean"
                disabled={!!product}
              />
            </div>
            <div className="row">
              <Label text={t('Kod')} inputId="code" />
              <TextInput
                value={code}
                onChange={e => {
                  const val = e.target.value;
                  setDirty(true);
                  setCode(val);
                }}
                name="code"
                id="code"
              />
            </div>
            <div className="row">
              <Label text={t('Izvor napajanja')} inputId="power_source" />
              <TextInput
                value={powerSource}
                onChange={e => {
                  const val = e.target.value;
                  setDirty(true);
                  setPowerSource(val);
                }}
                name="power_source"
                id="power_source"
              />
            </div>
            <div className="row">
              <Label text={t('Snaga')} inputId="power" />
              <TextInput
                value={power}
                onChange={e => {
                  const val = e.target.value;
                  setDirty(true);
                  setPower(val);
                }}
                name="power"
                id="power"
              />
            </div>
            <div className="row">
              <Label text={t('Napon')} inputId="voltage" />
              <TextInput
                value={voltage}
                onChange={e => {
                  const val = e.target.value;
                  setDirty(true);
                  setVoltage(val);
                }}
                name="voltage"
                id="voltage"
              />
            </div>
            <div className="row">
              <Label text={t('Opseg isporuke')} inputId="scope_of_delivery" />
              <TextInput
                value={scopeOfDelivery}
                onChange={e => {
                  const val = e.target.value;
                  setDirty(true);
                  setScopeOfDelivery(val);
                }}
                name="scope_of_delivery"
                id="scope_of_delivery"
              />
            </div>
          </div>
          <div className="divider" />
          <div className="ProductForm-right">
            <div className="row">
              <Label text={t('Segment')} inputId="segment" />
              <Selector
                options={mapSegmentsToSelector(segmentsList)}
                values={selectedSegment}
                onChange={val => {
                  setDirty(true);
                  setSelectedSegment(val);
                }}
                labelField="label"
                valueField="value"
                searchable
                disabled={shouldNewSegment}
              />
            </div>
            <div className="row-checkbox">
              <Checkbox
                id="new-segment"
                name="new-segment"
                label="Dodaj novi segment"
                checked={shouldNewSegment}
                onChange={e => setShouldNewSegment(e.target.checked)}
              />
              {shouldNewSegment && (
                <TextInput
                  value={newSegment}
                  error={newSegmentError}
                  onChange={e => {
                    const val = e.target.value;
                    setDirty(true);
                    setNewSegment(val);
                    setNewSegmentError('');
                  }}
                  name="new-segment"
                  id="new-segment"
                  placeholder="Ime segmenta"
                />
              )}
            </div>
            <div className="row">
              <Label text={t('Kategorija')} inputId="category" />
              <Selector
                options={mapDataToSelector(categoriesList, 'name', 'name')}
                values={selectedCategory}
                onChange={val => {
                  setDirty(true);
                  setSelectedCategory(val);
                }}
                labelField="label"
                valueField="value"
                searchable
                disabled={shouldNewCategory}
              />
            </div>
            <div className="row-checkbox">
              <Checkbox
                id="new-segment"
                name="new-segment"
                label={t('Dodaj novu kategoriju')}
                checked={shouldNewCategory}
                onChange={e => setShouldNewCategory(e.target.checked)}
              />
              {shouldNewCategory && (
                <TextInput
                  value={newCategory}
                  error={newCategoryError}
                  onChange={e => {
                    const val = e.target.value;
                    setDirty(true);
                    setNewCategory(val);
                    setNewCategoryError('');
                  }}
                  name="new-category"
                  id="new-category"
                  placeholder="Ime kategorije"
                />
              )}
            </div>
            <div className="row multi-select">
              <Label text={t('Materijal')} inputId="materials" />
              <Selector
                options={mapDataToSelector(materialsList, 'code', 'code')}
                values={selectedMaterials}
                onChange={val => {
                  setDirty(true);
                  setSelectedMaterials(val);
                }}
                labelField="label"
                valueField="value"
                searchable
                multi
              />
            </div>
            <div className="row multi-select">
              <Label text={t('Upotreba')} inputId="usages" />
              <Selector
                options={mapDataToSelector(usagesList, 'code', 'code')}
                values={selectedUsages}
                onChange={val => {
                  setDirty(true);
                  setSelectedUsages(val);
                }}
                labelField="label"
                valueField="value"
                searchable
                multi
              />
            </div>
          </div>
        </div>
        <div className="ProductForm-extra">
          <div className="extra-header">{t('Dodatna polja')}</div>
          <div className="extra-label-wrapper">
            <Label text={t('Naslov')} inputId="key" />
            <Label text={t('Vrijednost')} inputId="value" />
          </div>
          {details.map((d, index) => (
            <div className="detail-row" key={JSON.stringify(d)}>
              <div className="key input-row">
                <TextInput
                  // value={d[0]}
                  // onChange={(e) => {
                  //   const val = e.target.value;
                  //   const newDetails = details.map((item, i) => {
                  //     if (index !== i) {
                  //       return item;
                  //     }
                  //     item[0] = val;
                  //     return item;
                  //   });
                  //   setDetails(newDetails);
                  // }}
                  defaultValue={d[0]}
                  onChange={() => {
                    setDirty(true);
                  }}
                  name={`key-${index}`}
                  id={`key-${index}`}
                />
              </div>
              <div className="value input-row">
                <TextInput
                  // value={d[1]}
                  // onChange={(e) => {
                  //   const val = e.target.value;
                  //   const newDetails = details.map((item, i) => {
                  //     if (index !== i) {
                  //       return item;
                  //     }
                  //     item[1] = val;
                  //     return item;
                  //   });
                  //   setDetails(newDetails);
                  // }}
                  defaultValue={d[1]}
                  onChange={() => {
                    setDirty(true);
                  }}
                  name={`value-${index}`}
                  id={`value-${index}`}
                />
              </div>
              <div className="delete-btn">
                <Button
                  size="sm"
                  theme="error"
                  onClick={() => {
                    const newArr = details.filter((di, i) => i !== index);
                    setDirty(true);
                    setDetails(newArr);
                  }}
                >
                  {t('Obriši')}
                </Button>
              </div>
            </div>
          ))}
          <div>
            <Button size="sm" priority="secondary" onClick={() => setDetails([...details, ['', '']])}>{t('Novo polje')}</Button>
          </div>
        </div>
        <div className="ProductForm-actions">
          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {t('Potvrdi')}
          </Button>
          <Button
            type="button"
            theme="error"
            priority="tertiary"
            onClick={handleClose}
            disabled={isLoading}
          >
            {t('Poništi')}
          </Button>
        </div>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={() => {
              setDirtyFormAlertDisplay(false);
              closeCb();
            }}
          />
        )}
      </div>
    </Modal>
  );
};

ProductForm.propTypes = {
  product: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  refetchProducts: PropTypes.func.isRequired,
};

ProductForm.defaultProps = {
  product: null,
};

export default ProductForm;
