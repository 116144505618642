import React from 'react';
import PropTypes from 'prop-types';

const IconChevronDown = ({
  color, size, strokeWidth, disabled,
}) => (
  <div className="IconChevronDown" style={{ display: 'flex' }}>
    <svg fill={disabled ? '#BFC0C2' : color} height={size} width={size} viewBox="0 0 192 192">
      <path
        stroke={disabled ? '#BFC0C2' : color}
        strokeWidth={strokeWidth}
        d="M96 133.7L33.2 70.8l5.6-5.6L96 122.3l57.2-57.1 5.6 5.6z"
      />
    </svg>
  </div>
);

IconChevronDown.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  strokeWidth: PropTypes.number,
};

IconChevronDown.defaultProps = {
  color: '#37383B',
  disabled: false,
  size: '16px',
  strokeWidth: 2,
};

export default IconChevronDown;
