import React from 'react';
import PropTypes from 'prop-types';

const IconClose = ({ color, size }) => (
  <div className="IconClose">
    <svg fill={color} width={size} height={size} viewBox="0 0 192 192">
      <path
        stroke={color}
        strokeWidth={5}
        d="M158.8 38.8l-5.6-5.6L96 90.3 38.8 33.2l-5.6 5.6L90.3 96l-57.1 57.2 5.6 5.6L96 101.7l57.2 57.1 5.6-5.6L101.7 96z"
      />
    </svg>
  </div>
);

IconClose.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconClose.defaultProps = {
  color: '#37383B',
  size: '16px',
};

export default IconClose;
