import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import './styles.scss';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
    padding: '50px 0',
  },
  content: {
    position: 'relative',
    top: '25vh',
  },
};

const InactivityPopup = ({
  isOpen, handleClose, onTimerEnd, children, ui, ...rest
}) => {
  const kioskUI = useSelector(state => get(state, 'kiosk.ui') || {});
  const time = get(kioskUI, 'inactivityPopup.inactivity_time_counter') || 30;
  const [counter, setCounter] = useState(Number(time));

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      onTimerEnd();
    }
  }, [counter, onTimerEnd]);

  // Si še vedno tam? Kiosk se bo v prvi korak vrnil čez 30 sekund

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={handleClose}
      closeTimeoutMS={150}
      {...rest}
      className="InactivityPopup"
    >
      <div className="InactivityPopup-inner">
        <div className="popup-body">
          <div className="heading">
            {get(ui, 'inactivityPopup.title')}
          </div>
          <div className="timer">
            {`${counter}s`}
          </div>
          <div className="close-instructions">{get(ui, 'inactivityPopup.close_text')}</div>
        </div>
      </div>
    </ReactModal>
  );
};

InactivityPopup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onTimerEnd: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

InactivityPopup.defaultProps = {
  children: null,
};

export default InactivityPopup;
