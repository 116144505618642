import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { get } from 'lodash';
import { isBlagajna } from 'shared/helpers';
import { ProgressIndicator, Notification } from 'shared/components';
import { logout, logoutSuccess, logoutError } from 'redux/account/actions';
import {
  BackBtn,
  BoschLogo,
  Breadcrumbs,
  CartBtn,
  RestartBtn,
  InactivityPopup,
} from '../components';
import {
  isInitialStep,
  isDynamicStep,
  isRecommendationStep,
} from '../assets/helpers';
import CartPage from '../CartPage';
import StepContainer from '../StepContainer';
import './styles.scss';

const KioskContainer = ({
  logoutAction,
  logoutSuccessAction,
  logoutErrorAction,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const kioskUI = useSelector(state => get(state, 'kiosk.ui') || {});

  const [showCartPage, setCartPageVisible] = useState(false);
  const [cartProducts, setCartProducts] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [progresStep, setProgresStep] = useState(0);
  const [progresTotal, setProgresTotal] = useState(3);
  const [isInactive, setInactive] = useState(false);
  const [salesArea, setSalesArea] = useState(null);
  const [customStep, setCustomStep] = useState(null);
  const [productsCategories, setProductsCategories] = useState([]);

  const [showOrderPopup, setOrderPopupShow] = useState(false);

  const inactivitySeconds = get(kioskUI, 'inactivityPopup.inactivity_time') || 20;

  // eslint-disable-next-line no-unused-vars
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * Number(inactivitySeconds),
    // eslint-disable-next-line no-unused-vars
    onIdle: (event) => {
      // console.log('user is idle', event);
      // console.log('last active', getLastActiveTime());
      if (salesArea) {
        setInactive(true);
      }
    },
    // onActive: (event) => {
    //   console.log('user is active again', event);
    //   console.log('time remaining', getRemainingTime());
    //   // setInactive(false);
    // },
    // onAction: (event) => console.log('user did something', event),
    debounce: 500,
  });

  if (isBlagajna()) {
    return <Redirect to="/blagajna" />;
  }

  const handleLogout = () => {
    logoutAction()
      .then(() => {
        logoutSuccessAction();
        history.push('/login');
        Notification('success', t('Uspješno ste odjavljeni'));
      })
      .catch(() => {
        logoutErrorAction();
        Notification('error', t('Dogodila se pogreška'), t('Odjava nije uspjela'));
      });
  };

  const handleInactivityRestart = () => {
    setSalesArea(null);
    setCustomStep(null);
    setProductsCategories([]);
    setAnswers([]);
    setCartProducts([]);
    setCartPageVisible(false);
    setInactive(false);
    setOrderPopupShow(false);
  };

  const handleRestart = () => {
    setSalesArea(null);
    setCustomStep(null);
    setProductsCategories([]);
    setAnswers([]);
    setProgresStep(0);
    setProgresTotal(3);
  };

  const handleSalesAreaSelect = val => setSalesArea(val);

  const handleCustomStepSubmit = val => setCustomStep(val);

  const handleSelection = (val) => {
    setCustomStep(null);
    setAnswers([...answers, val]);
  };

  const handleBack = () => {
    if (!answers.length) {
      return setSalesArea(null);
    }
    const newAnswerList = answers.slice(0, -1);
    setCustomStep(null);
    setProductsCategories([]);
    return setAnswers(newAnswerList);
  };

  const handleBreadcrumb = (answerID) => {
    const answerIndex = answers.findIndex(a => a.id === answerID);
    const newAnswerList = answers.slice(0, answerIndex);
    setAnswers(newAnswerList);
    setCustomStep(null);
    setProductsCategories([]);
  };

  const handleProductSelection = product => {
    const isProductInCart = cartProducts.find(cp => cp.ean === product.ean);

    if (!isProductInCart) {
      const products = [...cartProducts, product];
      return setCartProducts(products);
    }

    const newProducts = cartProducts.map(cp => {
      if (cp.ean === product.ean) {
        const newProduct = { ...cp, quantity: cp.quantity + 1 };
        return newProduct;
      }
      return cp;
    });
    return setCartProducts(newProducts);
  };

  const removeCartProduct = product => {
    if (product === null) {
      return setCartProducts([]);
    }
    if (!product) {
      return setCartProducts(cartProducts);
    }
    const products = cartProducts.filter(p => p.ean !== product.ean);
    return setCartProducts([...products]);
  };

  const handleProductQuantity = (val, productid) => {
    const products = cartProducts.map(p => {
      if (p.ean === productid) {
        const newP = {
          ...p,
          quantity: val,
        };
        return newP;
      }
      return p;
    });

    setCartProducts(products);
  };

  const handleProgressChange = (answerLength, count) => {
    const total = count <= answerLength ? answerLength + 1 : count;
    setProgresStep(answerLength);
    setProgresTotal(total);
  };

  const handleLessThan10Products = (branchingCategories) => {
    setProductsCategories(branchingCategories);
    setProgresStep(progresTotal);
  };

  const isInitial = isInitialStep(answers, salesArea, customStep);
  const isDynamic = isDynamicStep(answers, salesArea, customStep);
  const isRecommendation = isRecommendationStep(answers, salesArea, customStep, productsCategories);

  return (
    <div className="KioskContainer">
      <div className="logout-btn">
        <button
          className="logout"
          onClick={handleLogout}
          type="button"
        >
          {t('Odjava')}
        </button>
      </div>
      <div className="KioskContainer-topBanner" />
      <div className="KioskContainer-wizzard">
        <ProgressIndicator activeStep={progresStep} totalSteps={progresTotal} />
        <div className={`wizzard-container ${showCartPage ? 'hide' : 'show'}`}>
          <div className="KioskStep">
            <CartBtn
              cartProducts={cartProducts}
              handleProductQuantity={handleProductQuantity}
              removeCartProduct={removeCartProduct}
              showCartPage={setCartPageVisible}
              ui={kioskUI}
            />
            <div className="top-banner">
              <BoschLogo />
              <Breadcrumbs
                answers={answers}
                salesArea={salesArea}
                handleBreadcrumbClick={val => handleBreadcrumb(val)}
                handleRestart={handleRestart}
              />
            </div>
            <div className="step-actions step-actions-mobile">
              {
                isDynamic && !isRecommendation && (
                  <BackBtn ui={kioskUI} handleBack={handleBack} />
                )
              }
              {
                isRecommendation && (
                  <RestartBtn ui={kioskUI} handleRestart={handleRestart} />
                )
              }
              {
                showCartPage && (
                  <BackBtn ui={kioskUI} handleBack={() => showCartPage(false)} />
                )
              }
            </div>
            <StepContainer
              answers={answers}
              handleProductSelection={handleProductSelection}
              handleSelection={handleSelection}
              ui={kioskUI}
              isInitial={isInitial}
              isDynamic={isDynamic}
              isRecommendation={isRecommendation}
              handleProgressChange={handleProgressChange}
              handleRestart={handleRestart}
              showCartPage={(val) => setCartPageVisible(val)}
              removeCartProduct={removeCartProduct}
              handleBack={handleBack}
              salesArea={salesArea}
              handleSalesAreaSelect={handleSalesAreaSelect}
              customStep={customStep}
              handleCustomStepSubmit={handleCustomStepSubmit}
              handleLessThan10Products={handleLessThan10Products}
              productsCategories={productsCategories}
            />
          </div>
        </div>
        <div className={`cartPage-container ${showCartPage ? 'show' : 'hide'}`}>
          <CartPage
            cartProducts={cartProducts}
            removeCartProduct={removeCartProduct}
            handleRestart={handleRestart}
            ui={kioskUI}
            handleProductQuantity={handleProductQuantity}
            showCartPage={(val) => setCartPageVisible(val)}
            showOrderPopup={showOrderPopup}
            handleOrderPopupDisplay={() => setOrderPopupShow(!showOrderPopup)}
          />
        </div>
      </div>
      {
        isInactive && (
          <InactivityPopup
            isOpen={isInactive}
            handleClose={() => setInactive(false)}
            ui={kioskUI}
            onTimerEnd={handleInactivityRestart}
          />
        )
      }
    </div>
  );
};

KioskContainer.propTypes = {
  logoutAction: PropTypes.func.isRequired,
  logoutErrorAction: PropTypes.func.isRequired,
  logoutSuccessAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  logoutAction: logout,
  logoutErrorAction: logoutError,
  logoutSuccessAction: logoutSuccess,
};

export default connect(null, mapDispatchToProps)(KioskContainer);
