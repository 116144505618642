import api from 'shared/api';

export const getOrders = (
  locationId, page, perPage, sortDirection, sortColumn, searchType, searchQuery,
) => {
  const offsetPage = page - 1;
  const offset = (!page || offsetPage < 1) ? 0 : offsetPage * perPage;
  const sortDir = sortDirection === 'desc' ? '-' : '';

  const searchBy = searchQuery ? `&${searchType.value}__icontains=${searchQuery}` : '';
  const sortBy = (sortDirection && sortColumn) ? `&order_by=${sortDir}${sortColumn}` : '';

  return api.get(`/api/v1/orders/?location=${locationId}&offset=${offset}&limit=${perPage}${searchBy}${sortBy}`);
};
