import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import NoImage from 'screens/kiosk/assets/imgs/noImagePlaceholder.jpg';
import {
  Page,
  PageHeader,
  Spinner,
  Table,
} from 'shared/components';
import {
  defaultDateTimeFormat,
} from 'shared/constants';
import { isKiosk } from 'shared/helpers';
import { getOrder } from './actions';
import TopHeader from '../../../../topHeader';
import TableExpandedRow from './TableExpandedRow';
import './styles.scss';

const getImage = (photos) => {
  if (!photos || !photos.length) {
    return NoImage;
  }

  const mainPhoto = photos.find(p => p.main);
  return get(mainPhoto, 'photo') || NoImage;
};

const OrderContainer = () => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const userRole = useSelector(state => get(state, 'account.user.role') || null);
  const activeLocation = useSelector(state => get(state, 'kiosk.activeLocation'));
  const locationID = get(activeLocation, 'id');

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);

  const fetchOrder = () => {
    const { orderID } = params;

    getOrder(orderID, locationID)
      .then((res) => {
        setOrder(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: t('Slika proizvoda'),
      selector: 'product.segment',
      sortable: false,
      hide: 'sm',
      cell: row => (
        <div className="image-container">
          <img src={getImage(get(row, 'product.photos'))} alt={get(row, 'product.model')} />
        </div>
      ),
    },
    {
      name: t('Količina'),
      selector: 'quantity',
      sortable: false,
    },
    {
      name: t('Ime proizvoda'),
      selector: 'product.model',
      sortable: false,
    },
    {
      name: t('Kategorija'),
      selector: 'product.category.name',
      sortable: false,
      hide: 'sm',
      format: (row) => {
        if (!get(row, 'product.category.name')) {
          return '-';
        }
        return get(row, 'product.category.name');
      },
    },
    {
      name: t('EAN'),
      selector: 'product.ean',
      sortable: false,
      hide: 'sm',
    },
    {
      name: t('Segment'),
      selector: 'product.segment',
      sortable: false,
      hide: 'sm',
    },
  ];

  if (loading) {
    return (
      <Page>
        <div className="OrderContainer">
          <TopHeader userRole={userRole} />
          <PageHeader title={t('Blagajna')} description={t('Detalji narudžbe')} />
          <div className="OrderContainer-main">
            <Spinner />
          </div>
        </div>
      </Page>
    );
  }

  if (isKiosk()) {
    return <Redirect to="/kiosk" />;
  }

  return (
    <Page>
      <div className="OrderContainer">
        <TopHeader userRole={userRole} />
        <PageHeader title={t('Blagajna')} description={t('Detalji narudžbe')} />
        <div className="OrderContainer-main">
          <div className="breadcrumb">
            <button
              type="button"
              onClick={() => history.push('/blagajna')}
            >
              {t('Natrag na listu narudžbi')}
            </button>
          </div>
          <div className="order-body">
            <div className="order-id order-row">{`${t('Broj narudžbe')}: ${order.id}`}</div>
            <div className="order-date order-row">{`${t('Datum')}: ${moment(order.created_at).format(defaultDateTimeFormat)}`}</div>
            <div className="order-products">
              <Table
                columns={columns}
                data={order.order_products}
                pagination={false}
                expandableRows
                expandableRowsComponent={<TableExpandedRow />}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default OrderContainer;
