import React from 'react';
import PropTypes from 'prop-types';
import { IoIosEye } from 'react-icons/io';

const IconPassVisible = ({ color, size }) => (
  <div className="IconPassVisible">
    <IoIosEye color={color} size={size} />
  </div>
);

IconPassVisible.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconPassVisible.defaultProps = {
  color: '#37383B',
  size: '16px',
};

export default IconPassVisible;
