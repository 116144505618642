import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import {
  Button,
  DirtyFormAlert,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import { validationSchema } from './validation';
import { resetUserPassword } from './actions';
import './styles.scss';

const ResetPasswordForm = ({
  closeCb,
  isOpen,
  companyID,
  user,
  title,
}) => {
  const { t } = useTranslation();

  const {
    register, handleSubmit, errors,
  } = useForm({ validationSchema });

  const [loading, setLoading] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);

  const onSubmit = (data) => {
    const { password } = data;
    const userID = get(user, 'id');
    if (loading) {
      return false;
    }

    setLoading(true);

    resetUserPassword(companyID, userID, { password })
      .then(() => {
        closeCb();
        Notification('success', t('Promjene su uspješno spremljene'));
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Dogodila se pogreška'));
      });
    return true;
  };

  return (
    <Modal
      closeCb={closeCb}
      isOpen={isOpen}
      disabled={loading}
      title={title}
      size="sm"
      shouldCloseOnOverlayClick={false}
    >
      <div className="ResetPasswordForm">
        <form
          className="user-form"
          id="user-add-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <TextInput
              label={t('Email')}
              id="user-email"
              name="email"
              type="email"
              disabled
              value={get(user, 'email')}
            />
          </div>
          <div>
            <TextInput
              label={t('Lozinka')}
              id="user-password"
              name="password"
              disabled={loading}
              register={register}
              error={t(get(errors, 'password.message'))}
            />
          </div>
          <div className="user-actions">
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
            >
              {t('Potvrdi')}
            </Button>
            <Button
              type="button"
              theme="error"
              priority="tertiary"
              onClick={closeCb}
              disabled={loading}
            >
              {t('Poništi')}
            </Button>
          </div>
        </form>
      </div>
      {
        isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={() => {
              setDirtyFormAlertDisplay(false);
              closeCb();
            }}
          />
        )
      }
    </Modal>
  );
};

ResetPasswordForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  companyID: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  title: PropTypes.string,
};

ResetPasswordForm.defaultProps = {
  title: '',
};

export default ResetPasswordForm;
