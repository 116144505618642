import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { IconReset, IconChevronLeft } from 'shared/components';
import './styles.scss';

const AddToCartPreview = ({
  kioskUI,
}) => (
  <div className="AddToCartPreview PreviewPane">
    <div className="content">
      <div className="popup">
        <div className="title">{get(kioskUI, 'addToCartPopup.title')}</div>
        <div className="popup-actions">
          <div className="actionBtn restart">
            <IconReset size="20px" />
            {get(kioskUI, 'actionBtns.restart_btn')}
          </div>
          <div className="actionBtn restart">
            <IconChevronLeft size="20px" />
            {get(kioskUI, 'addToCartPopup.continue_btn')}
          </div>
          <div className="actionBtn order">
            {get(kioskUI, 'addToCartPopup.view_order_btn')}
          </div>
          <div className="actionBtn cancel">
            {get(kioskUI, 'addToCartPopup.cancel_btn')}
          </div>
        </div>
        <div className="close-instructions">{get(kioskUI, 'addToCartPopup.close_text')}</div>
      </div>
    </div>
  </div>
);

AddToCartPreview.propTypes = {
  kioskUI: PropTypes.object.isRequired,
};

export default AddToCartPreview;
