import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'shared/components';
import './styles.scss';

const LoadingTable = ({ loadingText }) => (
  <div className="LoadingTable">
    <Spinner text={loadingText} />
  </div>
);

LoadingTable.propTypes = {
  loadingText: PropTypes.string.isRequired,
};

export default LoadingTable;
