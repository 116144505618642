import React from 'react';
import PropTypes from 'prop-types';

const IconAlert = ({ color, size }) => (
  <div className="IconAlert">
    <svg fill={color} width={size} height={size} viewBox="0 0 192 192">
      <path
        fill={color}
        d="M175.783 160H16.217L96 15.735 175.783 160zm-146-8h132.434L96 32.265 29.783 152zm73.328-15.111c0 3.89-3.222 7.111-7.111 7.111-4 0-7.111-3.11-7.111-7.111 0-4 3.11-7.111 7.111-7.111 3.889 0 7.111 3.222 7.111 7.11zm-12.223-48V64h10.223v24.889l-2.667 30.667h-4.888l-2.668-30.667z"
      />
    </svg>
  </div>
);

IconAlert.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconAlert.defaultProps = {
  color: '#fcaf17',
  size: '16px',
};

export default IconAlert;
