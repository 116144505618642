import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { IconCart } from 'shared/components';
import { ReactComponent as EmptyCartImage } from 'screens/kiosk/assets/imgs/empty-cart.svg';
import NoImage from 'screens/kiosk/assets/imgs/noImagePlaceholder.jpg';
import './styles.scss';

const CartBtnPreview = ({
  kioskUI,
}) => (
  <div className="CartBtnPreview PreviewPane">
    <div className="content">
      <div className="cartBtn">
        <IconCart />
        <div className="cartBtn-title">{get(kioskUI, 'cartBtn.title')}</div>
      </div>
      <div className="cartBtnContainer cartBtn-full">
        <div className="title">{get(kioskUI, 'cartBtn.title')}</div>
        <div className="product">
          <div className="image-container">
            <img src={NoImage} alt="placeholder"/>
          </div>
          <div className="text-container">
            <div className="shadow" />
            <div className="shadow" />
          </div>
        </div>
        <div className="actions">
            <div className="actionBtn order">
              {get(kioskUI, 'cartBtn.view_order_btn')}
            </div>
            <div className="actionBtn cancel">
              {get(kioskUI, 'cartBtn.cancel_btn')}
            </div>
          </div>
      </div>
      <div className="cartBtnContainer cartBtn-empty">
        <div className="title">{get(kioskUI, 'cartBtn.empty_cart_title')}</div>
        <EmptyCartImage />
        <div className="close">{get(kioskUI, 'cartBtn.close_text')}</div>
      </div>
    </div>
  </div>
);

CartBtnPreview.propTypes = {
  kioskUI: PropTypes.object.isRequired,
};

export default CartBtnPreview;
