import React from 'react';
import PropTypes from 'prop-types';

const IconChevronLeftDouble = ({
  color, size, strokeWidth, disabled,
}) => (
  <div className="IconChevronLeft" style={{ display: 'flex' }}>
    <svg viewBox="0 0 192 192" fill={disabled ? '#BFC0C2' : color} height={size} width={size}>
      <path
        stroke={disabled ? '#BFC0C2' : color}
        strokeWidth={strokeWidth}
        d="M145.3 158.9l-67-62.8 62.9-62.9 5.6 5.6-57.1 57.1 61 57.2z"
      />
      <path
        stroke={disabled ? '#BFC0C2' : color}
        strokeWidth={strokeWidth}
        d="M105.3 158.9l-67-62.8 62.9-62.9 5.6 5.6-57.1 57.1 61 57.2z"
      />
    </svg>
  </div>
);

IconChevronLeftDouble.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  strokeWidth: PropTypes.number,
};

IconChevronLeftDouble.defaultProps = {
  color: '#37383B',
  disabled: false,
  size: '16px',
  strokeWidth: 2,
};

export default IconChevronLeftDouble;
