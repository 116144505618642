import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminContainer from 'screens/admin/AdminContainer';
import StoreContainer from 'screens/store/StoreContainer';
import OrderContainer from 'screens/store/StoreContainer/OrderContainer';
import KioskContainer from 'screens/kiosk/KioskContainer';

const AppContainer = () => (
  <div className="AppContainer">
    <Switch>
      <Route path="/blagajna" exact component={StoreContainer} />
      <Route path="/blagajna/:orderID" component={OrderContainer} />
      <Route path="/kiosk" component={KioskContainer} />
      <Route path="/" component={AdminContainer} />
    </Switch>
  </div>
);

export default AppContainer;
