import React, {
  useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FileUploader,
  IconDownload,
  Notification,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  Table,
  StatusCell,
} from 'shared/components';
import {
  formatDate,
} from 'shared/helpers';
import api from 'shared/api';
import {
  getImportList,
  getExportList,
  exportProducts,
} from './actions';
import TopHeader from '../../../../topHeader';
import TableExpandedRow from './components/TableExpandedRow';
import './styles.scss';

const ProductImport = ({
  userRole,
}) => {
  const { t } = useTranslation();
  const fetchImportIdRef = useRef(0);
  const fetchExportIdRef = useRef(0);
  const locationId = useSelector(state => get(state, 'kiosk.activeLocation.id'));
  const validFileTypes = '.xlsx';

  const [ignoreExisting, setIgnoreExisting] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  // import table state
  const [listData, setListData] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  // export table state
  const [exportListData, setExportListData] = useState([]);
  const [exportListLoading, setExportListLoading] = useState(true);

  const [productExportLoading, setProductExportLoading] = useState(false);

  // import table methods
  const fetchImportData = async () => {
    // Give this fetch an ID
    const fetchId = ++fetchImportIdRef.current;
    const response = await getImportList(locationId);

    // Only update the data if this is the latest fetch
    if (fetchId === fetchImportIdRef.current) {
      setListData(response.data.results);
      setListLoading(false);
    }
  };

  // export table methods
  const fetchExportData = async () => {
    // Give this fetch an ID
    const fetchId = ++fetchExportIdRef.current;
    const response = await getExportList(locationId);

    // Only update the data if this is the latest fetch
    if (fetchId === fetchExportIdRef.current) {
      setExportListData(response.data.results);
      setExportListLoading(false);
    }
  };

  useEffect(() => {
    fetchImportData();
    fetchExportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchImportData();
      fetchExportData();
    }, 30 * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const handleWrongFileType = () => {
    // Notification('error', 'Pogrešna vrsta datoteke', Samo .csv i .xlsx datoteke se mogu uvesti');
    Notification('error', t('Pogrešna vrsta datoteke'), `${t('Mogu se prenositi samo datoteke')}: ${validFileTypes}`);
  };

  const handleSubmitClick = (val) => {
    const file = get(val, 'file');
    setUploading(true);
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', file);

    const ignoreExistingParams = ignoreExisting ? '' : '?ignore_existing=0';

    api.post(`/api/v1/locations/${locationId}/import_products/${ignoreExistingParams}`, data, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      },
    })
      .then(() => {
        Notification('success', t('Datoteka uvezena'), t('Postupak uvoza proizvoda je započeo'));
        val.remove();
        setUploading(false);
      })
      .catch(() => {
        Notification('error', t('Prijenos nije uspio'), t('Pokušajte ponovo ili promijenite datoteku'));
        setUploading(false);
      });
  };

  const handleItemRemove = () => {
    setUploading(false);
    setProgress(0);
  };

  const handleProductExport = () => {
    setProductExportLoading(true);
    exportProducts(locationId)
      .then(() => {
        setProductExportLoading(false);
        Notification('success', t('Izvoz datoteke je započeo'), t('Link na dokument će ubrzo biti prikazan u listi izvoza'));
        fetchExportData();
      })
      .catch(() => {
        setProductExportLoading(false);
        Notification('error', t('Dogodila se pogreška'));
      });
  };

  const columns = [
    {
      name: t('Ime datoteke'),
      selector: 'file_name',
      sortable: true,
    },
    {
      name: t('Status'),
      selector: 'status',
      sortable: true,
      cell: row => <StatusCell status={row.status} />,
    },
    {
      name: t('Datum'),
      selector: 'time_create',
      sortable: true,
      format: row => formatDate(get(row, 'time_create')),
    },
    {
      name: t('Korisnik'),
      selector: 'user.email',
      sortable: true,
    },
  ];

  const exportColumns = [
    {
      name: t('Ime datoteke'),
      selector: 'file_name',
      sortable: true,
    },
    {
      name: t('Status'),
      selector: 'status',
      sortable: true,
      cell: row => <StatusCell status={row.status} />,
    },
    {
      name: t('Datum'),
      selector: 'time_create',
      sortable: true,
      format: row => formatDate(get(row, 'time_create')),
    },
    {
      name: t('Korisnik'),
      selector: 'user.email',
      sortable: true,
    },
    {
      name: t('Preuzmi'),
      id: 'edit',
      sortable: false,
      ignoreRowClick: true,
      maxWidth: '120px',
      cell: row => {
        const isSuccess = get(row, 'status') === 'success';
        if (isSuccess) {
          return (
            <div className="product-active-cell">
              <a href={get(row, 'file')} target="_blank" rel="noopener noreferrer">
                <IconDownload size="24px" />
              </a>
            </div>
          );
        }
        return '-';
      },
    },
  ];

  return (
    <div className="ProductImport">
      <TopHeader userRole={userRole} />
      <Checkbox
        inputId="ignore-existing"
        name="ignore-existing"
        label={t('Zanemari postojeće proizvode')}
        checked={ignoreExisting}
        onChange={e => setIgnoreExisting(e.target.checked)}
      />
      <FileUploader
        disabled={uploading}
        progress={progress}
        onSubmit={handleSubmitClick}
        onWrongFileTypeReject={handleWrongFileType}
        onItemRemove={handleItemRemove}
        label={t('Povucite datoteke ili kliknite da biste ih pregledali')}
        subLabel={`${t('Vrste datoteka')}:`}
        validFileTypes={validFileTypes}
      />
      <Tabs forceRenderTabPanel>
        <TabsHeader>
          <Tab>{t('Lista uvoza')}</Tab>
          <Tab>{t('Lista izvoza')}</Tab>
        </TabsHeader>
        <TabContent>
          <div className="ProductImport-table">
            <Table
              columns={columns}
              data={listData}
              loading={listLoading}
              defaultSortField="time_create"
              defaultSortAsc={false}
              pagination
              paginationServer={false}
              sortServer={false}
              expandableRows={false}
              expandableRowsComponent={<TableExpandedRow />}
            // clickable
            />
          </div>
        </TabContent>
        <TabContent>
          <div className="ProductImport-table">
            <Button loading={productExportLoading} disabled={productExportLoading} size="sm" onClick={handleProductExport}>{t('Izvezi novi dokument')}</Button>
            <Table
              columns={exportColumns}
              data={exportListData}
              loading={exportListLoading}
              defaultSortField="time_create"
              defaultSortAsc={false}
              pagination
              paginationServer={false}
              sortServer={false}
              expandableRows={false}
              expandableRowsComponent={<TableExpandedRow />}
            // clickable
            />
          </div>
        </TabContent>
      </Tabs>
    </div>
  );
};


ProductImport.propTypes = {
  userRole: PropTypes.string,
};

ProductImport.defaultProps = {
  userRole: '',
};

export default ProductImport;
