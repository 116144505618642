import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Spinner = ({ text }) => (
  <div className="Spinner">
    <div className="loader">
      <div className="inner one" />
      <div className="inner two" />
      <div className="inner three" />
    </div>
    <div className="Spinner-text">{text}</div>
  </div>
);

Spinner.propTypes = {
  text: PropTypes.string,
};

Spinner.defaultProps = {
  text: '',
};

export default Spinner;
