import React from 'react';
import PropTypes from 'prop-types';

const IconCart = ({ color, size }) => (
  <div className="IconCart">
    <svg width={size} height={size} viewBox="0 0 192 192" fill={color}>
      <path
        d="M161.3 40.2c-4.1-1.4-9.1-.8-13.4 1.4-4 2.1-6.7 5.4-7.7 9.3l-1.4 5.1H32c-8.8 0-16 7.2-16 16 0 1.4.2 2.8.5 4.1l12.1 40.3c2 6.8 8.3 11.6 15.4 11.6h75.1l-2.3 8.3c-6 1.2-10.8 5.8-12.3 11.7h-41c-1.8-6.9-8-12-15.5-12-8.8 0-16 7.2-16 16s7.2 16 16 16c7.4 0 13.7-5.1 15.5-12h41.1c1.8 6.9 8 12 15.5 12 8.8 0 16-7.2 16-16 0-7.1-4.6-13.1-11-15.2L147.9 53c.5-1.7 1.8-3.3 3.8-4.4 2.3-1.2 5-1.6 7.1-.9l12 4 2.5-7.6-12-3.9zM48 160c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm-4-40c-3.5 0-6.7-2.4-7.7-5.8L24.3 74c-.2-.6-.3-1.3-.3-2 0-4.4 3.6-8 8-8h104.6l-15.3 56H44zm84 32c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8 8 3.6 8 8z"
      />
    </svg>
  </div>
);

IconCart.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconCart.defaultProps = {
  color: '#1E2328',
  size: '24px',
};

export default IconCart;
