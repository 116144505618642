import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import { Button } from 'shared/components';
import './styles.scss';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
    padding: '50px 0',
  },
  content: {
    position: 'relative',
    top: '25vh',
  },
};

const OrderPopup = ({
  disabled,
  isOpen,
  handleClose,
  children,
  emptyCart,
  handleRestart,
  closeCartPage,
  ui,
  cartProducts,
  ...rest
}) => {
  const cartString = cartProducts.map(cp => (
    `${cp.model} - ${get(cp, 'category.name') || ''} (${cp.code}) - kolicina: ${cp.quantity}; `
  ));

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={handleClose}
      closeTimeoutMS={150}
      shouldCloseOnOverlayClick={false}
      {...rest}
      className="OrderPopup"
    >
      <div className="OrderPopup-inner">
        <div className="popup-header">{get(ui, 'orderPopup.title')}</div>
        <div className="popup-body">
          <div className="order-number">{children}</div>
          <div className="order-qr-code">
            <QRCode value={`Broj narudzbe: ${children} - ${cartString}`} />
          </div>
          <div className="order-instructions">
            {get(ui, 'orderPopup.directions')}
          </div>
          <div className="order-restart-kiosk">
            <Button
              onClick={() => {
                emptyCart();
                handleClose();
                handleRestart();
                closeCartPage(false);
              }}
              size="lg"
              disabled={disabled}
            >
              {get(ui, 'orderPopup.restart_btn')}
            </Button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

OrderPopup.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  cartProducts: PropTypes.array.isRequired,
  emptyCart: PropTypes.func.isRequired,
  handleRestart: PropTypes.func.isRequired,
  closeCartPage: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

OrderPopup.defaultProps = {
  disabled: false,
};

export default OrderPopup;
