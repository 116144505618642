import api from 'shared/api';

export const getProducts = (
  locationId, searchType, searchQuery,
) => {
  const searchBy = searchQuery ? `&${searchType.value}__icontains=${searchQuery}` : '';
  return api.get(`/api/v1/products/?location=${locationId}&limit=50${searchBy}&slim=1`);
};

export const addProductsToPromotion = (locationID, promotionID, data) => api.post(`/api/v1/featuredgroups/${promotionID}/add_products/?location=${locationID}`, data);
