import React from 'react';
import { NavLink } from 'react-router-dom';
import { isAdmin } from 'shared/helpers';
import Logo from '../../assets/imgs/bosch-logo.svg';

const BoschLogo = () => {
  if (isAdmin()) {
    return (
      <NavLink className="BoschLogo" exact to="/">
        <img draggable="false" className="logo" src={Logo} alt="bosch logo" />
      </NavLink>
    );
  }

  return (
    <div
      onDragStart={() => false}
      onDrop={() => false}
      className="BoschLogo"
    >
      <img draggable="false" className="logo" src={Logo} alt="bosch logo" />
    </div>
  );
};

export default BoschLogo;
