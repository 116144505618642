import api from 'shared/api';

export const getProducts = (
  locationId, page, perPage, sortDirection, sortColumn, searchType, searchQuery,
) => {
  const offsetPage = page - 1;
  const offset = (!page || offsetPage < 1) ? 0 : offsetPage * perPage;
  const sortDir = sortDirection === 'desc' ? '-' : '';
  let sort;
  if (sortColumn === 'category.name') {
    sort = 'category__name';
  } else if (sortColumn === 'product_location.is_active') {
    sort = 'product_location__is_active';
  } else if (sortColumn === 'product_location.is_featured') {
    sort = 'product_location__is_featured';
  } else {
    sort = sortColumn;
  }

  const searchBy = searchQuery ? `&${searchType.value}__icontains=${searchQuery}` : '';
  const sortBy = (sortDirection && sortColumn) ? `&order_by=${sortDir}${sort}` : '';

  return api.get(`/api/v1/products/?location=${locationId}&offset=${offset}&limit=${perPage}${searchBy}${sortBy}`);
};

export const patchProductLocation = (locationId, data) => api.patch(`/api/v1/productlocations/${locationId}/`, data);

export const bulkActiveStatusChange = (locationId, data) => api.post(`/api/v1/products/bulk_update_is_active/?location=${locationId}`, data);
