import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  DirtyFormAlert,
  Label,
  TextInput,
  Notification,
} from 'shared/components';
import api from 'shared/api';
import { getMaterials } from 'redux/kiosk/actions';
import './styles.scss';

const NewMaterialForm = ({
  isOpen,
  closeCb,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);

  const [materialName, setMaterialName] = useState('');
  const [materialNameError, setMaterialNameError] = useState('');

  const materialsList = useSelector(state => get(state, 'kiosk.materials') || []);
  const locationID = useSelector(state => get(state, 'kiosk.activeLocation.id') || null);

  const isDataValid = () => {
    if (!materialName) {
      setMaterialNameError(t('Ovo polje je obavezno'));
      return false;
    }
    const doesExist = materialsList.find(m => m.code === materialName);
    if (doesExist) {
      setMaterialNameError(t('Materijal već postoji'));
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const isValid = isDataValid();
    if (isLoading || !isValid) {
      return false;
    }

    setLoading(true);
    const data = {
      code: materialName,
    };

    api.post('/api/v1/materials/', data)
      .then(() => {
        dispatch(getMaterials(locationID));
        Notification('success', t('Promjene su uspješno spremljene'));
        closeCb();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Dogodila se pogreška'));
      });
    return true;
  };

  const handleClose = () => {
    if (isDirty) {
      setDirtyFormAlertDisplay(true);
    } else {
      closeCb();
    }
  };

  return (
    <Modal
      size="sm"
      closeCb={handleClose}
      isOpen={isOpen}
      title={t('Dodaj materijal')}
    >
      <div className="NewMaterialForm">
        <form className="NewMaterialForm-form" onSubmit={handleSubmit}>
          <div className="form-inner">
            <div className="row">
              <Label text={t('Materijal')} inputId="material-name" />
              <TextInput
                id="material-name"
                name="material-name"
                value={materialName}
                error={materialNameError}
                onChange={(e) => {
                  const val = e.target.value;
                  setDirty(true);
                  setMaterialName(val);
                  setMaterialNameError('');
                }}
              />
            </div>
          </div>
          <div className="form-actions">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {t('Potvrdi')}
            </Button>
            <Button
              type="button"
              theme="error"
              priority="tertiary"
              onClick={handleClose}
              disabled={isLoading}
            >
              {t('Poništi')}
            </Button>
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={() => {
              setDirtyFormAlertDisplay(false);
              closeCb();
            }}
          />
        )}
      </div>
    </Modal>
  );
};

NewMaterialForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
};

export default NewMaterialForm;
