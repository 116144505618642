import api from 'shared/api';
import { get } from 'lodash';

export const getRecommendedProducts = (locationID, previousAnswer, productsCategories) => {
  const ID = get(previousAnswer, 'id');
  const productsCategoriesIDs = productsCategories.join(',');
  const productsParam = productsCategories.length ? productsCategoriesIDs : ID;

  const branchingCategoryParam = `&branching_categories__in=${productsParam}`;
  const url = `/api/v1/products/?location=${locationID}${branchingCategoryParam}`;

  return api.get(url);
};
