import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Ovo polje je obavezno')
    .email('Unesite valjanu e-adresu'),
  name: yup.string().required('Ovo polje je obavezno'),
  password: yup.string()
    .required('Ovo polje je obavezno')
    .min(8, 'Lozinka je prekratka - trebala bi imati najmanje 8 znakova')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Lozinka mora sadržavati 8 znakova, jedan veliki, jedan mali, jedan broj i jedan poseban znak',
    ),
});
