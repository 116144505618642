import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components';
import { checkUserRole } from 'shared/helpers';
import TopHeader from 'topHeader';
import icon from './404_page_not_found.svg';
import './styles.scss';

const NotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user') || null);
  const userRole = checkUserRole(user);

  return (
    <div className="NotFound-container">
      <TopHeader userRole={userRole} />
      <img className="notfound-img" src={icon} alt="Not found" />
      <p>{t('Čini se da ne možemo pronaći stranicu koju tražite.')}</p>
      <Button
        onClick={() => {
          history.push('/');
        }}
      >
        {t('Idi na početnu stranicu')}
      </Button>
    </div>
  );
};

export default NotFound;
