import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// import 'moment/locale/hr';
import { SingleDatePicker } from 'react-dates';
import { defaultDateFormat } from 'shared/constants';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './styles.scss';

const DatePicker = ({
  date,
  onDateSelect,
  id,
  placeholder,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();
  const [isFocused, setFocus] = useState(false);

  // useEffect(() => {
  //   moment.locale('pt-br');
  // }, []);

  return (
    <div className="DatePicker">
      <SingleDatePicker
        hideKeyboardShortcutsPanel
        date={date}
        onDateChange={onDateSelect}
        focused={isFocused}
        onFocusChange={({ focused }) => setFocus(focused)}
        id={id}
        numberOfMonths={1}
        displayFormat={defaultDateFormat}
        placeholder={placeholder || t('Datum')}
        renderMonthElement={({ month }) => moment(month).locale('hr').format('MM-YYYY')}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

DatePicker.propTypes = {
  date: PropTypes.object,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onDateSelect: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  date: null,
  disabled: false,
  placeholder: '',
};

export default DatePicker;
