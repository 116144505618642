import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  IconChevronRight,
  IconChevronRightDouble,
  IconChevronLeft,
  IconChevronLeftDouble,
} from 'shared/components';
import './styles.scss';

const getNumberOfPages = (rowCount, rowsPerPage) => Math.ceil(rowCount / rowsPerPage);

const TablePagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
  paginationRowsPerPageOptions,
  loading,
}) => {
  const { t } = useTranslation();
  const numPages = getNumberOfPages(rowCount, rowsPerPage);
  const lastIndex = currentPage * rowsPerPage;
  const firstIndex = (lastIndex - rowsPerPage) + 1;
  const disabledLesser = currentPage === 1;
  const disabledGreater = currentPage === numPages;
  const range = currentPage === numPages
    ? `${t('Stranica')} ${firstIndex}-${rowCount} ${t('od')} ${rowCount}`
    : `${t('Stranica')} ${firstIndex}-${lastIndex} ${t('od')} ${rowCount}`;

  const handlePrevious = useCallback(() => onChangePage(currentPage - 1), [currentPage, onChangePage]);
  const handleNext = useCallback(() => onChangePage(currentPage + 1), [currentPage, onChangePage]);
  const handleFirst = useCallback(() => onChangePage(1), [onChangePage]);
  const handleLast = useCallback(() => onChangePage(getNumberOfPages(rowCount, rowsPerPage)), [onChangePage, rowCount, rowsPerPage]);
  const handleRowsPerPage = useCallback(({ target }) => onChangeRowsPerPage(Number(target.value), currentPage), [currentPage, onChangeRowsPerPage]);

  const selectOptions = paginationRowsPerPageOptions.map(num => (
    <option
      key={num}
      value={num}
    >
      {num}
    </option>
  ));

  const select = (
    <select onChange={handleRowsPerPage} defaultValue={rowsPerPage}>
      {selectOptions}
    </select>
  );

  return (
    <nav className={`TablePagination ${loading ? 'TablePagination-loading' : ''}`}>
      <div className="TablePagination-perPage">
        <span>{t('Redaka po stranici')}</span>
        {select}
      </div>
      <div className="TablePagination-actions">
        <button
          id="pagination-first-page"
          type="button"
          aria-label="First Page"
          aria-disabled={disabledLesser}
          onClick={handleFirst}
          disabled={disabledLesser}
        >
          <IconChevronLeftDouble disabled={disabledLesser} size="20px" strokeWidth={4} />
        </button>
        <button
          id="pagination-previous-page"
          type="button"
          aria-label="Previous Page"
          aria-disabled={disabledLesser}
          onClick={handlePrevious}
          disabled={disabledLesser}
        >
          <IconChevronLeft disabled={disabledLesser} size="20px" strokeWidth={4} />
        </button>

        <span className="TablePagination-actions-range">
          {range}
        </span>

        <button
          id="pagination-next-page"
          type="button"
          aria-label="Next Page"
          aria-disabled={disabledGreater}
          onClick={handleNext}
          disabled={disabledGreater}
        >
          <IconChevronRight disabled={disabledGreater} size="20px" strokeWidth={4} />
        </button>
        <button
          id="pagination-last-page"
          type="button"
          aria-label="Last Page"
          aria-disabled={disabledGreater}
          onClick={handleLast}
          disabled={disabledGreater}
        >
          <IconChevronRightDouble disabled={disabledGreater} size="20px" strokeWidth={4} />
        </button>
      </div>
    </nav>
  );
};

TablePagination.propTypes = {
  loading: PropTypes.bool.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  paginationRowsPerPageOptions: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
};

TablePagination.defaultProps = {
  paginationRowsPerPageOptions: [10, 15, 20, 25, 30],
};

export default TablePagination;
