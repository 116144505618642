import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Label = ({ inputId, text, description }) => (
  <div className="Label">
    <label htmlFor={inputId} className="Label-elem">
      {text}
    </label>
    {description && <div className="Label-description">{description}</div>}
  </div>
);

Label.propTypes = {
  inputId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Label.defaultProps = {
  description: null,
};

export default Label;
