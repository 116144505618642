/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  Button,
  DirtyFormAlert,
  Label,
  FileUploader,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import { patchTopSalesAreas, patchSalesAreas, patchBranchingCategory } from './actions';
import './styles.scss';

const OptionItemEdit = ({
  option,
  isOpen,
  closeCb,
  confirmCb,
  locationID,
  isTopSalesArea,
}) => {
  const isTopSales = isTopSalesArea();

  const [loading, setLoading] = useState(false);
  const [isImageRowDirty, setImageRowDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [file, setFile] = useState(null);

  const validImageFormats = '.png,.jpg,.jpeg';

  const [t] = useTranslation();
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(t('Ovo polje je obavezno.')),
  });

  const {
    register, handleSubmit, errors, formState,
  } = useForm({
    validationSchema,
    defaultValues: {
      name: get(option, 'name') || '',
    },
  });

  const handlePatchSuccess = (res) => {
    const newData = get(res, 'data');
    setLoading(false);
    confirmCb(newData);
    closeCb();
    Notification('success', t('Promjene su uspješno spremljene'));
  };

  const handlePatchError = () => {
    setLoading(false);
    Notification('error', t('Dogodila se pogreška'));
  };

  const updateTopSalesArea = (formData) => {
    const optionID = get(option, 'id');

    patchTopSalesAreas(optionID, locationID, formData)
      .then(res => handlePatchSuccess(res))
      .catch(err => handlePatchError(err));
  };

  const updateSalesArea = (formData) => {
    const optionID = get(option, 'id');

    patchSalesAreas(optionID, locationID, formData)
      .then(res => handlePatchSuccess(res))
      .catch(err => handlePatchError(err));
  };

  const updateBranchingCategory = (formData) => {
    const optionID = get(option, 'id');

    patchBranchingCategory(optionID, locationID, formData)
      .then(res => handlePatchSuccess(res))
      .catch(err => handlePatchError(err));
  };

  const onSubmit = (data) => {
    const { name } = data;

    if (loading) {
      return;
    }

    setLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('name', name);
    if (file) {
      formData.append('photo', file);
    }

    if (isTopSales) {
      updateTopSalesArea(formData);
    } else if (!get(option, 'sales_area')) {
      updateSalesArea(formData);
    } else {
      updateBranchingCategory(formData);
    }
  };

  const handleWrongFileType = () => {
    Notification('error', t('Pogrešna vrsta datoteke'), `${t('Mogu se prenositi samo datoteke')}: ${validImageFormats}`);
  };

  const { dirty } = formState;

  const handleClose = () => {
    if (dirty) {
      setDirtyFormAlertDisplay(true);
    } else {
      closeCb();
    }
  };

  return (
    <Modal
      closeCb={handleClose}
      isOpen={isOpen}
      size="sm"
    // title={t('Uredi kiosk opciju')}
    >
      <div className="OptionItemEdit">
        <div className="OptionItemEdit-row">
          <TextInput
            label={t('Naslov')}
            id="option-name"
            name="name"
            type="text"
            disabled={loading}
            register={register}
            error={get(errors, 'name.message')}
          />
        </div>
        <div className="OptionItemEdit-row">
          <Label inputId="file-upload" text={t('Slika')} />
          <FileUploader
            disabled={loading}
            onUploadSuccess={(_file, allFiles) => {
              const val = get(allFiles, '[0].file');
              setImageRowDirty(true);
              setFile(val);
            }}
            onWrongFileTypeReject={handleWrongFileType}
            onItemRemove={() => setFile(null)}
            label={t('Povucite datoteke ili kliknite da biste ih pregledali')}
            subLabel={`${t('Vrste datoteka')}:`}
            validFileTypes={validImageFormats}
          />
        </div>
        <div className="OptionItemEdit-row btn-cont">
          <Button disabled={loading} onClick={handleSubmit(onSubmit)}>
            {t('Spremi')}
          </Button>
          <Button disabled={loading} theme="link" priority="tertiary" onClick={handleClose}>
            {t('Poništi')}
          </Button>
        </div>
      </div>
      {
        isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty || isImageRowDirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={() => {
              setDirtyFormAlertDisplay(false);
              closeCb();
            }}
          />
        )
      }
    </Modal>
  );
};

OptionItemEdit.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  locationID: PropTypes.number.isRequired,
  isTopSalesArea: PropTypes.func.isRequired,
  option: PropTypes.object,
};

OptionItemEdit.defaultProps = {
  isOpen: false,
  option: null,
};

export default OptionItemEdit;
