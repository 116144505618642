export const kioskConfigReducer = (state, action) => {
  switch (action.type) {
    case 'mainTitle':
      return { ...state, mainTitle: action.data };
    case 'cartBtn':
      return { ...state, cartBtn: action.data };
    case 'addToCartPopup':
      return { ...state, addToCartPopup: action.data };
    case 'cartPage':
      return { ...state, cartPage: action.data };
    case 'orderPopup':
      return { ...state, orderPopup: action.data };
    case 'recommendationPage':
      return { ...state, recommendationPage: action.data };
    case 'actionBtns':
      return { ...state, actionBtns: action.data };
    case 'inactivityPopup':
      return { ...state, inactivityPopup: action.data };
    default:
      return { ...state };
  }
};
