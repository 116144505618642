import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Label, TextInput,
} from 'shared/components';

const OrderPopupEditor = ({
  kioskState,
  onChange,
}) => {
  const { t } = useTranslation();
  const [popupTitle, setTitle] = useState(get(kioskState, 'orderPopup.title'));
  const [directions, setDirections] = useState(get(kioskState, 'orderPopup.directions'));
  const [restartBtn, setRestartBtn] = useState(get(kioskState, 'orderPopup.restart_btn'));

  return (
    <div className="OrderPopupEditor KioskMetaEditor">
      <div>
        <Label text={t('Naslov')} inputId="orderPopup-title" />
        <TextInput
          value={popupTitle}
          onChange={e => {
            const val = e.target.value;
            setTitle(val);
            onChange({
              type: 'orderPopup',
              data: {
                title: val,
                directions,
                restart_btn: restartBtn,
              },
            });
          }}
          name="orderPopup-title"
          id="orderPopup-title"
        />
      </div>
      <div>
        <Label text={t('Upute')} inputId="orderPopup-directions" />
        <TextInput
          value={directions}
          onChange={e => {
            const val = e.target.value;
            setDirections(val);
            onChange({
              type: 'orderPopup',
              data: {
                title: popupTitle,
                directions: val,
                restart_btn: restartBtn,
              },
            });
          }}
          name="orderPopup-directions"
          id="orderPopup-directions"
        />
      </div>
      <div>
        <Label text={t('Gumb za ponovno pokretanje')} inputId="orderPopup-restart" />
        <TextInput
          value={restartBtn}
          onChange={e => {
            const val = e.target.value;
            setRestartBtn(val);
            onChange({
              type: 'orderPopup',
              data: {
                title: popupTitle,
                directions,
                restart_btn: val,
              },
            });
          }}
          name="orderPopup-restart"
          id="orderPopup-restart"
        />
      </div>
    </div>
  );
};

OrderPopupEditor.propTypes = {
  kioskState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OrderPopupEditor;
