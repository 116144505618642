import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import './styles.scss';

const MainTitlePreview = ({
  kioskUI,
}) => (
  <div className="MainTitlePreview PreviewPane">
    <div className="content">
      <div className="title">{get(kioskUI, 'mainTitle.title')}</div>
      <div className="elements">
        <div className="shadow" />
        <div className="shadow" />
        <div className="shadow" />
      </div>
    </div>
  </div>
);

MainTitlePreview.propTypes = {
  kioskUI: PropTypes.object.isRequired,
};

export default MainTitlePreview;
