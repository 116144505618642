import React from 'react';
import PropTypes from 'prop-types';

const IconEdit = ({ color, size }) => (
  <div className="IconEdit">
    <svg fill={color} width={size} height={size} viewBox="0 0 192 192">
      <path
        stroke={color}
        strokeWidth={2}
        d="M170.7,81.3c-7-7-18.4-7-25.4,0l0,0l0,0l-55,55l-12.7,38.1l38.1-12.7l55-55l0,0C177.7,99.7,177.7,88.3,170.7,81.3z
        M90.3,161.7l5.3-15.9l10.6,10.6L90.3,161.7z M165.1,101L165.1,101l-51.5,51.5l-14.1-14.1L151,86.9c3.9-3.8,10.2-3.8,14.1,0
        C169,90.8,169,97.1,165.1,101z M170.7,81.3c-7-7-18.4-7-25.4,0l-55,55l-12.7,38.1l38.1-12.7l55-55l0,0
        C177.7,99.7,177.7,88.3,170.7,81.3z M90.3,161.7l5.3-15.9l10.6,10.6L90.3,161.7z M165.1,101L165.1,101l-51.5,51.5l-14.1-14.1
        L151,86.9c3.9-3.8,10.2-3.8,14.1,0C169,90.8,169,97.1,165.1,101z M67,153.1l-15.8,9.3l-2.1-1.5c-6.9-5-12.9-11-17.9-17.9l-1.5-2.1
        l9.3-15.8c-1.5-3-2.8-6-3.8-9.2l-17.7-4.6l-0.4-2.6c-0.7-4.2-1-8.4-1-12.6s0.3-8.4,1-12.6l0.4-2.6l17.7-4.6c1-3.1,2.3-6.2,3.8-9.1
        l-9.3-15.8l1.5-2.1c5-6.9,11-12.9,17.9-17.9l2.1-1.5L67,38.9c3-1.5,6-2.8,9.2-3.8l4.6-17.7l2.6-0.4c8.4-1.3,16.9-1.3,25.3,0l2.6,0.4
        l4.6,17.7c3.1,1,6.2,2.3,9.1,3.8l15.8-9.3l2.1,1.5c6.9,5,12.9,11,17.9,17.9l1.5,2.1L153.1,67c0.2,0.5,0.4,0.9,0.7,1.4
        c-2.7,0.4-5.3,1.3-7.7,2.6c-0.3-0.7-0.7-1.4-1.1-2.1l-1.1-2l8.9-15.2c-3.6-4.6-7.8-8.8-12.5-12.5l-15.2,8.9l-2-1.1
        c-3.7-2.1-7.6-3.7-11.7-4.9l-2.2-0.6l-4.4-17c-5.9-0.7-11.7-0.7-17.6,0l-4.4,17l-2.2,0.6c-4.1,1.2-8,2.8-11.7,4.9l-2,1.1l-15.2-8.9
        c-4.6,3.6-8.8,7.8-12.5,12.5l8.9,15.2l-1.1,2c-2.1,3.7-3.7,7.6-4.9,11.7l-0.6,2.2l-17,4.4C24.2,90.1,24,93.1,24,96
        c0,2.9,0.2,5.9,0.5,8.8l17,4.4l0.6,2.2c1.2,4.1,2.8,8,4.9,11.7l1.1,2l-8.9,15.2c3.6,4.6,7.8,8.8,12.5,12.5l15.2-8.9l2,1.1
        c2.8,1.6,5.8,2.9,8.8,4l-2.5,7.6C72.4,155.6,69.6,154.4,67,153.1z M64,96c0-17.6,14.4-32,32-32c15,0,27.6,10.3,31,24.2l-7.1,7.1
        C119.6,82.4,109,72,96,72c-13.2,0-24,10.8-24,24c0,13,10.4,23.6,23.3,24l-7.1,7.1C74.3,123.6,64,111,64,96z"
      />
    </svg>
  </div>
);

IconEdit.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconEdit.defaultProps = {
  color: '#37383B',
  size: '16px',
};

export default IconEdit;
