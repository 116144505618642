/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Range, getTrackBackground } from 'react-range';
import { Button, IconPlus } from 'shared/components';
import { handleACRoomSizeSelect } from 'shared/helpers';
import { categoryTypes } from 'shared/constants';
import { getBranchingCategories } from './actions';
import OptionItem from '../OptionItem';
import AddOptionForm from '../AddOptionForm';
import AddNewStepForm from '../AddNewStepForm';
import './styles.scss';

const OptionsRow = ({
  options,
  isSelected,
  handleAnswerSelect,
  handleEdit,
  fetchLoading,
  selected,
  selectedTopSalesArea,
  locationID,
  fetchTopSalesAreas,
  fetchSalesArea,
  addNewOptions,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState([20]);
  const [ACBtnLoading, setACBtnLoading] = useState(false);
  const [optionFormDisplayed, setOptionFormDisplay] = useState(false);
  const [newStepFormDisplayed, setNewStepFormDisplay] = useState(false);
  const [categoryType, setCategoryType] = useState(null);
  const [rowOptions, setRowOptions] = useState([]);
  const [rowID, setRowID] = useState(null);

  const isACSelected = selected.find(s => get(s, 'id') === 9 && get(s, 'top_sales_area') === 2);

  if (!options || !options.length) {
    return (
      <div className="OptionsRow empty" />
    );
  }

  const handleSliderConfirm = async (index) => {
    setACBtnLoading(true);
    const data = await getBranchingCategories(locationID, selected[0]).then(res => res.data.results);
    setACBtnLoading(false);
    const answer = handleACRoomSizeSelect(values, data, val => val, { name: 'od 100 qm', id: 0 });
    handleAnswerSelect(answer, index);
  };

  const handleCategoryType = (items, row) => {
    if (!items || !items.length) {
      if (row === 0) {
        return setCategoryType(categoryTypes.topSalesArea);
      }
      if (row === 1) {
        setRowOptions([{ top_sales_area: get(selectedTopSalesArea, 'id') }]);
        return setCategoryType(categoryTypes.salesArea);
      }
      if (row === 2) {
        setRowOptions([{ sales_area: get(selected, '[0].id'), parent: '' }]);
        return setCategoryType(categoryTypes.rootBranchingCategory);
      }
      const selectedLength = selected.length;
      setRowOptions([{ sales_area: get(selected, '[0].id'), parent: get(selected, `[${selectedLength - 1}].parent`) }]);
      return setCategoryType(categoryTypes.childBranchingCategory);
    }
    const isTopSalesArea = items.every(i => !i.top_sales_area && !i.sales_area);
    const isSalesArea = items.every(i => i.top_sales_area);
    const isRootBranching = items.every(i => i.sales_area && !i.parent);
    const isChildBranching = items.every(i => i.sales_area && i.parent);

    if (isTopSalesArea) {
      return setCategoryType(categoryTypes.topSalesArea);
    }
    if (isSalesArea) {
      return setCategoryType(categoryTypes.salesArea);
    }
    if (isRootBranching) {
      return setCategoryType(categoryTypes.rootBranchingCategory);
    }
    if (isChildBranching) {
      return setCategoryType(categoryTypes.childBranchingCategory);
    }
    return setCategoryType(null);
  };

  return (
    <div className="OptionsRow">
      {
        // eslint-disable-next-line arrow-body-style
        options.map((oArr, i) => {
          if (isACSelected && i === 1) {
            return (
              <div key={`option-index-${oArr.id}`}>
                <div className="step-indicator">
                  {`${t('Korak')}: ${oArr.id} - Kvadratura prostora`}
                </div>
                <div className="Options-row slider">
                  <Range
                    step={5}
                    min={0}
                    max={200}
                    onChange={val => setValues(val)}
                    // onFinalChange={val => handleSliderSelect(val)}
                    values={values}
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        className="SliderBox-track"
                        role="slider"
                        tabIndex={0}
                        aria-valuemax={200}
                        aria-valuemin={0}
                        aria-valuenow={values[0]}
                      >
                        <div
                          ref={props.ref}
                          className="SliderBox-track-inner"
                          style={{
                            background: getTrackBackground({
                              values,
                              colors: ['#005691', '#BFC0C2'],
                              min: 0,
                              max: 200,
                            }),
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div
                        {...props}
                        className="SliderBox-thumb"
                        style={{
                          ...props.style,
                          backgroundColor: isDragged ? '#008ECF' : '#005691',
                        }}
                      >
                        {values[0]}
                      </div>
                    )}
                  />
                  <div className="submit-btn">
                    <Button
                      onClick={() => handleSliderConfirm(i)}
                      size="lg"
                      theme="success"
                      disabled={ACBtnLoading}
                      loading={ACBtnLoading}
                    >
                      {t('Potvrdi')}
                    </Button>
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div key={`option-index-${oArr.id}`}>
              <div className="step-indicator">
                {
                  oArr.id === 0 ? t('Prodajna područja') : `${t('Korak')}: ${oArr.id}`
                }
              </div>
              <div className="Options-row">
                {
                  // eslint-disable-next-line arrow-body-style
                  oArr.data.map(o => {
                    return (
                      <OptionItem
                        key={`${o.id} - ${o.name}`}
                        isSelected={!!isSelected(o.id)}
                        value={o}
                        handleSelectClick={() => handleAnswerSelect(o, i)}
                        handleEditClick={() => handleEdit(o)}
                        disabled={fetchLoading}
                      />
                    );
                  })
                }
                <button
                  className="add-btn"
                  type="button"
                  onClick={() => {
                    setRowOptions(oArr.data);
                    setRowID(i);
                    handleCategoryType(oArr.data, oArr.id);
                    setOptionFormDisplay(true);
                  }}
                >
                  <IconPlus size="50px" />
                  <div className="text">{t('Dodaj novu kategoriju')}</div>
                </button>
                {
                  (oArr.id > 1 && !!oArr.data.length) && (
                    <button
                      className="add-btn"
                      type="button"
                      onClick={() => {
                        setRowOptions(oArr.data);
                        setRowID(i);
                        setNewStepFormDisplay(true);
                      }}
                    >
                      <IconPlus size="50px" />
                      <div className="text">{t('Dodaj novi korak')}</div>
                    </button>
                  )
                }
              </div>
            </div>
          );
        })
      }
      {optionFormDisplayed && (
        <AddOptionForm
          isOpen={optionFormDisplayed}
          closeCb={() => {
            setOptionFormDisplay(false);
            setRowOptions([]);
            setRowID(null);
            setCategoryType(null);
          }}
          locationID={locationID}
          confirmCb={(val) => {
            if (categoryType === categoryTypes.topSalesArea) {
              return fetchTopSalesAreas();
            }
            if (categoryType === categoryTypes.salesArea) {
              return fetchSalesArea(selectedTopSalesArea);
            }
            return addNewOptions(val, rowID);
          }}
          categoryType={categoryType}
          rowOptions={rowOptions}
        />
      )}
      {newStepFormDisplayed && (
        <AddNewStepForm
          isOpen={newStepFormDisplayed}
          closeCb={() => {
            setNewStepFormDisplay(false);
            setRowID(null);
            setRowOptions([]);
          }}
          locationID={locationID}
          confirmCb={(i) => handleAnswerSelect(i, rowID, true)}
          rowOptions={rowOptions}
        />
      )}
    </div>
  );
};

OptionsRow.propTypes = {
  options: PropTypes.array,
  selectedTopSalesArea: PropTypes.object,
  isSelected: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  handleAnswerSelect: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  fetchLoading: PropTypes.bool.isRequired,
  fetchTopSalesAreas: PropTypes.func.isRequired,
  fetchSalesArea: PropTypes.func.isRequired,
  addNewOptions: PropTypes.func.isRequired,
  locationID: PropTypes.number.isRequired,
};

OptionsRow.defaultProps = {
  options: null,
  selectedTopSalesArea: null,
};

export default OptionsRow;
