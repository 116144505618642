import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Label, TextInput,
} from 'shared/components';

const CartBtnEditor = ({
  kioskState,
  onChange,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(get(kioskState, 'cartBtn.title'));
  const [emptyCartTitle, setEmptyCartTitle] = useState(get(kioskState, 'cartBtn.empty_cart_title'));
  const [viewOrderBtn, setViewOrderBtn] = useState(get(kioskState, 'cartBtn.view_order_btn'));
  const [cancelBtn, setCancelBtn] = useState(get(kioskState, 'cartBtn.cancel_btn'));
  const [closeText, setCloseText] = useState(get(kioskState, 'cartBtn.close_text'));

  return (
    <div className="CartBtnEditor KioskMetaEditor">
      <div>
        <Label text={t('Naslov')} inputId="cartBtn-title" />
        <TextInput
          value={title}
          onChange={e => {
            const val = e.target.value;
            setTitle(val);
            onChange({
              type: 'cartBtn',
              data: {
                title: val,
                empty_cart_title: emptyCartTitle,
                view_order_btn: viewOrderBtn,
                cancel_btn: cancelBtn,
              },
            });
          }}
          name="cartBtn-title"
          id="cartBtn-title"
        />
      </div>
      <div>
        <Label text={t('Naslov prazne košarice')} inputId="cartBtn-empty-title" />
        <TextInput
          value={emptyCartTitle}
          onChange={e => {
            const val = e.target.value;
            setEmptyCartTitle(val);
            onChange({
              type: 'cartBtn',
              data: {
                title,
                empty_cart_title: val,
                view_order_btn: viewOrderBtn,
                cancel_btn: cancelBtn,
              },
            });
          }}
          name="cartBtn-empty-title"
          id="cartBtn-empty-title"
        />
      </div>
      <div>
        <Label text={t('Gumb pregleda narudžbe')} inputId="cartBtn-view-order" />
        <TextInput
          value={viewOrderBtn}
          onChange={e => {
            const val = e.target.value;
            setViewOrderBtn(val);
            onChange({
              type: 'cartBtn',
              data: {
                title,
                empty_cart_title: emptyCartTitle,
                view_order_btn: val,
                cancel_btn: cancelBtn,
              },
            });
          }}
          name="cartBtn-view-order"
          id="cartBtn-view-order"
        />
      </div>
      <div>
        <Label text={t('Gumb za prekid')} inputId="cartBtn-cancel" />
        <TextInput
          value={cancelBtn}
          onChange={e => {
            const val = e.target.value;
            setCancelBtn(val);
            onChange({
              type: 'cartBtn',
              data: {
                title,
                empty_cart_title: emptyCartTitle,
                view_order_btn: viewOrderBtn,
                cancel_btn: val,
              },
            });
          }}
          name="cartBtn-cancel"
          id="cartBtn-cancel"
        />
      </div>
      <div>
        <Label text={t('Zatvoriti skočni prozor')} inputId="cartBtn-close" />
        <TextInput
          value={closeText}
          onChange={e => {
            const val = e.target.value;
            setCloseText(val);
            onChange({
              type: 'cartBtn',
              data: {
                title,
                empty_cart_title: emptyCartTitle,
                view_order_btn: viewOrderBtn,
                cancel_btn: cancelBtn,
                close_text: val,
              },
            });
          }}
          name="cartBtn-close"
          id="cartBtn-close"
        />
      </div>
    </div>
  );
};

CartBtnEditor.propTypes = {
  kioskState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CartBtnEditor;
