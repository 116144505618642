import React from 'react';
import PropTypes from 'prop-types';
import { IconAlert } from 'shared/components';
import './styles.scss';

const InputErrorMessage = ({ text }) => (
  <div className="InputErrorMessage">
    <IconAlert color="#AE0721" size="14px" />
    {text}
  </div>
);

InputErrorMessage.propTypes = {
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

InputErrorMessage.defaultProps = {
  text: '',
};

export default InputErrorMessage;
