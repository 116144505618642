import { get } from 'lodash';
import store from 'redux/store';
import moment from 'moment';
import {
  defaultDateTimeFormat,
} from 'shared/constants';

export const isValidDate = (dateString) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;

  if (typeof dateString !== 'string') {
    return false;
  }

  if (!dateString.match(regEx)) {
    return false;
  }
  const d = new Date(dateString);
  if (Number.isNaN(d.getTime())) {
    return false;
  }
  return d.toISOString().slice(0, 10) === dateString;
};

export const copyText = (val) => {
  if (!val) {
    return false;
  }
  const textField = document.createElement('textarea');
  textField.innerText = val;
  document.body.appendChild(textField);
  textField.select();
  const result = document.execCommand('copy');
  textField.remove();
  return result;
};

export const isJSON = (val) => {
  try {
    JSON.parse(val);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseObject = (val) => {
  let data = {};

  if (!val || !isJSON(val)) {
    return data;
  }

  const result = JSON.parse(val);
  if (typeof result === 'object' && !Array.isArray(result)) {
    data = result;
  }

  return data;
};

export const formatDate = (val, formatType = defaultDateTimeFormat, fallback = '-') => {
  const isValid = moment(val).isValid();

  if (!val || !isValid) {
    return fallback;
  }
  return moment(val).format(formatType);
};

export const displayValue = (val, fallback = '-') => {
  if (!val && val !== 0) {
    return fallback;
  }
  return val;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return undefined;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const checkUserRole = (user) => {
  const role = get(user, 'role');

  if (!user || !role) {
    const state = store.getState();
    const userRole = get(state, 'account.user.role');
    return userRole;
  }

  return role;
};

export const isAdmin = () => {
  const userRole = checkUserRole();
  return userRole === 'admin';
};

export const isBlagajna = () => {
  const userRole = checkUserRole();
  return userRole === 'store';
};

export const isKiosk = () => {
  const userRole = checkUserRole();
  return userRole === 'kiosk';
};

export const getInnerContainerStyle = (items = []) => {
  if (items.length < 3) {
    return { justifyContent: 'space-around' };
  }
  return {};
};

export const getItemStyle = (items = []) => {
  if (items.length < 3) {
    return { marginRight: 0 };
  }
  return {};
};

export const handleACRoomSizeSelect = (values, items, setterFunc, fallback = undefined) => {
  let category = items[0];

  if (values[0] < 25) {
    category = items.find(c => c.name === 'do 25 qm');
    return setterFunc(category);
  }
  if (values[0] < 30) {
    category = items.find(c => c.name === 'od 25 do 30 qm');
    return setterFunc(category);
  }
  if (values[0] < 40) {
    category = items.find(c => c.name === 'od 30 do 40 qm');
    return setterFunc(category);
  }
  if (values[0] < 45) {
    category = items.find(c => c.name === 'od 40 do 45 qm');
    return setterFunc(category);
  }
  if (values[0] < 50) {
    category = items.find(c => c.name === 'od 45 do 50 qm');
    return setterFunc(category);
  }
  if (values[0] < 55) {
    category = items.find(c => c.name === 'od 50 do 55 qm');
    return setterFunc(category);
  }
  if (values[0] < 60) {
    category = items.find(c => c.name === 'od 55 do 60 qm');
    return setterFunc(category);
  }
  if (values[0] < 65) {
    category = items.find(c => c.name === 'od 60 do 65 qm');
    return setterFunc(category);
  }
  if (values[0] < 70) {
    category = items.find(c => c.name === 'od 65 do 70 qm');
    return setterFunc(category);
  }
  if (values[0] < 85) {
    category = items.find(c => c.name === 'od 70 do 85 qm');
    return setterFunc(category);
  }
  if (values[0] <= 100) {
    category = items.find(c => c.name === 'od 85 do 100 qm');
    return setterFunc(category);
  }
  if (values[0] > 100) {
    return setterFunc(fallback || { name: 'od 100 qm', id: 'od100qm' });
  }
  return category;
};

export const getUniqueCompanies = locations => locations.reduce((companies, location) => {
  const companyID = get(location, 'company.id');
  const isAdded = companies.find(c => c.id === companyID);
  if (!isAdded) {
    const companyObject = { ...location.company, locations: [] };
    return [...companies, companyObject];
  }
  return companies;
}, []);

export const fillCompanyList = (companies, user) => {
  const companyList = [...companies];
  user.locations.forEach(l => {
    const companyIndex = companyList.findIndex(c => c.id === l.company.id);
    if (companyIndex !== -1) {
      companyList[companyIndex].locations.push(l);
    }
  });
  return companyList;
};

export const mapUserLocations = (user) => {
  const companyList = getUniqueCompanies(user.locations);
  const companyLocations = fillCompanyList(companyList, user);
  // console.log(user);
  // console.log({ ...user, companies: companyList });
  // console.log({ ...user, companies: companyLocations });
  return { ...user, companies: companyLocations };
};
