import React from 'react';
import PropTypes from 'prop-types';

const IconArrowUp = ({
  color, size, strokeWidth, disabled,
}) => (
  <div className="IconArrowUp" style={{ display: 'flex' }}>
    <svg viewBox="0 0 192 192" fill={disabled ? '#BFC0C2' : color} height={size} width={size}>
      <path
        stroke={disabled ? '#BFC0C2' : color}
        strokeWidth={strokeWidth}
        d="M130.8 53.2L96 18.3 61.2 53.2l5.6 5.6L92 33.7V176h8V33.7l25.2 25.1z"
      />
    </svg>
  </div>
);

IconArrowUp.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  strokeWidth: PropTypes.number,
};

IconArrowUp.defaultProps = {
  color: '#37383B',
  disabled: false,
  size: '16px',
  strokeWidth: 2,
};

export default IconArrowUp;
