/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { capitalizeFirstLetter } from 'shared/helpers';
import './styles.scss';

const RecommendedProduct = ({
  product, onProductSelect, ctaText, customStyle,
}) => {
  // const { t } = useTranslation();
  const isFeatured = get(product, 'product_location.is_currently_featured');
  const featuredGroup = get(product, 'product_location.featured_group.name');
  const isAC = get(product, 'sales_area') === 9;

  const scope = get(product, 'scope_of_delivery');

  return (
    <div
      onDragStart={() => false}
      onDrop={() => false}
      role="button"
      className="RecommendedProduct"
      onClick={() => onProductSelect(product)}
      style={customStyle}
    >
      <div className="RecommendedProduct-image">
        <img src={product.img} alt={product.model} draggable="false" />
      </div>
      <div className="RecommendedProduct-title">
        {`${product.model}${isAC ? ` - ${get(product, 'power')}` : ''}`}
      </div>
      <div className="RecommendedProduct-description">
        {capitalizeFirstLetter(get(product, 'category.name'))}
      </div>
      {scope && (
        <div className="RecommendedProduct-scope">
          {capitalizeFirstLetter(get(product, 'scope_of_delivery'))}
        </div>
      )}
      <div className="RecommendedProduct-cta">{ctaText}</div>
      {isFeatured && (
        <div className="RecommendedProduct-featured">{featuredGroup}</div>
      )}
    </div>
  );
};

RecommendedProduct.propTypes = {
  ctaText: PropTypes.string.isRequired,
  customStyle: PropTypes.object.isRequired,
  onProductSelect: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};

export default RecommendedProduct;
