import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import QRCode from 'qrcode.react';
import './styles.scss';

const OrderPopupPreview = ({
  kioskUI,
}) => (
  <div className="OrderPopupPreview PreviewPane">
    <div className="content">
      <div className="popup">
        <div className="title">{get(kioskUI, 'orderPopup.title')}</div>
        <div className="popup-body">
          <div className="order-content">
            <div className="order-id">54</div>
            <div className="order-qr">
              <QRCode value="preview" size={80} />
            </div>
          </div>
          <div className="description">{get(kioskUI, 'orderPopup.directions')}</div>
          <div className="actions">
            <div className="actionBtn">
              {get(kioskUI, 'orderPopup.restart_btn')}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

OrderPopupPreview.propTypes = {
  kioskUI: PropTypes.object.isRequired,
};

export default OrderPopupPreview;
