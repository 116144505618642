import React from 'react';
import PropTypes from 'prop-types';
import { getImage } from './helpers';

const BoxImage = ({ img }) => <img draggable="false" className="logo" src={getImage(img)} alt="category" />;

BoxImage.propTypes = {
  img: PropTypes.string,
};

BoxImage.defaultProps = {
  img: null,
};

export default BoxImage;
