import api from 'shared/api';

export const patchTopSalesAreas = (optionID, locationID, data) => {
  const url = `/api/v1/topsalesareas/${optionID}/?location=${locationID}`;

  return api.patch(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const patchSalesAreas = (optionID, locationID, data) => {
  const url = `/api/v1/salesareas/${optionID}/?location=${locationID}`;

  return api.patch(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const patchBranchingCategory = (optionID, locationID, data) => {
  const url = `/api/v1/branchingcategories/${optionID}/?location=${locationID}`;

  return api.patch(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
