import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Label, TextInput,
} from 'shared/components';

const ActionBtnsEditor = ({
  kioskState,
  onChange,
}) => {
  const { t } = useTranslation();
  const [backBtn, setBackBtn] = useState(get(kioskState, 'actionBtns.back_btn'));
  const [restartBtn, setRestartBtn] = useState(get(kioskState, 'actionBtns.restart_btn'));

  return (
    <div className="ActionBtnsEditor KioskMetaEditor">
      <div>
        <Label text={t('Gumb za povratak')} inputId="actionBtns-backBtn" />
        <TextInput
          value={backBtn}
          onChange={e => {
            const val = e.target.value;
            setBackBtn(val);
            onChange({
              type: 'actionBtns',
              data: {
                back_btn: val,
                restart_btn: restartBtn,
              },
            });
          }}
          name="actionBtns-backBtn"
          id="actionBtns-backBtn"
        />
      </div>
      <div>
        <Label text={t('Gumb za ponovno pokretanje')} inputId="actionBtns-restart" />
        <TextInput
          value={restartBtn}
          onChange={e => {
            const val = e.target.value;
            setRestartBtn(val);
            onChange({
              type: 'actionBtns',
              data: {
                back_btn: backBtn,
                restart_btn: val,
              },
            });
          }}
          name="actionBtns-restart"
          id="actionBtns-restart"
        />
      </div>
    </div>
  );
};

ActionBtnsEditor.propTypes = {
  kioskState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ActionBtnsEditor;
