import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const PageHeader = ({
  children,
  description,
  title,
}) => (
  <div className="PageHeader">
    <header className="PageHeader-main">
      {!!title && <h1 className="PageHeader-main-title">{title}</h1>}
      <div className="PageHeader-main-description">{!!description && description}</div>
    </header>
    <div className="PageHeader-children">
      {children}
    </div>
  </div>
);

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]),
  description: PropTypes.string,
  title: PropTypes.string,
};

PageHeader.defaultProps = {
  children: null,
  description: null,
  title: null,
};

export default PageHeader;
