import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Label, TextInput,
} from 'shared/components';

const AddToCartEditor = ({
  kioskState,
  onChange,
}) => {
  const { t } = useTranslation();
  const [popupTitle, setTitle] = useState(get(kioskState, 'addToCartPopup.title'));
  const [orderBtn, setOrderBtn] = useState(get(kioskState, 'addToCartPopup.view_order_btn'));
  const [continueBtn, setContinueBtn] = useState(get(kioskState, 'addToCartPopup.continue_btn'));
  const [cancelBtn, setCancelBtn] = useState(get(kioskState, 'addToCartPopup.cancel_btn'));
  const [closeText, setCloseText] = useState(get(kioskState, 'addToCartPopup.close_text'));

  return (
    <div className="AddToCartEditor KioskMetaEditor">
      <div>
        <Label text={t('Naslov')} inputId="addToCartPopup-title" />
        <TextInput
          value={popupTitle}
          onChange={e => {
            const val = e.target.value;
            setTitle(val);
            onChange({
              type: 'addToCartPopup',
              data: {
                title: val,
                view_order_btn: orderBtn,
                cancel_btn: cancelBtn,
                continue_btn: continueBtn,
                close_text: closeText,
              },
            });
          }}
          name="addToCartPopup-title"
          id="addToCartPopup-title"
        />
      </div>
      <div>
        <Label text={t('Potvrda narudžbe')} inputId="addToCartPopup-order" />
        <TextInput
          value={orderBtn}
          onChange={e => {
            const val = e.target.value;
            setOrderBtn(val);
            onChange({
              type: 'addToCartPopup',
              data: {
                title: popupTitle,
                view_order_btn: val,
                continue_btn: continueBtn,
                cancel_btn: cancelBtn,
                close_text: closeText,
              },
            });
          }}
          name="addToCartPopup-order"
          id="addToCartPopup-order"
        />
      </div>
      <div>
        <Label text={t('Nastavi s odabirom')} inputId="addToCartPopup-continue" />
        <TextInput
          value={continueBtn}
          onChange={e => {
            const val = e.target.value;
            setContinueBtn(val);
            onChange({
              type: 'addToCartPopup',
              data: {
                title: popupTitle,
                view_order_btn: orderBtn,
                continue_btn: val,
                cancel_btn: cancelBtn,
                close_text: closeText,
              },
            });
          }}
          name="addToCartPopup-continue"
          id="addToCartPopup-continue"
        />
      </div>
      <div>
        <Label text={t('Prekid narudžbe')} inputId="addToCartPopup-cancel" />
        <TextInput
          value={cancelBtn}
          onChange={e => {
            const val = e.target.value;
            setCancelBtn(val);
            onChange({
              type: 'addToCartPopup',
              data: {
                title: popupTitle,
                view_order_btn: orderBtn,
                cancel_btn: val,
                continue_btn: continueBtn,
                close_text: closeText,
              },
            });
          }}
          name="addToCartPopup-cancel"
          id="addToCartPopup-cancel"
        />
      </div>
      <div>
        <Label text={t('Zatvoriti skočni prozor')} inputId="addToCartPopup-close" />
        <TextInput
          value={closeText}
          onChange={e => {
            const val = e.target.value;
            setCloseText(val);
            onChange({
              type: 'addToCartPopup',
              data: {
                title: popupTitle,
                view_order_btn: orderBtn,
                cancel_btn: cancelBtn,
                continue_btn: continueBtn,
                close_text: val,
              },
            });
          }}
          name="addToCartPopup-close"
          id="addToCartPopup-close"
        />
      </div>
    </div>
  );
};

AddToCartEditor.propTypes = {
  kioskState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AddToCartEditor;
