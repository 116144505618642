import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components';
import NoImage from 'screens/kiosk/assets/imgs/noImagePlaceholder.jpg';
import ProductImageChange from '../ProductImageChange';
import './styles.scss';

const getImage = (photos) => {
  if (!photos || !photos.length) {
    return NoImage;
  }

  const mainPhoto = photos.find(p => p.main);
  return get(mainPhoto, 'photo') || NoImage;
};

const formatDataDetails = (details) => {
  const data = [];
  Object.entries(details).forEach(([key, value]) => {
    const item = { label: key, value };
    data.push(item);
  });
  return data;
};

const TableExpandedRow = ({
  data,
  locationID,
  updateProducts,
}) => {
  const { t } = useTranslation();
  const details = formatDataDetails(get(data, 'details') || {});

  const [showImageModal, setImageModalDisplay] = useState(false);

  return (
    <div
      className="TableExpandedRow"
    >
      <div className="image-container">
        <img src={getImage(get(data, 'photos'))} alt={get(data, 'model')} />
        <Button size="sm" onClick={() => setImageModalDisplay(true)}>Promijeni sliku</Button>
      </div>
      <div className="data-container">
        <div className="details-heading">{`${t('Dodatni detalji')}:`}</div>
        <table className="details-table">
          <tbody>
            <tr>
              <td>{t('Kod')}</td>
              <td>{get(data, 'code') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Opseg isporuke')}</td>
              <td>{get(data, 'scope_of_delivery') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Izvor napajanja')}</td>
              <td>{get(data, 'power_source') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Napon')}</td>
              <td>{get(data, 'voltage') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Snaga')}</td>
              <td>{get(data, 'power') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Opseg isporuke')}</td>
              <td>{get(data, 'scope_of_delivery') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Upotreba')}</td>
              <td>{data.usages.map(u => u.code).join(', ')}</td>
            </tr>
            <tr>
              <td>{t('Materijal')}</td>
              <td>{data.materials.map(u => u.code).join(', ')}</td>
            </tr>
            {
              details.filter(d => d.label).map(d => (
                <tr key={d.label}>
                  <td>{t(d.label)}</td>
                  <td>{d.value}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      {showImageModal && (
        <ProductImageChange
          isOpen={showImageModal}
          closeCb={() => setImageModalDisplay(false)}
          confirmCb={() => {
            updateProducts();
            setImageModalDisplay(false);
          }}
          locationID={locationID}
          product={data}
        />
      )}
    </div>
  );
};

TableExpandedRow.propTypes = {
  data: PropTypes.object,
  locationID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  updateProducts: PropTypes.func.isRequired,
};

TableExpandedRow.defaultProps = {
  data: {},
};

export default TableExpandedRow;
