/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DirtyFormAlert,
  Label,
  FileUploader,
  Modal,
  Notification,
} from 'shared/components';
import { updateProductImage } from './actions';
import './styles.scss';

const ProductImageChange = ({
  product,
  isOpen,
  closeCb,
  confirmCb,
  locationID,
}) => {
  const [loading, setLoading] = useState(false);
  const [isImageRowDirty, setImageRowDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [file, setFile] = useState(null);

  const validImageFormats = '.png,.jpg,.jpeg';
  const productID = get(product, 'ean');

  const [t] = useTranslation();

  const handlePatchSuccess = (res) => {
    const newData = get(res, 'data');
    setLoading(false);
    confirmCb(newData);
    closeCb();
    Notification('success', t('Promjene su uspješno spremljene'));
  };

  const handlePatchError = () => {
    setLoading(false);
    // console.log('error');
    Notification('error', t('Dogodila se pogreška'));
  };

  const onSubmit = () => {
    if (loading || !file) {
      return;
    }

    setLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    if (file) {
      formData.append('photo', file);
    }

    updateProductImage(productID, locationID, formData)
      .then(handlePatchSuccess)
      .catch(handlePatchError);
  };

  const handleWrongFileType = () => {
    Notification('error', t('Pogrešna vrsta datoteke'), `${t('Mogu se prenositi samo datoteke')}: ${validImageFormats}`);
  };

  const handleClose = () => {
    if (isImageRowDirty) {
      setDirtyFormAlertDisplay(true);
    } else {
      closeCb();
    }
  };

  return (
    <Modal
      closeCb={handleClose}
      isOpen={isOpen}
      size="sm"
      title={get(product, 'model')}
    >
      <div className="ProductImageChange">
        <div className="ProductImageChange-row">
          <Label inputId="file-upload" text={t('Slika')} />
          <FileUploader
            disabled={loading}
            onUploadSuccess={(_file, allFiles) => {
              const val = get(allFiles, '[0].file');
              setImageRowDirty(true);
              setFile(val);
            }}
            onWrongFileTypeReject={handleWrongFileType}
            onItemRemove={() => setFile(null)}
            label={t('Povucite datoteke ili kliknite da biste ih pregledali')}
            subLabel={`${t('Vrste datoteka')}:`}
            validFileTypes={validImageFormats}
          />
        </div>
        <div className="ProductImageChange-row btn-cont">
          <Button disabled={loading} onClick={onSubmit}>
            {t('Spremi')}
          </Button>
          <Button disabled={loading} theme="link" priority="tertiary" onClick={handleClose}>
            {t('Poništi')}
          </Button>
        </div>
      </div>
      {
        isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={isImageRowDirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={() => {
              setDirtyFormAlertDisplay(false);
              closeCb();
            }}
          />
        )
      }
    </Modal>
  );
};

ProductImageChange.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  locationID: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
};

ProductImageChange.defaultProps = {
  isOpen: false,
};

export default ProductImageChange;
