import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Label, TextInput,
} from 'shared/components';

const CartPageEditor = ({
  kioskState,
  onChange,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(get(kioskState, 'cartPage.title'));
  const [emptyCartTitle, setEmptyCartTitle] = useState(get(kioskState, 'cartPage.empty_cart_description'));
  const [orderBtn, setOrderBtn] = useState(get(kioskState, 'cartPage.order_btn'));

  return (
    <div className="CartPageEditor KioskMetaEditor">
      <div>
        <Label text={t('Naslov')} inputId="CartPage-title" />
        <TextInput
          value={title}
          onChange={e => {
            const val = e.target.value;
            setTitle(val);
            onChange({
              type: 'cartPage',
              data: {
                title: val,
                empty_cart_description: emptyCartTitle,
                order_btn: orderBtn,
              },
            });
          }}
          name="CartPage-title"
          id="CartPage-title"
        />
      </div>
      <div>
        <Label text={t('Naslov prazne košarice')} inputId="CartPage-empty-title" />
        <TextInput
          value={emptyCartTitle}
          onChange={e => {
            const val = e.target.value;
            setEmptyCartTitle(val);
            onChange({
              type: 'cartPage',
              data: {
                title,
                empty_cart_description: val,
                order_btn: orderBtn,
              },
            });
          }}
          name="CartPage-empty-title"
          id="CartPage-empty-title"
        />
      </div>
      <div>
        <Label text={t('Potvrda narudžbe')} inputId="CartPage-view-order" />
        <TextInput
          value={orderBtn}
          onChange={e => {
            const val = e.target.value;
            setOrderBtn(val);
            onChange({
              type: 'cartPage',
              data: {
                title,
                empty_cart_description: emptyCartTitle,
                order_btn: val,
              },
            });
          }}
          name="CartPage-view-order"
          id="CartPage-view-order"
        />
      </div>
    </div>
  );
};

CartPageEditor.propTypes = {
  kioskState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CartPageEditor;
