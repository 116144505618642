import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { getMainPhoto } from 'screens/kiosk/assets/helpers';
import { capitalizeFirstLetter } from 'shared/helpers';
import './styles.scss';

const RecommendationItem = ({
  value,
}) => (
  <div
    className="RecommendationItem"
  >
    <div className={`image-container ${value.photos.length ? '' : 'empty'}`}>
      <img src={getMainPhoto(value.photos)} alt={value.name} />
    </div>
    <div className="meta-container">
      <div className="content">
        <div className="product-name">{value.model}</div>
        <div className="product-category">{capitalizeFirstLetter(get(value, 'category.name'))}</div>
      </div>
    </div>
  </div>
);

RecommendationItem.propTypes = {
  value: PropTypes.object.isRequired,
};

export default RecommendationItem;
