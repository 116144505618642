import React from 'react';
import PropTypes from 'prop-types';
import { InputErrorMessage, Label } from 'shared/components';
import './styles.scss';

const TextInput = ({
  disabled,
  description,
  error,
  loading,
  id,
  label,
  register,
  name,
  ...rest
}) => (
  <div className="TextInput">
    {label && <Label inputId={id} text={label} description={description} />}
    <input
      className={error ? 'has-error' : ''}
      type="text"
      disabled={disabled}
      ref={register}
      name={name}
      id={id}
      {...rest}
    />
    {error && <InputErrorMessage text={error} />}
  </div>
);

TextInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  loading: PropTypes.bool,
  register: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    // eslint-disable-next-line no-undef
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  name: PropTypes.string.isRequired,
};

TextInput.defaultProps = {
  disabled: false,
  description: '',
  error: '',
  label: '',
  loading: false,
  register: null,
};

export default TextInput;
