import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const StepTitle = ({ title, description }) => (
  <div className="StepTitle">
    <div className="StepTitle-title">{title}</div>
    {description && (
      <div className="StepTitle-description">{description}</div>
    )}
  </div>
);

StepTitle.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

StepTitle.defaultProps = {
  description: null,
};

export default StepTitle;
