import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputErrorMessage,
  Label,
  IconPassHidden,
  IconPassVisible,
} from 'shared/components';
import './styles.scss';

const PasswordInput = ({
  disabled,
  description,
  error,
  loading,
  id,
  label,
  register,
  showVisibilityBtn,
  name,
  ...rest
}) => {
  const [passVisible, setPasswordDisplay] = useState(false);

  return (
    <div className="PasswordInput">
      {label && <Label inputId={id} text={label} description={description} />}
      <input
        className={error ? 'has-error' : ''}
        type={passVisible ? 'text' : 'password'}
        disabled={disabled}
        ref={register}
        name={name}
        {...rest}
      />
      {/* {loading && <LoadingIndicator />} */}
      {showVisibilityBtn && (
        <button type="button" onClick={() => setPasswordDisplay(!passVisible)}>
          {!passVisible && <IconPassVisible color="#777" size="20px" />}
          {passVisible && <IconPassHidden color="#777" size="20px" />}
        </button>
      )}
      {error && <InputErrorMessage text={error} />}
    </div>
  );
};

PasswordInput.propTypes = {
  disabled: PropTypes.bool,
  description: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  loading: PropTypes.bool,
  showVisibilityBtn: PropTypes.bool,
  register: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    // eslint-disable-next-line no-undef
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  name: PropTypes.string.isRequired,
};

PasswordInput.defaultProps = {
  disabled: false,
  description: '',
  error: '',
  label: '',
  loading: false,
  showVisibilityBtn: true,
  register: null,
};

export default PasswordInput;
