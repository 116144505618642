import React from 'react';
import PropTypes from 'prop-types';
import {
  CustomStep,
  InitialStep,
  DynamicStep,
  RecommendationStep,
  SalesAreaStep,
} from '../components';
import './styles.scss';

const StepContainer = ({
  answers,
  ui,
  handleBack,
  handleSelection,
  handleProductSelection,
  handleProgressChange,
  handleRestart,
  showCartPage,
  removeCartProduct,
  isInitial,
  isDynamic,
  isRecommendation,
  salesArea,
  handleSalesAreaSelect,
  customStep,
  handleCustomStepSubmit,
  handleLessThan10Products,
  productsCategories,
}) => (
  <div className="StepContainer">
    {
      salesArea === null && <SalesAreaStep handleSelection={handleSalesAreaSelect} ui={ui} />
    }
    {
      customStep !== null && <CustomStep ui={ui} handleBack={handleBack} customStep={customStep} />
    }
    { isInitial && (
      <InitialStep
        salesArea={salesArea}
        handleProgressChange={handleProgressChange}
        handleSelection={handleSelection}
        handleBack={handleBack}
        ui={ui}
      />
    )}
    { isDynamic && !isRecommendation && (
      <DynamicStep
        answers={answers}
        handleBack={handleBack}
        handleProgressChange={handleProgressChange}
        handleSelection={handleSelection}
        ui={ui}
        handleCustomStepSubmit={handleCustomStepSubmit}
        handleLessThan10Products={handleLessThan10Products}
      />
    )}
    { isRecommendation && (
      <RecommendationStep
        answers={answers}
        handleProductSelection={handleProductSelection}
        handleProgressChange={handleProgressChange}
        handleRestart={handleRestart}
        handleBack={handleBack}
        removeCartProduct={removeCartProduct}
        showCartPage={showCartPage}
        ui={ui}
        productsCategories={productsCategories}
      />
    )}
  </div>
);

StepContainer.propTypes = {
  answers: PropTypes.array.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
  handleProductSelection: PropTypes.func.isRequired,
  handleProgressChange: PropTypes.func.isRequired,
  handleRestart: PropTypes.func.isRequired,
  showCartPage: PropTypes.func.isRequired,
  removeCartProduct: PropTypes.func.isRequired,
  isInitial: PropTypes.bool.isRequired,
  isDynamic: PropTypes.bool.isRequired,
  isRecommendation: PropTypes.bool.isRequired,
  ui: PropTypes.object.isRequired,
  salesArea: PropTypes.object,
  handleSalesAreaSelect: PropTypes.func.isRequired,
  handleCustomStepSubmit: PropTypes.func.isRequired,
  handleLessThan10Products: PropTypes.func.isRequired,
  customStep: PropTypes.object,
  productsCategories: PropTypes.array,
};

StepContainer.defaultProps = {
  salesArea: null,
  customStep: null,
  productsCategories: [],
};

export default StepContainer;
