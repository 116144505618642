import React from 'react';
import PropTypes from 'prop-types';

const IconCheckmark = ({ color, size }) => (
  <div className="IconCheckmark">
    <svg width={size} height={size} fill={color} viewBox="0 0 192 192">
      <path
        stroke={color}
        strokeWidth={5}
        d="M76 141.7L33.2 98.8l5.6-5.6L76 130.3l77.2-77.1 5.6 5.6z"
      />
    </svg>
  </div>
);

IconCheckmark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconCheckmark.defaultProps = {
  color: '#37383B',
  size: '16px',
};

export default IconCheckmark;
