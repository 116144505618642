import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import {
  Redirect, NavLink, Switch, Route,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Label,
  NotFound,
  Page,
  PageHeader,
  Selector,
} from 'shared/components';
import { isKiosk, isBlagajna, checkUserRole } from 'shared/helpers';
import {
  changeActiveCompany, changeActiveLocation,
} from 'redux/kiosk/actionTypes';
import {
  getPromotions,
} from 'redux/promotions/actions';
import {
  getUsages,
  getMaterials,
  getCategories,
  getSegments,
} from 'redux/kiosk/actions';
import KioskConfiguration from './KioskConfiguration';
import ProductList from './ProductList';
import ProductImport from './ProductImport';
import Promotions from './Promotions';
import PromotionDetails from './Promotions/PromotionDetails';
import UsersList from './UsersList';
import './styles.scss';

const AdminContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => get(state, 'account.user') || null);
  const companies = useSelector(state => get(state, 'account.user.companies') || []);
  const activeCompany = useSelector(state => get(state, 'kiosk.activeCompany'));
  const activeLocation = useSelector(state => get(state, 'kiosk.activeLocation'));

  const userRole = checkUserRole(user);

  useEffect(() => {
    dispatch(getPromotions(activeLocation.id));
    dispatch(getUsages(activeLocation.id));
    dispatch(getMaterials(activeLocation.id));
    dispatch(getCategories(activeLocation.id));
    dispatch(getSegments(activeLocation.id));
  }, [activeLocation, dispatch]);

  if (isKiosk()) {
    return <Redirect to="/kiosk" />;
  }

  if (isBlagajna()) {
    return <Redirect to="/blagajna" />;
  }

  return (
    <Page>
      <div className="AdminContainer">
        <PageHeader title={t('Administrator')} description={t('Upravljanje platformom')}>
          <div className="LocationSelectors">
            <div className="Selector-wrapper">
              <Label text={t('Tvrtka')} inputId="company-selector" />
              <Selector
                options={companies}
                values={[activeCompany]}
                onChange={val => dispatch({ type: changeActiveCompany, company: get(val, '[0]') })}
                labelField="name"
                valueField="id"
              />
            </div>
            <div className="Selector-wrapper">
              <Label text={t('Lokacija')} inputId="location-selector" />
              <Selector
                options={get(activeCompany, 'locations')}
                values={[activeLocation]}
                onChange={val => dispatch({ type: changeActiveLocation, location: get(val, '[0]') })}
                labelField="name"
                valueField="id"
              />
            </div>
          </div>
        </PageHeader>
        <nav className="AdminContainer-menu">
          <NavLink className="nav-link" exact to="/">{t('Kiosk konfigurator')}</NavLink>
          <NavLink className="nav-link" to="/proizvodi">{t('Lista proizvoda')}</NavLink>
          <NavLink className="nav-link" exact to="/import-proizvoda">{t('Uvoz proizvoda')}</NavLink>
          <NavLink className="nav-link" to="/promocije">{t('Promocije')}</NavLink>
          <NavLink className="nav-link" to="/korisnici">{t('Lista korisnika')}</NavLink>
        </nav>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <KioskConfiguration userRole={userRole} />}
          />
          <Route
            exact
            path="/proizvodi"
            render={() => <ProductList userRole={userRole} />}
          />
          {/* <Route
            exact
            path="/proizvodi/:productID"
            render={() => <div>Detalji proizvoda</div>}
          /> */}
          <Route
            exact
            path="/import-proizvoda"
            render={() => <ProductImport userRole={userRole} />}
          />
          <Route
            exact
            path="/promocije"
            render={() => <Promotions userRole={userRole} />}
          />
          <Route
            exact
            path="/promocije/:promotionID"
            render={() => <PromotionDetails userRole={userRole} />}
          />
          <Route
            exact
            path="/korisnici"
            render={() => <UsersList userRole={userRole} />}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Page>
  );
};

export default AdminContainer;
