import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconSearch,
  LoadingIndicator,
  InputErrorMessage,
  Selector,
} from 'shared/components';
import './styles.scss';

const SearchForm = ({
  btnText,
  disabled,
  error,
  handleChange,
  handleClearBtn,
  loading,
  onSearch,
  searchOptions,
  selectedSearchOption,
  onSearchTypeChange,
  showButton,
  ...rest
}) => {
  const [query, setQuery] = useState('');
  const handleInputChange = (e) => {
    setQuery(e.target.value);
    handleChange(e.target.value);
  };
  const handleClearBtnClick = () => {
    setQuery('');
    handleClearBtn('');
  };

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(query);
  };

  return (
    <div className="SearchForm">
      <form className="SearchForm-form" onSubmit={handleSearch}>
        {
          searchOptions && (
            <Selector
              options={searchOptions}
              values={selectedSearchOption}
              onChange={(val) => onSearchTypeChange(val)}
            />
          )
        }
        <div className="search-input-wrapper">
          <input
            className={`${error ? 'has-error' : ''}`}
            disabled={disabled}
            onChange={handleInputChange}
            value={query}
            {...rest}
          />
          {loading && <LoadingIndicator />}
          {query && (
            <button
              type="button"
              className="search-clear-btn"
              onClick={handleClearBtnClick}
            >
              &times;
            </button>
          )}
        </div>
        {showButton && (
          <div className="submit-search-btn">
            <Button type="submit">
              <>
                <IconSearch color="#fff" />
                {btnText && <span>{btnText}</span>}
              </>
            </Button>
          </div>
        )}
      </form>
      {error && <InputErrorMessage text={error} />}
    </div>
  );
};

SearchForm.propTypes = {
  btnText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleClearBtn: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  showButton: PropTypes.bool,
  searchOptions: PropTypes.array,
  selectedSearchOption: PropTypes.array,
  onSearchTypeChange: PropTypes.func,
};

SearchForm.defaultProps = {
  btnText: '',
  disabled: false,
  loading: false,
  error: '',
  showButton: true,
  searchOptions: null,
  selectedSearchOption: null,
  onSearchTypeChange: null,
};

export default SearchForm;
