/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
export const defaultDateFormat = 'DD-MM-YYYY';
export const defaultTimeFormat = 'HH:mm';
export const defaultDateTimeFormat = 'DD-MM-YYYY HH:mm';

export const emailRegex = /^[a-z0-9!#$%&'*+/=?\^_`{|}~\-]+(?:.[a-z0-9!#$%&'*+/=?\^_`{|}~\-]+)*@(?:[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?$/i;
export const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const numberRegex = /\d+/g;
export const lettersRegex = /[a-zA-Z]+/g;

export const languageOptions = [
  {
    label: 'SLO',
    value: 'slo',
  },
  {
    label: 'HR',
    value: 'hr',
  },
  {
    label: 'SRB',
    value: 'srb',
  },
  {
    label: 'EN',
    value: 'en',
  },
];

export const roleOptions = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Blagajna',
    value: 'store',
  },
  {
    label: 'Kiosk',
    value: 'kiosk',
  },
];

export const categoryTypes = {
  topSalesArea: 'top_sales_area',
  salesArea: 'sales_area',
  rootBranchingCategory: 'root_branching_category',
  childBranchingCategory: 'child_branching_category',
};
