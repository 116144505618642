import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  getInnerContainerStyle,
  getItemStyle,
} from 'shared/helpers';
import {
  Spinner,
} from 'shared/components';
import {
  updateTopSalesArea,
} from 'redux/kiosk/actionTypes';
import { getTopSalesAreas } from './actions';
import StepTitle from '../StepTitle';
import SingleAnswerBox from '../SingleAnswerBox';

const SalesAreaStep = ({
  handleSelection,
  // eslint-disable-next-line no-unused-vars
  ui,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companies = useSelector(state => get(state, 'account.user.companies') || []);
  const cachedTopSalesArea = useSelector(state => get(state, 'kiosk.topSalesArea'));
  const locationId = get(companies, '[0].id');

  const [loading, setLoading] = useState(true);
  const [topSalesAreas, setTopSalesAreas] = useState([]);

  const fetchTopSalesArea = useCallback(() => {
    if (cachedTopSalesArea) {
      setTopSalesAreas(cachedTopSalesArea);
      setLoading(false);
    } else {
      getTopSalesAreas(locationId)
        .then((res) => {
          const data = get(res, 'data.results') || [];
          setTopSalesAreas(data);
          dispatch({ type: updateTopSalesArea, payload: data });
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [locationId, cachedTopSalesArea, dispatch]);

  useEffect(() => {
    fetchTopSalesArea();
  }, [fetchTopSalesArea]);

  if (loading) {
    return (
      <div className="SalesAreaStep">
        <div className="KioskStep-answers-loading">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div
      onDragStart={() => false}
      onDrop={() => false}
      className="SalesAreaStep"
    >
      <StepTitle title={t('Prodajna područja')} />
      <div className="KioskStep-answers">
        <div className="inner-container" style={getInnerContainerStyle(topSalesAreas)}>
          {topSalesAreas.map((a) => (
            <SingleAnswerBox
              key={a.id}
              answer={a}
              handleSelection={handleSelection}
              customStyle={getItemStyle(topSalesAreas)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

SalesAreaStep.propTypes = {
  handleSelection: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

export default SalesAreaStep;
