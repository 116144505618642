import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Label, TextInput,
} from 'shared/components';

const MainTitleEditor = ({
  kioskState,
  onChange,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(get(kioskState, 'mainTitle.title'));

  return (
    <div className="mainTitleEditor KioskMetaEditor">
      <Label text={t('Naslov')} inputId="mainTitle-title" />
      <TextInput
        value={title}
        onChange={e => {
          const val = get(e, 'target.value');
          setTitle(val);
          onChange({
            type: 'mainTitle',
            data: {
              title: val,
            },
          });
        }}
        name="mainTitle-title"
        id="mainTitle-title"
      />
    </div>
  );
};

MainTitleEditor.propTypes = {
  kioskState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MainTitleEditor;
