import api from 'shared/api';

export const getPromotions = (locationID) => {
  const url = `/api/v1/featuredgroups/?location=${locationID}&offset=0&limit=100`;
  return api.get(url);
};

export const deletePromotion = (locationID, promotionID) => {
  const url = `/api/v1/featuredgroups/${promotionID}/?location=${locationID}`;
  return api.delete(url);
};
