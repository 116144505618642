// Kiosk ui
export const changeKioskUi = 'CHANGE_KIOSK_UI';
export const changeActiveCompany = 'CHANGE_ACTIVE_COMPANY';
export const changeActiveLocation = 'CHANGE_ACTIVE_LOCATION';

export const loginSuccess = 'LOGIN_SUCCESS';
export const getUserSuccess = 'GET_USER_SUCCESS';

// top sales area
export const updateTopSalesArea = 'UPDATE_TOP_SALES_AREA';

// usages
export const getUsagesInit = 'GET_USAGES_INIT';
export const getUsagesSuccess = 'GET_USAGES_SUCCESS';
export const getUsagesError = 'GET_USAGES_ERROR';
export const refreshUsages = 'REFRESH_USAGES';

// materials
export const getMaterialsInit = 'GET_MATERIALS_INIT';
export const getMaterialsSuccess = 'GET_MATERIALS_SUCCESS';
export const getMaterialsError = 'GET_MATERIALS_ERROR';
export const refreshMaterials = 'REFRESH_MATERIALS';

// categories
export const getCategoriesInit = 'GET_CATEGORIES_INIT';
export const getCategoriesSuccess = 'GET_CATEGORIES_SUCCESS';
export const getCategoriesError = 'GET_CATEGORIES_ERROR';
export const refreshCategories = 'REFRESH_CATEGORIES';

// segments
export const getSegmentsInit = 'GET_SEGMENTS_INIT';
export const getSegmentsSuccess = 'GET_SEGMENTS_SUCCESS';
export const getSegmentsError = 'GET_SEGMENTS_ERROR';
export const refreshSegments = 'REFRESH_SEGMENTS';
