import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import './styles.scss';

const Selector = ({
  containerStyle,
  options,
  value,
  multi,
  disabled,
  loading,
  maxWidth,
  featureAvailable,
  featureEnabled,
  notAvailableMessage,
  notEnabledMessage,
  valueKey,
  handleChange,
  ...props
}) => {
  let valueForSelect = null;
  if (value) {
    valueForSelect = !multi ? options.find(o => o[valueKey] === value) : value;
  }

  return (
    <div
      className="Selector"
      style={{
        maxWidth,
        ...containerStyle,
      }}
    >
      <Select
        className="selector-container"
        classNamePrefix="selector-inner"
        isDisabled={disabled}
        isMulti={multi}
        onChange={option => handleChange(option[valueKey])}
        options={options}
        value={valueForSelect}
        {...props}
      />
      {
        loading && (
          <div className="spinner">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        )
      }
    </div>
  );
}

Selector.propTypes = {
  /** Is selector disabled */
  disabled: PropTypes.bool,
  /** Callback for handling selector change */
  handleChange: PropTypes.func,
  /** Selector shows loading indicator in loading state */
  loading: PropTypes.bool,
  /** Max width that selector container can have */
  containerStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Max width that selector container can have */
  maxWidth: PropTypes.string,
  /** Can select multiple values */
  multi: PropTypes.bool,
  /** Data for the selector */
  options: PropTypes.array.isRequired,
  /** Optional styling object */
  style: PropTypes.object,
  /** Value of the selector */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  featureAvailable: PropTypes.bool,
  notAvailableMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  featureEnabled: PropTypes.bool,
  notEnabledMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  valueKey: PropTypes.string,
};

Selector.defaultProps = {
  containerStyle: {},
  disabled: false,
  handleChange: () => { },
  loading: false,
  maxWidth: 'auto',
  multi: false,
  style: {},
  value: undefined,
  featureAvailable: true,
  notAvailableMessage: '',
  featureEnabled: true,
  notEnabledMessage: '',
  valueKey: 'value',
};

export default Selector;
