import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  DirtyFormAlert,
  Label,
  TextInput,
  Notification,
} from 'shared/components';
import api from 'shared/api';
import { getUsages } from 'redux/kiosk/actions';
import './styles.scss';

const NewUsageForm = ({
  isOpen,
  closeCb,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);

  const [usageName, setUsageName] = useState('');
  const [usageNameError, setUsageNameError] = useState('');

  const usagesList = useSelector(state => get(state, 'kiosk.usages') || []);
  const locationID = useSelector(state => get(state, 'kiosk.activeLocation.id') || null);

  const isDataValid = () => {
    if (!usageName) {
      setUsageNameError(t('Ovo polje je obavezno'));
      return false;
    }
    const doesExist = usagesList.find(m => m.code === usageName);
    if (doesExist) {
      setUsageNameError(t('Upotreba već postoji'));
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const isValid = isDataValid();
    if (isLoading || !isValid) {
      return false;
    }

    setLoading(true);
    const data = {
      code: usageName,
    };

    api.post('/api/v1/usages/', data)
      .then(() => {
        dispatch(getUsages(locationID));
        Notification('success', t('Promjene su uspješno spremljene'));
        closeCb();
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Dogodila se pogreška'));
      });
    return true;
  };

  const handleClose = () => {
    if (isDirty) {
      setDirtyFormAlertDisplay(true);
    } else {
      closeCb();
    }
  };

  return (
    <Modal
      size="sm"
      closeCb={handleClose}
      isOpen={isOpen}
      title={t('Dodaj uporabu')}
    >
      <div className="NewUsageForm">
        <form className="NewUsageForm-form" onSubmit={handleSubmit}>
          <div className="form-inner">
            <div className="row">
              <Label text={t('Upotreba')} inputId="usage-name" />
              <TextInput
                id="usage-name"
                name="usage-name"
                value={usageName}
                error={usageNameError}
                onChange={(e) => {
                  const val = e.target.value;
                  setDirty(true);
                  setUsageName(val);
                  setUsageNameError('');
                }}
              />
            </div>
          </div>
          <div className="form-actions">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {t('Potvrdi')}
            </Button>
            <Button
              type="button"
              theme="error"
              priority="tertiary"
              onClick={handleClose}
              disabled={isLoading}
            >
              {t('Poništi')}
            </Button>
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={() => {
              setDirtyFormAlertDisplay(false);
              closeCb();
            }}
          />
        )}
      </div>
    </Modal>
  );
};

NewUsageForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
};

export default NewUsageForm;
