import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ReactComponent as EmptyCartImage } from 'screens/kiosk/assets/imgs/empty-cart.svg';
import NoImage from 'screens/kiosk/assets/imgs/noImagePlaceholder.jpg';
import './styles.scss';

const CartPagePreview = ({
  kioskUI,
}) => (
  <div className="CartPagePreview PreviewPane">
    <div className="content">
      <div className="title">{get(kioskUI, 'cartPage.title')}</div>
      <div className="cartPage-full">
        <div className="products">
          <div className="product">
            <div className="image-container">
              <img src={NoImage} alt="placeholder" />
            </div>
            <div className="text-container">
              <div className="shadow" />
              <div className="shadow" />
            </div>
          </div>
          <div className="product">
            <div className="image-container">
              <img src={NoImage} alt="placeholder" />
            </div>
            <div className="text-container">
              <div className="shadow" />
              <div className="shadow" />
            </div>
          </div>
        </div>
        <div className="actions">
          <div className="actionBtn">
            {get(kioskUI, 'cartPage.order_btn')}
          </div>
        </div>
      </div>
      <div className="title center">{get(kioskUI, 'cartPage.title')}</div>
      <div className="cartPage-empty">
        <EmptyCartImage />
        <div className="empty-cart-title">{get(kioskUI, 'cartPage.empty_cart_description')}</div>
      </div>
    </div>
  </div>
);

CartPagePreview.propTypes = {
  kioskUI: PropTypes.object.isRequired,
};

export default CartPagePreview;
