import api from 'shared/api';
import { get } from 'lodash';

export const getBranchingCategories = (locationID, previousAnswer) => {
  const hasParent = 'parent' in previousAnswer;
  const ID = get(previousAnswer, 'id');
  const salesArea = get(previousAnswer, 'sales_area') || get(previousAnswer, 'id');

  const parentParam = hasParent ? `&parent=${ID}` : '&parent__isnull=True';
  const salesAreaParam = `&sales_area=${salesArea}`;
  const url = `/api/v1/branchingcategories/?location=${locationID}${salesAreaParam}${parentParam}`;

  return api.get(url);
};

export const getRecommendedProducts = (locationID, previousAnswer) => {
  const ID = get(previousAnswer, 'id');

  const branchingCategoryParam = `&branching_categories__in=${ID}`;
  const url = `/api/v1/products/?location=${locationID}${branchingCategoryParam}`;

  return api.get(url);
};

export const getTopSalesAreas = (locationID) => api.get(`/api/v1/topsalesareas/?location=${locationID}`);

export const getSalesAreas = (locationID, topSalesArea) => {
  const topsalesareaParam = topSalesArea ? `&top_sales_area=${topSalesArea}` : '';
  return api.get(`/api/v1/salesareas/?location=${locationID}${topsalesareaParam}`);
};

// export const getSalesAreas = (locationID) => api.get(`/api/v1/salesareas/?location=${locationID}`);
