import React from 'react';
import './styles.scss';

const Loader = () => (
  <div className="Loader">
    <div className="Loader-spinner" />
  </div>
);

export default Loader;
