import api from 'shared/api';
import { get } from 'lodash';

export const getBranchingCategories = (locationID, previousAnswer) => {
  const hasParent = 'parent' in previousAnswer;
  const ID = get(previousAnswer, 'id');
  const salesArea = get(previousAnswer, 'sales_area') || get(previousAnswer, 'id');

  const parentParam = hasParent ? `&parent=${ID}` : '&parent__isnull=True';
  const salesAreaParam = `&sales_area=${salesArea}`;
  const url = `/api/v1/branchingcategories/?location=${locationID}${salesAreaParam}${parentParam}`;

  return api.get(url);
};
