import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import { LoadingTable, NoData } from './components';
import './styles.scss';

const Table = ({
  columns, data, disabled, title, loading, loadingText, noDataText, clickable, showNoDataComponent, ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div className={`Table ${clickable ? 'Table-clickable' : ''}`}>
      <DataTable
        persistTableHead
        columns={columns}
        data={data}
        title={title}
        disabled={disabled}
        noHeader
        noDataComponent={<NoData shouldDisplay={showNoDataComponent} text={noDataText || t('Nema podataka u tablici')} />}
        pagination
        sortServer
        paginationServer
        progressPending={loading}
        progressComponent={<LoadingTable loadingText={loadingText || `${t('Učitavanje')} ...`} />}
        paginationComponentOptions={{
          rowsPerPageText: t('Redaka po stranici'),
          rangeSeparatorText: t('od'),
        }}
        {...rest}
      />
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  clickable: PropTypes.bool,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  noDataText: PropTypes.string,
  showNoDataComponent: PropTypes.bool,
  title: PropTypes.string,
};

Table.defaultProps = {
  data: [],
  clickable: false,
  disabled: false,
  loading: false,
  loadingText: '',
  noDataText: '',
  showNoDataComponent: true,
  title: '',
};

export default Table;
