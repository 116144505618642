import React from 'react';
import PropTypes from 'prop-types';
import { IoIosEyeOff } from 'react-icons/io';

const IconPassHidden = ({ color, size }) => (
  <div className="IconPassHidden">
    <IoIosEyeOff color={color} size={size} />
  </div>
);

IconPassHidden.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconPassHidden.defaultProps = {
  color: '#37383B',
  size: '16px',
};

export default IconPassHidden;
