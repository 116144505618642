import React from 'react';
import PropTypes from 'prop-types';
import Image from './no_data.svg';
import './styles.scss';

const NoData = ({ text, shouldDisplay }) => {
  if (!shouldDisplay) {
    return <div className="NoData" />;
  }

  return (
    <div className="NoData">
      <div className="NoData-image">
        <img src={Image} alt="no data" />
      </div>
      <div className="NoData-text">{text}</div>
    </div>
  );
};

NoData.propTypes = {
  shouldDisplay: PropTypes.bool,
  text: PropTypes.string,
};

NoData.defaultProps = {
  shouldDisplay: true,
  text: '',
};

export default NoData;
