import { combineReducers } from 'redux';
import accountReducer from '../account/accountReducer';
import uiReducer from '../ui/uiReducer';
import kioskReducer from '../kiosk/kioskReducer';
import promotionsReducer from '../promotions/promotionsReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  ui: uiReducer,
  kiosk: kioskReducer,
  promotions: promotionsReducer,
});

export default rootReducer;
