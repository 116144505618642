import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components';
import icon from './bug_fixing.svg';
import './styles.scss';

const GlobalError = () => {
  const { t } = useTranslation();

  return (
    <div className="GlobalError">
      <div className="wrapper">
        <img src={icon} alt="Error message" />
        <h2>{t('Dogodila se pogreška')}</h2>
        <p>
          {t('Naš je tehnički tim obaviješten, a za ponovno pokretanje pregledavanja pritisnite gumb ispod')}
        </p>
        <Button
          onClick={() => {
            window.location = '/';
          }}
        >
          {t('Ponovno pokrenite pregledavanje')}
        </Button>
      </div>
    </div>
  );
};

export default GlobalError;
