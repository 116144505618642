import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Label, TextInput,
} from 'shared/components';

const InactivityPopupEditor = ({
  kioskState,
  onChange,
}) => {
  const { t } = useTranslation();
  const [popupTitle, setTitle] = useState(get(kioskState, 'inactivityPopup.title'));
  const [closeText, setCloseText] = useState(get(kioskState, 'inactivityPopup.close_text'));
  const [inactivityTime, setInactivityTime] = useState(get(kioskState, 'inactivityPopup.inactivity_time') || 20);
  const [inactivityTimeCounter, setInactivityTimeCounter] = useState(get(kioskState, 'inactivityPopup.inactivity_time_counter') || 30);

  return (
    <div className="InactivityPopupEditor KioskMetaEditor">
      <div>
        <Label text={t('Naslov')} inputId="inactivityPopup-title" />
        <TextInput
          value={popupTitle}
          onChange={e => {
            const val = e.target.value;
            setTitle(val);
            onChange({
              type: 'inactivityPopup',
              data: {
                title: val,
                close_text: closeText,
                inactivity_time: inactivityTime,
                inactivity_time_counter: inactivityTimeCounter,
              },
            });
          }}
          name="inactivityPopup-title"
          id="inactivityPopup-title"
        />
      </div>
      <div>
        <Label text={t('Zatvoriti skočni prozor')} inputId="inactivityPopup-close" />
        <TextInput
          value={closeText}
          onChange={e => {
            const val = e.target.value;
            setCloseText(val);
            onChange({
              type: 'inactivityPopup',
              data: {
                title: popupTitle,
                close_text: val,
                inactivity_time: inactivityTime,
                inactivity_time_counter: inactivityTimeCounter,
              },
            });
          }}
          name="inactivityPopup-close"
          id="inactivityPopup-close"
        />
      </div>
      <div>
        <Label text={`${t('Vrijeme do neaktivnosti')} (${t('sekunde')})`} inputId="inactivityPopup-time" />
        <TextInput
          value={inactivityTime}
          onChange={e => {
            const val = e.target.value;
            setInactivityTime(val);
            onChange({
              type: 'inactivityPopup',
              data: {
                title: popupTitle,
                close_text: closeText,
                inactivity_time: val,
                inactivity_time_counter: inactivityTimeCounter,
              },
            });
          }}
          name="inactivityPopup-time"
          id="inactivityPopup-time"
        />
      </div>
      <div>
        <Label text={`${t('Vrijeme za odbrojavanje')} (${t('sekunde')})`} inputId="inactivityPopup-time-counter" />
        <TextInput
          value={inactivityTimeCounter}
          onChange={e => {
            const val = e.target.value;
            setInactivityTimeCounter(val);
            onChange({
              type: 'inactivityPopup',
              data: {
                title: popupTitle,
                close_text: closeText,
                inactivity_time: inactivityTime,
                inactivity_time_counter: val,
              },
            });
          }}
          name="inactivityPopup-time-counter"
          id="inactivityPopup-time-counter"
        />
      </div>
    </div>
  );
};

InactivityPopupEditor.propTypes = {
  kioskState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InactivityPopupEditor;
