/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import {
  Button, IconClose, TextInput,
} from 'shared/components';
import { capitalizeFirstLetter } from 'shared/helpers';
import { ReactComponent as EmptyCartImage } from '../../../assets/imgs/empty-cart.svg';
import './styles.scss';

const mapProductValues = products => products.reduce((acc, product) => {
  acc[product.ean] = product.quantity;
  return acc;
}, {});

const CartBox = ({
  cartProducts,
  closeCartBox,
  removeCartProduct,
  handleProductQuantity,
  showCartPage,
  ui,
}) => {
  const [inputs, setInputs] = useState({});
  const [layoutName, setLayoutName] = useState('ip');
  const [inputName, setInputName] = useState('default');
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const keyboard = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      const inputArr = document.querySelectorAll('.product-quantity-number') || [];
      if (inputArr.length) {
        const doesContain = Array.from(inputArr).find(i => i.contains(e.target));
        if (doesContain) {
          return;
        }
      }
      if (keyboard.current && get(keyboard, 'current.keyboardDOM').contains(e.target)) {
        return;
      }

      setKeyboardOpen(false);
    };

    window.addEventListener('click', handleClick);
    window.addEventListener('touchstart', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('touchstart', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInputs(mapProductValues(cartProducts));
  }, [cartProducts]);

  const onChangeAll = inputs => {
    setInputs({ ...inputs });
  };

  const onKeyboardChange = val => handleProductQuantity(Number(val), inputName);

  const onChangeInput = event => {
    const inputVal = event.target.value;
    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };

  const getInputValue = inputName => inputs[inputName] || '';

  const handleRemoveProduct = (e, product) => {
    e.preventDefault();
    e.stopPropagation();

    removeCartProduct(product);
  };

  return (
    <div className="CartBox">
      {
        !cartProducts.length && (
          <div className="CartBox-empty">
            <div className="heading">
              {get(ui, 'cartBtn.empty_cart_title')}
            </div>
            <EmptyCartImage />
            <div className="close-instructions">{get(ui, 'cartBtn.close_text')}</div>
          </div>
        )
      }
      {
        !!cartProducts.length && (
          <div className="CartBox-full">
            <div className="heading">
              {get(ui, 'cartBtn.title')}
            </div>
            <div className="CartBox-products">
              {
                cartProducts.map(product => (
                  <div className="CartBox-product-item" key={product.ean}>
                    <div className="product-image">
                      <img src={product.img} alt={product.model} />
                    </div>
                    <div className="product-meta">
                      <div className="product-cart-delete">
                        <Button theme="error" size="sm" priority="tertiary" onClick={(e) => handleRemoveProduct(e, product)}>
                          <IconClose color="#EA0016" />
                        </Button>
                      </div>
                      <div className="product-title">
                        {product.model}
                      </div>
                      <div className="product-description">
                        {capitalizeFirstLetter(get(product, 'category.name'))}
                      </div>
                      <div className="product-scope">
                        {capitalizeFirstLetter(get(product, 'scope_of_delivery'))}
                      </div>
                      <div className="product-quantity">
                        <button
                          type="button"
                          disabled={product.quantity === 1}
                          className="product-quantity-down"
                          onClick={() => handleProductQuantity(product.quantity - 1, product.ean)}
                        >
                          -
                        </button>
                        <div className="product-quantity-number">
                          <TextInput
                            // type="number"
                            id={product.ean}
                            name={product.ean}
                            value={getInputValue(product.ean) || product.quantity}
                            onChange={(e) => {
                              handleProductQuantity(Number(e.target.value), product.ean);
                              onChangeInput(e);
                            }}
                            onFocus={() => {
                              setInputName(product.ean.toString());
                              setKeyboardOpen(true);
                            }}
                          />
                        </div>
                        <button
                          type="button"
                          className="product-quantity-up"
                          onClick={() => handleProductQuantity(product.quantity + 1, product.ean)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              }
              <div className={`keyboard-container ${keyboardOpen ? '' : 'hidden'}`}>
                <Keyboard
                  // eslint-disable-next-line no-return-assign
                  keyboardRef={r => (keyboard.current = r)}
                  inputName={inputName}
                  layoutName={layoutName}
                  onChangeAll={onChangeAll}
                  onChange={onKeyboardChange}
                  layout={{
                    ip: ['1 2 3', '4 5 6', '7 8 9', '0 {clear} {bksp}'],
                  }}
                  display={{
                    '{clear}': 'C',
                    '{bksp}': 'backspace',
                  }}
                />
              </div>
            </div>
            <div className="CartBox-confirm">
              <Button
                onClick={() => {
                  closeCartBox();
                  showCartPage(true);
                }}
                size="lg"
              >
                {get(ui, 'cartBtn.view_order_btn')}
              </Button>
              <Button
                onClick={() => {
                  removeCartProduct(null);
                  closeCartBox();
                }}
                size="lg"
                theme="error"
              >
                {get(ui, 'cartBtn.cancel_btn')}
              </Button>
            </div>
          </div>
        )
      }
    </div>
  );
};

CartBox.propTypes = {
  cartProducts: PropTypes.array.isRequired,
  closeCartBox: PropTypes.func.isRequired,
  handleProductQuantity: PropTypes.func.isRequired,
  showCartPage: PropTypes.func.isRequired,
  removeCartProduct: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

export default CartBox;
