/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import {
  Button,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  Selector,
} from 'shared/components';
import { roleOptions } from 'shared/constants';
import { validationSchema } from './validation';
import { createUser, patchUser } from './actions';
import './styles.scss';

const AddUserForm = ({
  closeCb,
  isOpen,
  companyID,
  user,
  title,
  updateUsersList,
}) => {
  const { t } = useTranslation();
  const activeCompany = useSelector(state => get(state, 'kiosk.activeCompany'));
  const locations = get(activeCompany, 'locations') || [];
  const activeLocation = useSelector(state => get(state, 'kiosk.activeLocation'));
  const loggedInUserID = useSelector(state => get(state, 'account.user.id'));

  const initialEmail = get(user, 'email') || '';
  const initialName = get(user, 'name') || '';
  const initialPassword = user ? '123@Abd33w' : '';
  const initialRole = user ?
    roleOptions.filter(ro => ro.value === user.role) :
    [roleOptions[0]];
  const initialLocations = user ?
    locations.filter(l => {
      const isUserLocation = user.locations.find(ul => ul.id === l.id);
      return !!isUserLocation;
    }) :
    [activeLocation];

  const {
    register, handleSubmit, errors, formState,
  } = useForm({ validationSchema, defaultValues: { email: initialEmail, name: initialName, password: initialPassword } });

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [selectedRole, setSelectedRole] = useState(initialRole);
  const [selectedLocation, setSelectedLocation] = useState(initialLocations);
  const [selectedLocationError, setSelectedLocationError] = useState('');
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);

  const createNewUser = (data) => {
    const { email, name, password } = data;
    const userData = {
      email,
      name,
      password,
      role: get(selectedRole, '[0].value'),
      locations: selectedLocation.map(s => s.id),
    };

    createUser(companyID, userData)
      .then(() => {
        updateUsersList();
        closeCb();
        Notification('success', t('Promjene su uspješno spremljene'));
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Dogodila se pogreška'));
      });
  };

  const updateExistingUser = (data) => {
    const { email, name } = data;
    const userID = get(user, 'id');

    const userData = {
      email,
      name,
      role: get(selectedRole, '[0].value'),
      locations: selectedLocation.map(s => s.id),
    };

    patchUser(companyID, userID, userData)
      .then(() => {
        updateUsersList();
        closeCb();
        Notification('success', t('Promjene su uspješno spremljene'));
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Dogodila se pogreška'));
      });
  };

  const onSubmit = (data) => {
    if (loading) {
      return false;
    }

    if (!selectedLocation || !selectedLocation.length) {
      setSelectedLocationError(t('Ovo polje je obavezno'));
      return false;
    }

    setLoading(true);

    if (!user) {
      createNewUser(data);
    } else {
      updateExistingUser(data);
    }
    return true;
  };

  const handleClose = () => {
    if (dirty) {
      setDirtyFormAlertDisplay(true);
    } else {
      closeCb();
    }
  };

  // console.log(formState);

  return (
    <Modal
      closeCb={handleClose}
      isOpen={isOpen}
      disabled={loading}
      title={title}
      size="sm"
      shouldCloseOnOverlayClick={false}
    >
      <div className="AddUserForm">
        <form
          className="user-form"
          id="user-add-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <TextInput
              label={t('Email')}
              id="user-email"
              name="email"
              type="email"
              disabled={!!user || loading}
              register={register}
              error={t(get(errors, 'email.message'))}
            />
          </div>
          <div>
            <TextInput
              label={t('Ime i prezime')}
              id="user-name"
              name="name"
              disabled={loading}
              register={register}
              error={t(get(errors, 'name.message'))}
            />
          </div>
          <div style={{ display: user ? 'none' : 'block' }}>
            <TextInput
              label={t('Lozinka')}
              id="user-password"
              name="password"
              disabled={loading}
              register={register}
              error={t(get(errors, 'password.message'))}
            />
          </div>
          <div>
            <Label
              inputId="user-role"
              text={t('Rola')}
            />
            <Selector
              options={roleOptions}
              values={selectedRole}
              onChange={val => {
                setDirty(true);
                setSelectedRole(val);
              }}
              disabled={loggedInUserID === get(user, 'id')}
            />
          </div>
          <div>
            <Label
              inputId="user-location"
              text={t('Lokacija')}
            />
            <Selector
              options={locations}
              values={selectedLocation}
              onChange={val => {
                setDirty(true);
                setSelectedLocation(val);
              }}
              labelField="name"
              valueField="id"
              multi
              closeOnSelect
              error={selectedLocationError}
              disabled={loggedInUserID === get(user, 'id')}
            />
          </div>
          <div className="user-actions">
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
            >
              {t('Potvrdi')}
            </Button>
            <Button
              type="button"
              theme="error"
              priority="tertiary"
              onClick={closeCb}
              disabled={loading}
            >
              {t('Poništi')}
            </Button>
          </div>
        </form>
      </div>
      {
        isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={() => {
              setDirtyFormAlertDisplay(false);
              closeCb();
            }}
          />
        )
      }
    </Modal>
  );
};

AddUserForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  companyID: PropTypes.number.isRequired,
  user: PropTypes.object,
  updateUsersList: PropTypes.func.isRequired,
  title: PropTypes.string,
};

AddUserForm.defaultProps = {
  title: '',
  user: null,
};

export default AddUserForm;
