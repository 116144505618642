import { get } from 'lodash';
import api from 'shared/api';
import * as actionTypes from './actionTypes';

// user actions
const initGetUser = (actions) => () => ({
  type: actions.getUserInit,
});

const getUserError = (actions) => () => ({
  type: actions.getUserError,
});

const getUserSuccess = (actions) => (res) => ({
  type: actions.getUserSuccess,
  user: res,
});

export const getUser = () => (dispatch) => {
  dispatch(initGetUser(actionTypes)());
  return api.get('/api/v1/user/').then(
    (res) => dispatch(getUserSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getUserError(actionTypes)()),
  );
};

export const refreshUser = (res) => ({
  type: actionTypes.refreshUser,
  user: res,
});

// login actions
const initLogin = (actions) => () => ({
  type: actions.loginInit,
});

export const loginSuccess = (res) => (dispatch) => dispatch({
  type: actionTypes.loginSuccess,
  user: res,
});

export const loginError = (res) => (dispatch) => dispatch({
  type: actionTypes.loginError,
  payload: res,
});

export const login = (loginData) => (dispatch) => {
  dispatch(initLogin(actionTypes)());
  return api.post('/api/v1/login/', loginData);
};

// logout actions
export const logoutSuccess = () => (dispatch) => dispatch({
  type: actionTypes.logoutSuccess,
  user: null,
});

export const logoutError = () => (dispatch) => dispatch({
  type: actionTypes.logoutError,
});

export const logout = () => (dispatch) => {
  dispatch({ type: actionTypes.logoutInit });
  return api.post('/api/v1/logout/');
};
