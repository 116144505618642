import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { get } from 'lodash';
import {
  Button, Notification, Selector,
} from 'shared/components';
import { changeKioskUi } from 'redux/kiosk/actions';
import {
  RecommendationEditor,
  OrderPopupEditor,
  ActionBtnsEditor,
  MainTitleEditor,
  CartBtnEditor,
  AddToCartEditor,
  CartPageEditor,
  InactivityPopupEditor,
  KioskPreview,
} from '../components';
import { kioskConfigReducer } from './kioskConfigReducer';
import { saveKioskMeta } from './actions';
import './styles.scss';

const KioskMetaConfig = ({
  changeKioskUiAction,
}) => {
  const { t } = useTranslation();
  const locationId = useSelector(state => get(state, 'kiosk.activeLocation.id'));
  const kioskUI = useSelector(state => get(state, 'kiosk.ui'));

  const selectorOptions = [
    {
      label: t('Naslov'),
      value: 'mainTitle',
    },
    {
      label: `${t('Akcijski gumbi')}`,
      value: 'actionBtns',
    },
    {
      label: `${t('Preporučeni proizvodi')}`,
      value: 'recommendationPage',
    },
    {
      label: `${t('Dodaj u košaricu')}`,
      value: 'addToCartPopup',
    },
    {
      label: t('Gumb košarice'),
      value: 'cartBtn',
    },
    {
      label: t('Košarica'),
      value: 'cartPage',
    },
    {
      label: `${t('Skočni prozor narudžbe')}`,
      value: 'orderPopup',
    },
    {
      label: `${t('Skočni prozor neaktivnosti')}`,
      value: 'inactivityPopup',
    },
  ];

  const [dirtyForm, setDirtyForm] = useState(false);
  const [selectedStep, setSelectedStep] = useState([selectorOptions[0]]);
  const [kioskState, dispatch] = useReducer(kioskConfigReducer, kioskUI);

  const showKioskEditor = type => {
    switch (type) {
      case 'mainTitle': {
        return (
          <MainTitleEditor
            kioskState={kioskState}
            onChange={data => {
              setDirtyForm(true);
              dispatch(data);
            }}
          />
        );
      }
      case 'cartBtn': {
        return (
          <CartBtnEditor
            kioskState={kioskState}
            onChange={data => {
              setDirtyForm(true);
              dispatch(data);
            }}
          />
        );
      }
      case 'addToCartPopup': {
        return (
          <AddToCartEditor
            kioskState={kioskState}
            onChange={data => {
              setDirtyForm(true);
              dispatch(data);
            }}
          />
        );
      }
      case 'cartPage': {
        return (
          <CartPageEditor
            kioskState={kioskState}
            onChange={data => {
              setDirtyForm(true);
              dispatch(data);
            }}
          />
        );
      }
      case 'orderPopup': {
        return (
          <OrderPopupEditor
            kioskState={kioskState}
            onChange={data => {
              setDirtyForm(true);
              dispatch(data);
            }}
          />
        );
      }
      case 'actionBtns': {
        return (
          <ActionBtnsEditor
            kioskState={kioskState}
            onChange={data => {
              setDirtyForm(true);
              dispatch(data);
            }}
          />
        );
      }
      case 'recommendationPage': {
        return (
          <RecommendationEditor
            kioskState={kioskState}
            onChange={data => {
              setDirtyForm(true);
              dispatch(data);
            }}
          />
        );
      }
      case 'inactivityPopup': {
        return (
          <InactivityPopupEditor
            kioskState={kioskState}
            onChange={data => {
              setDirtyForm(true);
              dispatch(data);
            }}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const handleSave = () => {
    if (!dirtyForm) { return; }
    setDirtyForm(false);

    saveKioskMeta(locationId, kioskState)
      .then(() => {
        Notification('success', t('Promjene na kiosku su uspješno spremljene.'));
        changeKioskUiAction(kioskState);
      })
      .catch(() => {
        changeKioskUiAction(kioskState);
      });
  };

  const selectedValue = get(selectedStep, '[0].value');

  return (
    <div className="KioskMetaConfig">
      <Prompt
        message={t('Imate nespremljene promjene. Jeste li sigurni da želite otići?')}
        when={dirtyForm}
      />
      <div className="KioskMetaConfig-wrapper">
        <form className="working-area" onSubmit={handleSave}>
          <Button type="submit" onClick={handleSave} disabled={!dirtyForm}>{t('Spremi')}</Button>
          <div className="Kiosk-view-select">
            <Selector
              options={selectorOptions}
              values={selectedStep}
              onChange={val => setSelectedStep(val)}
            />
          </div>
          <div className="Kiosk-view-form">
            {showKioskEditor(selectedValue)}
          </div>
        </form>
        <div className="preview-area">
          <KioskPreview kioskUI={kioskState} selectedStep={selectedStep} />
        </div>
      </div>
    </div>
  );
};

KioskMetaConfig.propTypes = {
  changeKioskUiAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  changeKioskUiAction: changeKioskUi,
};

export default connect(null, mapDispatchToProps)(KioskMetaConfig);
