import { useEffect } from 'react';

const useOnClickOutside = (ref, callback, btnRef = {}) => {
  const handleClick = (e) => {
    if (btnRef.current && btnRef.current.contains(e.target)) {
      return;
    }
    if (ref.current && ref.current.contains(e.target)) {
      return;
    }

    callback();
  };

  useEffect(() => {
    window.addEventListener('click', handleClick);
    window.addEventListener('touchstart', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('touchstart', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, callback]);
};

export default useOnClickOutside;
