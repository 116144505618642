import React from 'react';
import PropTypes from 'prop-types';

const IconChevronLeft = ({
  color, size, strokeWidth, disabled,
}) => (
  <div className="IconChevronLeft" style={{ display: 'flex' }}>
    <svg viewBox="0 0 192 192" fill={disabled ? '#BFC0C2' : color} height={size} width={size}>
      <path
        stroke={disabled ? '#BFC0C2' : color}
        strokeWidth={strokeWidth}
        d="M121.2 158.8L58.3 96l62.9-62.8 5.6 5.6L69.7 96l57.1 57.2z"
      />
    </svg>
  </div>
);

IconChevronLeft.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  strokeWidth: PropTypes.number,
};

IconChevronLeft.defaultProps = {
  color: '#37383B',
  disabled: false,
  size: '16px',
  strokeWidth: 2,
};

export default IconChevronLeft;
