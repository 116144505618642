/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useOnClickOutside } from 'shared/hooks';
import {
  IconCart,
} from 'shared/components';
import CartBox from './CartBox';
import './styles.scss';

const CartBtn = ({
  cartProducts,
  removeCartProduct,
  handleProductQuantity,
  showCartPage,
  ui,
}) => {
  const cartRef = useRef();
  const cartBtnRef = useRef();
  const [isCartBoxVisible, setCartBoxVisibility] = useState(false);
  useOnClickOutside(cartRef, () => setCartBoxVisibility(false), cartBtnRef);

  const quantities = cartProducts.reduce((acc, item) => {
    const q = item.quantity;
    return acc + Number(q);
  }, 0);

  return (
    <div className={`CartBtn-wrapper ${isCartBoxVisible ? 'show' : ''}`}>
      <div
        onDragStart={() => false}
        onDrop={() => false}
        className="CartBtn"
        tabIndex={0}
        role="button"
        onClick={() => setCartBoxVisibility(!isCartBoxVisible)}
        ref={cartBtnRef}
      >
        {
          !!cartProducts.length && (
            <div className="CartBtn-number">
              {quantities}
            </div>
          )
        }
        <IconCart />
        <div className="CartBtn-title">{get(ui, 'cartBtn.title')}</div>
      </div>
      {
        isCartBoxVisible && (
          <div className="CartBtn-container" ref={cartRef}>
            <CartBox
              cartProducts={cartProducts}
              removeCartProduct={removeCartProduct}
              handleProductQuantity={handleProductQuantity}
              ui={ui}
              closeCartBox={() => setCartBoxVisibility(false)}
              showCartPage={showCartPage}
            />
          </div>
        )
      }
    </div>
  );
};

CartBtn.propTypes = {
  cartProducts: PropTypes.array.isRequired,
  removeCartProduct: PropTypes.func.isRequired,
  handleProductQuantity: PropTypes.func.isRequired,
  showCartPage: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

export default CartBtn;
