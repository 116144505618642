import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Label, TextInput,
} from 'shared/components';

const RecommendationEditor = ({
  kioskState,
  onChange,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(get(kioskState, 'recommendationPage.title'));
  const [emptyTitle, setEmptyTitle] = useState(get(kioskState, 'recommendationPage.empty_title'));
  const [ctaBtn, setCtaBtn] = useState(get(kioskState, 'recommendationPage.cta_btn'));

  return (
    <div className="RecommendationEditor KioskMetaEditor">
      <div>
        <Label text={t('Naslov')} inputId="recommendationPage-title" />
        <TextInput
          value={title}
          onChange={e => {
            const val = e.target.value;
            setTitle(val);
            onChange({
              type: 'recommendationPage',
              data: {
                title: val,
                empty_title: emptyTitle,
                cta_btn: ctaBtn,
              },
            });
          }}
          name="recommendationPage-title"
          id="recommendationPage-title"
        />
      </div>
      <div>
        <Label text={t('Gumb za karticu')} inputId="recommendationPage-ctaText" />
        <TextInput
          value={ctaBtn}
          onChange={e => {
            const val = e.target.value;
            setCtaBtn(val);
            onChange({
              type: 'recommendationPage',
              data: {
                title,
                empty_title: emptyTitle,
                cta_btn: val,
              },
            });
          }}
          name="recommendationPage-ctaText"
          id="recommendationPage-ctaText"
        />
      </div>
      <div>
        <Label text={t('Prazna preporuka')} inputId="recommendationPage-empty" />
        <TextInput
          value={emptyTitle}
          onChange={e => {
            const val = e.target.value;
            setEmptyTitle(val);
            onChange({
              type: 'recommendationPage',
              data: {
                title,
                empty_title: val,
                cta_btn: ctaBtn,
              },
            });
          }}
          name="recommendationPage-empty"
          id="recommendationPage-empty"
        />
      </div>
    </div>
  );
};

RecommendationEditor.propTypes = {
  kioskState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RecommendationEditor;
