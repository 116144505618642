/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  Button,
  IconClose,
  TextInput,
} from 'shared/components';
import { capitalizeFirstLetter, formatDate } from 'shared/helpers';
import Keyboard from 'react-simple-keyboard';
import {
  BackBtn,
  BoschLogo,
  StepTitle,
  OrderPopup,
} from '../components';
import { ReactComponent as EmptyCartImage } from '../assets/imgs/empty-cart.svg';
import { createOrder } from './actions';
import 'react-simple-keyboard/build/css/index.css';
import './styles.scss';


// eslint-disable-next-line
const formatProducts = products => {
  const dataObj = {};

  products.forEach(p => {
    dataObj[p.ean] = p.quantity;
  });

  return dataObj;
};

const mapProductValues = products => products.reduce((acc, product) => {
  acc[product.ean] = product.quantity;
  return acc;
}, {});

const CartPage = ({
  cartProducts,
  handleProductQuantity,
  removeCartProduct,
  handleRestart,
  showCartPage,
  showOrderPopup,
  handleOrderPopupDisplay,
  ui,
}) => {
  const [t] = useTranslation();
  const locationId = useSelector(state => get(state, 'kiosk.activeLocation.id'));

  const [loading, setLoading] = useState(false);
  // const [showOrderPopup, setOrderPopupShow] = useState(false);
  const [orderID, setOrderID] = useState(null);

  const [inputs, setInputs] = useState({});
  const [layoutName, setLayoutName] = useState('ip');
  const [inputName, setInputName] = useState('default');
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const keyboard = useRef();

  const printLabel = (order) => {
    const data = {
      number: get(order, 'id') || '',
      datetime: formatDate(get(order, 'created_at')),
      items: order.order_products.map(op => ({ name: get(op, 'product.model') || '', ean: get(op, 'product.ean') || '', quantity: get(op, 'quantity') || '' })),
    };
    // eslint-disable-next-line no-undef
    const printSocket = new WebSocket('ws://localhost:5555', ['binary']);
    printSocket.binaryType = 'arraybuffer';
    printSocket.onopen = (event) => {
      // console.log('Socket is connected.', event);
      if (printSocket.readyState !== printSocket.OPEN) {
        // console.log('directPrint(): Socket is not open!', event);
        return false;
      }
      printSocket.send(JSON.stringify(data));
      printSocket.close(1000);
      return true;
    };
  };

  useEffect(() => {
    const handleClick = (e) => {
      const inputArr = document.querySelectorAll('.quantity-text-input') || [];
      if (inputArr.length) {
        const doesContain = Array.from(inputArr).find(i => i.contains(e.target));
        if (doesContain) {
          return;
        }
      }
      if (keyboard.current && get(keyboard, 'current.keyboardDOM').contains(e.target)) {
        return;
      }

      setKeyboardOpen(false);
    };

    window.addEventListener('click', handleClick);
    window.addEventListener('touchstart', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
      window.removeEventListener('touchstart', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInputs(mapProductValues(cartProducts));
  }, [cartProducts]);

  const onChangeAll = inputValues => {
    setInputs({ ...inputValues });
    // eslint-disable-next-line no-console
    // console.log('Inputs changed', inputValues);
  };

  const onKeyboardChange = val => handleProductQuantity(Number(val), inputName);

  const onChangeInput = event => {
    const inputVal = event.target.value;

    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };

  const getInputValue = inputValueName => inputs[inputValueName] || '';

  const handleOrderCreate = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const data = {
      location: locationId,
      products: formatProducts(cartProducts),
    };

    createOrder(locationId, data)
      .then((res) => {
        setOrderID(res.data.id);
        // setOrderPopupShow(true);
        handleOrderPopupDisplay();
        // send QR code value to printer
        // printSocket.current.send(res.data.id);
        printLabel(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="CartPage KioskStep">
      <div className="top-banner">
        <BoschLogo />
      </div>
      <div className="step-actions step-actions-mobile">
        <BackBtn ui={ui} handleBack={() => showCartPage(false)} disabled={loading} />
      </div>
      <StepTitle title={get(ui, 'cartPage.title')} />
      <div className="CartPage-container">
        {
          cartProducts.length ? (
            <>
              <div className="CartBox-products">
                <BackBtn ui={ui} handleBack={() => showCartPage(false)} disabled={loading} />
                <div className="inner-container">
                  {
                    cartProducts.map(product => (
                      <div className="CartBox-product-item" key={product.ean}>
                        <div className="product-image">
                          <img src={product.img} alt={product.title} draggable="false" />
                        </div>
                        <div className="product-meta">
                          <div className="product-cart-delete">
                            <Button disabled={loading} theme="error" size="sm" priority="tertiary" onClick={() => removeCartProduct(product)}>
                              <IconClose color="#EA0016" />
                            </Button>
                          </div>
                          <div className="product-title">
                            {product.model}
                          </div>
                          <div className="product-description">
                            {capitalizeFirstLetter(get(product, 'category.name'))}
                          </div>
                          <div className="product-scope">
                            {capitalizeFirstLetter(get(product, 'scope_of_delivery'))}
                          </div>
                          <div className="product-quantity">
                            <button
                              type="button"
                              disabled={product.quantity === 1 || loading}
                              className="product-quantity-down"
                              onClick={() => handleProductQuantity(product.quantity - 1, product.ean)}
                            >
                              -
                            </button>
                            <div className="product-quantity-number quantity-text-input">
                              <TextInput
                                // type="number"
                                id={product.ean}
                                name={product.ean}
                                value={getInputValue(product.ean) || product.quantity}
                                onChange={(e) => {
                                  handleProductQuantity(Number(e.target.value), product.ean);
                                  onChangeInput(e);
                                }}
                                onFocus={() => {
                                  setInputName(product.ean.toString());
                                  setKeyboardOpen(true);
                                }}
                                disabled={loading}
                              />
                            </div>
                            <button
                              type="button"
                              className="product-quantity-up"
                              onClick={() => handleProductQuantity(product.quantity + 1, product.ean)}
                              disabled={loading}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  <div className={`keyboard-container ${keyboardOpen ? '' : 'hidden'}`}>
                    <Keyboard
                      keyboardRef={r => { keyboard.current = r; }}
                      inputName={inputName}
                      layoutName={layoutName}
                      onChangeAll={onChangeAll}
                      onChange={onKeyboardChange}
                      layout={{
                        ip: ['1 2 3', '4 5 6', '7 8 9', '0 {clear} {bksp}'],
                      }}
                      display={{
                        '{clear}': 'C',
                        '{bksp}': 'backspace',
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="CartBox-confirm">
                <Button
                  onClick={handleOrderCreate}
                  size="lg"
                  theme="success"
                  disabled={loading}
                >
                  {get(ui, 'cartPage.order_btn')}
                </Button>
              </div>
            </>
          ) : (
            <div className="CartBox-products CartPage-empty">
              <BackBtn ui={ui} handleBack={() => showCartPage(false)} disabled={loading} />
              <EmptyCartImage />
              <div className="heading">
                {get(ui, 'cartPage.empty_cart_description')}
              </div>
            </div>
          )
        }
      </div>
      {showOrderPopup && (
        <OrderPopup
          isOpen={showOrderPopup}
          cartProducts={cartProducts}
          emptyCart={() => removeCartProduct(null)}
          closeCartPage={() => showCartPage(false)}
          handleRestart={handleRestart}
          // handleClose={() => setOrderPopupShow(false)}
          handleClose={() => handleOrderPopupDisplay()}
          ui={ui}
        >
          {orderID}
        </OrderPopup>
      )}
    </div>
  );
};

CartPage.propTypes = {
  cartProducts: PropTypes.array.isRequired,
  handleProductQuantity: PropTypes.func.isRequired,
  handleRestart: PropTypes.func.isRequired,
  removeCartProduct: PropTypes.func.isRequired,
  showCartPage: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  handleOrderPopupDisplay: PropTypes.func.isRequired,
  showOrderPopup: PropTypes.bool.isRequired,
};

export default CartPage;


// import Keyboard from "react-simple-keyboard";
// import "react-simple-keyboard/build/css/index.css";

// class App extends Component {
//   state = {
//     layoutName: "ip",
//     inputName: "input1",
//     input: {},
//     // Just for demo purposes
//     submittedData: ""
//   };

//   onChangeAll = inputObj => {
//     this.setState({
//       input: inputObj
//     });

//     console.log("Input changed", inputObj);
//   };

//   onKeyPress = button => {
//     console.log("Button pressed", button);
//     if (button === "{clear}") this.clearScreen();
//   };

//   onChangeInput = event => {
//     let inputVal = event.target.value;

//     let updatedInputObj = {
//       ...this.state.input,
//       [this.state.inputName]: inputVal
//     };

//     this.setState(
//       {
//         input: updatedInputObj
//       },
//       () => {
//         this.keyboard.setInput(inputVal);
//       }
//     );
//   };

//   setActiveInput = inputName => {
//     this.setState(
//       {
//         inputName: inputName,
//         keyboardOpen: true
//       },
//       () => {
//         console.log("Active input", inputName);
//       }
//     );
//   };

//   closeKeyboard = () => {
//     this.setState({
//       keyboardOpen: false
//     });
//   };

//   submit = () => {
//     this.setState({
//       submittedData: JSON.stringify(this.state.input)
//     });
//     console.log(this.state.input);
//   };

//   clearScreen = () => {
//     let input = { ...this.state.input };
//     let inputName = this.state.inputName;
//     input[inputName] = "";

//     this.setState({ input }, () => {
//       this.keyboard.clearInput(inputName);
//       console.log(
//         "cleared",
//         input,
//         this.keyboard.options.inputName,
//         this.keyboard.input,
//         this.keyboard.getInput()
//       );
//     });
//   };

//   render() {
//     let { input, keyboardOpen, submittedData } = this.state;

//     return (
//       <div>
//         <h3>Tap on an input to reveal the keyboard</h3>
//         <div className="inputsContainer">
//           <input
//             onFocus={() => this.setActiveInput("input1")}
//             value={input["input1"] || ""}
//             placeholder={"Input 1"}
//             onChange={e => this.onChangeInput(e)}
//           />
//           <input
//             onFocus={() => this.setActiveInput("input2")}
//             value={input["input2"] || ""}
//             placeholder={"Input 2"}
//             onChange={e => this.onChangeInput(e)}
//           />
//           <input
//             onFocus={() => this.setActiveInput("input3")}
//             value={input["input3"] || ""}
//             placeholder={"Input 3"}
//             onChange={e => this.onChangeInput(e)}
//           />
//           <input
//             onFocus={() => this.setActiveInput("input4")}
//             value={input["input4"] || ""}
//             placeholder={"Input 4"}
//             onChange={e => this.onChangeInput(e)}
//           />
//           <input
//             onFocus={() => this.setActiveInput("input5")}
//             value={input["input5"] || ""}
//             placeholder={"Input 5"}
//             onChange={e => this.onChangeInput(e)}
//           />
//           <input
//             onFocus={() => this.setActiveInput("input6")}
//             value={input["input6"] || ""}
//             placeholder={"Input 6"}
//             onChange={e => this.onChangeInput(e)}
//           />
//         </div>
//         <div className={`keyboardContainer ${!keyboardOpen ? "hidden" : ""}`}>
//           <Keyboard
//             keyboardRef={r => (this.keyboard = r)}
//             inputName={this.state.inputName}
//             layoutName={this.state.layoutName}
//             onChangeAll={inputObj => this.onChangeAll(inputObj)}
//             onKeyPress={button => this.onKeyPress(button)}
//             layout={{
//               ip: ["1 2 3", "4 5 6", "7 8 9", ". 0 {clear}"]
//             }}
//             display={{
//               "{clear}": "DEL",
//             }}
//           />
//           <button className="submitBtn" onClick={this.submit}>
//             Submit
//           </button>
//           <button className="closeBtn" onClick={this.closeKeyboard}>
//             Close Keyboard
//           </button>
//         </div>
//         {submittedData &&
//           <div className={"submittedData"}><h4>Submitted Data:</h4><div className="data">{submittedData}</div></div>
//         }
//       </div>
//     );
//   }
// }
