import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconClose,
  IconAlert,
} from 'shared/components';
import './styles.scss';

const ConfirmationPopup = ({
  confirmText,
  cancelText,
  disabled,
  title,
  theme,
  children,
  confirmCb,
  closeCb,
  warning,
  ...props
}) => {
  const { t } = useTranslation();

  return createPortal(
    <div
      className={`ConfirmationPopup ${warning ? 'ConfirmationPopup-warning' : ''}`}
      {...props}
    >
      <div className="ConfirmationPopup-inner">
        <div className="ConfirmationPopup-header">
          {warning && (
            <IconAlert height="40px" width="40px" />
          )}
          {title}
        </div>
        {
          children && (
            <div className="ConfirmationPopup-body">
              {children}
            </div>
          )
        }
        <div className="ConfirmationPopup-footer">
          <Button
            onClick={confirmCb}
            theme={theme}
            disabled={disabled}
          >
            {t(confirmText)}
          </Button>
          <Button
            onClick={closeCb}
            theme="link"
            priority="tertiary"
            disabled={disabled}
          >
            {cancelText}
          </Button>
          <button
            type="button"
            className="ConfirmationPopup-dismiss"
            onClick={closeCb}
            disabled={disabled}
          >
            <IconClose
              height="16px"
            />
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

ConfirmationPopup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'info', 'warning', 'success', 'error', 'link']),
  title: PropTypes.string,
  warning: PropTypes.bool,
};

ConfirmationPopup.defaultProps = {
  children: '',
  confirmText: 'Potvrdi',
  cancelText: 'Poništi',
  disabled: false,
  warning: false,
  theme: 'success',
  title: 'Jeste li sigurni?',
};

export default ConfirmationPopup;
