/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  Menu,
  MenuItem,
  MenuButton,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {
  Button,
  IconSearch,
  IconEdit,
  // Label,
  LoadingIndicator,
  Notification,
  Selector,
  Switcher,
  Table,
  TablePagination,
} from 'shared/components';
import {
  bulkActiveStatusChange,
  getProducts,
  patchProductLocation,
} from './actions';
import TableExpandedRow from './components/TableExpandedRow';
import NewMaterialForm from './components/NewMaterialForm';
import NewUsageForm from './components/NewUsageForm';
import ProductForm from './components/ProductForm';
import TopHeader from '../../../../topHeader';
import './styles.scss';

const ProductList = ({
  userRole,
}) => {
  const { t } = useTranslation();
  const locationId = useSelector(state => get(state, 'kiosk.activeLocation.id') || []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [toggledClearRows, setTogleClearRow] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [disabledSwitchers, setDisabledSwitchers] = useState([]);
  const [selectedBulkAction, setSelectedBulkAction] = useState({ value: 'activate', label: t('Aktivirati') });
  // get products params
  const [totalRows, setTotalRows] = useState(0);
  const [tablePage, setTablePage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [sortAsc, setSortAsc] = useState('asc');
  const [sortType, setSortType] = useState('');
  const [searchType, setSearchType] = useState({ value: 'model', label: t('Pretraži po nazivu proizvoda') });
  const [searchQuery, setSearchQuery] = useState('');

  const [productToEdit, setProductToEdit] = useState(null);
  const [showProductForm, setProductFormDisplay] = useState(false);

  const [showMaterialForm, setMaterialFormDisplay] = useState(false);
  const [showUsageForm, setUsageFormDisplay] = useState(false);

  const fetchProducts = async (page, isLoading = true) => {
    setLoading(isLoading);
    const response = await getProducts(locationId, page, perPage, sortAsc, sortType, searchType, searchQuery);

    setData(response.data.results);
    setTotalRows(response.data.count);
    setTablePage(page);
    setLoading(false);
  };

  const handlePageChange = page => {
    setSelectedProducts([]);
    setTogleClearRow(!toggledClearRows);
    fetchProducts(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setSelectedProducts([]);
    setTogleClearRow(!toggledClearRows);
    setLoading(true);
    const response = await getProducts(locationId, page, newPerPage, sortAsc, sortType, searchType, searchQuery);

    setData(response.data.results);
    setPerPage(newPerPage);
    setTablePage(page);
    setLoading(false);
  };

  const handleSort = async (column, sortDirection) => {
    setLoading(true);
    const { selector } = column;
    const response = await getProducts(locationId, 1, perPage, sortDirection, selector, searchType, searchQuery);

    setSortType(selector);
    setSortAsc(sortDirection);
    setData(response.data.results);
    setTotalRows(response.data.count);
    setTablePage(1);
    setLoading(false);
  };

  const handleSelectedRowsChange = ({ selectedRows }) => setSelectedProducts(selectedRows);

  const handleProductLocationStatus = (checked, ean, field = 'is_active') => {
    const productToPatch = data.find(d => d.ean === ean);
    const newDisabled = [...disabledSwitchers, ean];
    setDisabledSwitchers(newDisabled);

    let dataObj = {};
    if (field === 'is_active') {
      dataObj = { is_active: checked };
    }
    if (field === 'is_currently_featured' && !checked) {
      dataObj = { is_currently_featured: checked, featured_group: null, is_featured: checked };
    }
    if (field === 'is_currently_featured' && !!checked) {
      dataObj = { is_featured: checked };
    }

    patchProductLocation(productToPatch.product_location.id, dataObj)
      .then(() => {
        const newData = data.map((d) => {
          if (d.ean === ean) {
            // eslint-disable-next-line no-param-reassign
            d.product_location[field] = checked;
            return d;
          }
          return d;
        });
        setData(newData);
        const newDisabledSwitchers = disabledSwitchers.filter(d => d !== ean);
        setDisabledSwitchers(newDisabledSwitchers);
        Notification('success', t('Promjene su uspješno spremljene.'));
      })
      .catch(() => {
        Notification('error', t('Promjene nisu uspjele.'));
        const newDisabledSwitchers = disabledSwitchers.filter(d => d !== ean);
        setDisabledSwitchers(newDisabledSwitchers);
      });
  };

  const handleProductBulkActive = () => {
    const isActive = selectedBulkAction.value === 'activate';
    const dict = selectedProducts.reduce((acc, item) => {
      acc[item.ean] = isActive;
      return acc;
    }, {});
    setBulkLoading(true);
    setLoading(true);

    bulkActiveStatusChange(locationId, dict)
      .then(() => {
        getProducts(locationId, tablePage, perPage, sortAsc, sortType, searchType, searchQuery)
          .then((response) => {
            setData(get(response, 'data.results'));
            setTotalRows(get(response, 'data.count'));
            setSelectedProducts([]);
            setTogleClearRow(!toggledClearRows);
            setBulkLoading(false);
            setLoading(false);
            setSelectedBulkAction({ value: 'activate', label: t('Aktivirati') });
            Notification('success', t('Promjene su uspješno spremljene.'));
          });
      })
      .catch(() => {
        setBulkLoading(false);
        setLoading(false);
        Notification('error', t('Promjene nisu uspjele.'));
      });
  };

  const handleBulkActionConfirm = () => {
    if (selectedBulkAction.value === 'activate' || selectedBulkAction.value === 'deactivate') {
      handleProductBulkActive();
    }
  };

  const handleEmptySearch = async () => {
    setLoading(true);
    setSearchLoading(true);
    setSearchQuery('');

    const response = await getProducts(locationId, 1, perPage, sortAsc, sortType, searchType, '');

    setData(response.data.results);
    setTotalRows(response.data.count);
    setTablePage(1);
    setLoading(false);
    setSearchLoading(false);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSearchLoading(true);

    const response = await getProducts(locationId, 1, perPage, sortAsc, sortType, searchType, searchQuery);

    setData(response.data.results);
    setTotalRows(response.data.count);
    setTablePage(1);
    setLoading(false);
    setSearchLoading(false);
  };

  useEffect(() => {
    fetchProducts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const columns = [
    {
      name: t('Ime proizvoda'),
      selector: 'model',
      sortable: true,
    },
    {
      name: t('Kategorija'),
      selector: 'category.name',
      sortable: true,
      hide: 'sm',
      format: (row) => {
        if (!get(row, 'category.name')) {
          return '-';
        }
        return get(row, 'category.name');
      },
    },
    {
      name: t('EAN'),
      selector: 'ean',
      sortable: true,
      hide: 'sm',
    },
    {
      name: t('Segment'),
      selector: 'segment',
      sortable: true,
      hide: 'sm',
    },
    {
      name: t('Promocija'),
      selector: 'product_location.is_currently_featured',
      sortable: true,
      ignoreRowClick: true,
      maxWidth: '120px',
      cell: row => (
        <div className="product-active-cell">
          <Switcher
            size="sm"
            checked={row.product_location.is_currently_featured}
            disabled={disabledSwitchers.includes(get(row, 'ean'))}
            handleChange={val => handleProductLocationStatus(val, row.ean, 'is_currently_featured')}
          />
        </div>
      ),
    },
    {
      name: t('Aktivan'),
      selector: 'product_location.is_active',
      sortable: true,
      ignoreRowClick: true,
      maxWidth: '120px',
      cell: row => (
        <div className="product-active-cell">
          <Switcher
            size="sm"
            checked={row.product_location.is_active}
            disabled={disabledSwitchers.includes(get(row, 'ean'))}
            handleChange={val => handleProductLocationStatus(val, row.ean, 'is_active')}
          />
        </div>
      ),
    },
    {
      name: '',
      id: 'edit',
      sortable: false,
      ignoreRowClick: true,
      maxWidth: '120px',
      cell: row => (
        <div className="product-active-cell">
          <Button
            size="sm"
            theme="link"
            priority="tertiary"
            onClick={() => {
              setProductToEdit(row);
              setProductFormDisplay(true);
            }}
          >
            <IconEdit />
          </Button>
        </div>
      ),
    },
  ];

  const searchTypeOptions = [
    {
      value: 'model',
      label: t('Pretraži po nazivu proizvoda'),
    },
    {
      value: 'category__name',
      label: t('Pretraži po kategorijama'),
    },
    {
      value: 'segment',
      label: t('Pretraži po segmentima'),
    },
    {
      value: 'power_source',
      label: t('Pretraži prema izvoru energije'),
    },
    {
      value: 'code',
      label: t('Pretraži po šifri'),
    },
    {
      value: 'ean',
      label: t('Pretraži po EAN-u'),
    },
  ];

  return (
    <div className="ProductList">
      <TopHeader userRole={userRole} />
      <div className="ProductList-search">
        <div className="SearchForm">
          <form className="SearchForm-form" onSubmit={handleSearchSubmit}>
            <Selector
              options={searchTypeOptions}
              values={[searchType]}
              onChange={(val) => setSearchType(get(val, '[0]'))}
            />
            <div className="search-input-wrapper">
              <input
                disabled={searchLoading}
                onChange={e => setSearchQuery(e.target.value)}
                value={searchQuery}
                placeholder={t('Pretraži')}
              />
              {searchLoading && <LoadingIndicator />}
              {searchQuery && (
                <button
                  type="button"
                  className="search-clear-btn"
                  onClick={handleEmptySearch}
                >
                  &times;
                </button>
              )}
            </div>
            <div className="submit-search-btn">
              <Button type="submit">
                <IconSearch color="#fff" />
              </Button>
            </div>
          </form>
        </div>
        <div className="Product-add">
          <Button onClick={() => setProductFormDisplay(true)}>{t('Dodaj proizvod')}</Button>
          <Menu
            direction="bottom"
            align="end"
            menuButton={<MenuButton className="Button Button-size-md Button-theme-default Button-priority-secondary">{t('Dodatne akcije')}</MenuButton>}
          >
            <MenuItem>
              <Button theme="link" priority="tertiary" onClick={() => setMaterialFormDisplay(true)}>{t('Dodaj materijal')}</Button>
            </MenuItem>
            <MenuItem>
              <Button theme="link" priority="tertiary" onClick={() => setUsageFormDisplay(true)}>{t('Dodaj uporabu')}</Button>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="ProductList-filters">
        {/* <div className="sales-area">
          <Label inputId="prodajno_područje" text={t('Prodajno područje')} />
          <Selector
            options={[
              {
                value: 'power_tools',
                label: t('Power tools'),
              },
              {
                value: 'tt',
                label: t('TT (Thermotechnology)'),
              },
              {
                value: 'aa',
                label: t('AA (Automotive Aftermarket)'),
                disabled: true,
              },
              {
                value: 'bsh',
                label: t('BSH (Bosch Home Appliances)'),
                disabled: true,
              },
            ]}
            values={[{ value: 'power_tools', label: t('Power tools') }]}
            onChange={() => { }}
          />
        </div> */}
      </div>
      <div className="ProductList-table-action">
        <div className="ProductList-table-actions">
          {
            selectedProducts.length ? (
              <div className="bulk-actions-container active">
                <div className="total-selected">
                  {`${t('Odabrano proizvoda')}: ${selectedProducts.length}`}
                </div>
                <div className="bulk-action-btns">
                  <Selector
                    options={[
                      {
                        value: 'activate',
                        label: t('Aktivirati'),
                      },
                      {
                        value: 'deactivate',
                        label: t('Deaktivirati'),
                      },
                      // {
                      //   value: 'is_featured',
                      //   label: t('Dodati u promociju'),
                      // },
                      // {
                      //   value: 'not_featured',
                      //   label: t('Prekinuti promociju'),
                      // },
                    ]}
                    disabled={!selectedProducts.length || bulkLoading}
                    values={[selectedBulkAction]}
                    onChange={(val) => setSelectedBulkAction(get(val, '[0]'))}
                  />
                  <Button
                    size="sm"
                    disabled={!selectedProducts.length || bulkLoading}
                    onClick={handleBulkActionConfirm}
                  >
                    {t('Potvrdi')}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="bulk-actions-container" />
            )
          }
        </div>
      </div>
      <div className="ProductList-table">
        <Table
          columns={columns}
          data={data}
          loading={loading}
          pagination={false}
          paginationServer
          selectableRows
          selectableRowsHighlight
          onSelectedRowsChange={handleSelectedRowsChange}
          onSort={handleSort}
          defaultSortField={sortType}
          defaultSortAsc={sortAsc === 'asc'}
          expandableRows
          expandableRowsComponent={<TableExpandedRow locationID={locationId} updateProducts={() => getProducts(locationId, tablePage, perPage, sortAsc, sortType, searchType, searchQuery)} />}
          clearSelectedRows={toggledClearRows}
          onRowClicked={() => {}}
          // clickable
        />
        <TablePagination
          rowsPerPage={perPage}
          rowCount={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          currentPage={tablePage}
          loading={loading}
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
        />
      </div>
      {showProductForm && (
        <ProductForm
          isOpen={showProductForm}
          closeCb={() => {
            setProductFormDisplay(false);
            setProductToEdit(null);
          }}
          product={productToEdit}
          refetchProducts={() => fetchProducts(tablePage, false)}
        />
      )}
      {showMaterialForm && (
        <NewMaterialForm
          isOpen={showMaterialForm}
          closeCb={() => setMaterialFormDisplay(false)}
        />
      )}
      {showUsageForm && (
        <NewUsageForm
          isOpen={showUsageForm}
          closeCb={() => setUsageFormDisplay(false)}
        />
      )}
    </div>
  );
};

ProductList.propTypes = {
  userRole: PropTypes.string,
};

ProductList.defaultProps = {
  userRole: '',
};

export default ProductList;
