import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  Button,
  DatePicker,
  InputErrorMessage,
  Label,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import { validationSchema } from './validation';
import { createPromotion, patchPromotion } from './actions';
import './styles.scss';

const PromotionPopup = ({
  closeCb,
  isOpen,
  locationId,
  promotionToEdit,
  updatePromotionsList,
  title,
}) => {
  const initialName = get(promotionToEdit, 'name') || '';
  const initialTimeStart = promotionToEdit ? moment(get(promotionToEdit, 'time_start')) : null;
  const initialTimeEnd = promotionToEdit ? moment(get(promotionToEdit, 'time_end')) : null;

  const { t } = useTranslation();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({ validationSchema, defaultValues: { name: initialName } });

  const [loading, setLoading] = useState(false);
  const [timeStart, setTimeStart] = useState(initialTimeStart);
  const [timeStartError, setTimeStartError] = useState(null);
  const [timeEnd, setTimeEnd] = useState(initialTimeEnd);
  const [timeEndError, setTimeEndError] = useState(null);

  const editExistingPromotion = data => {
    const promotionToUpdateID = get(promotionToEdit, 'id');
    patchPromotion(locationId, promotionToUpdateID, data)
      .then((res) => {
        const newPromotion = get(res, 'data');
        updatePromotionsList(newPromotion);
        closeCb();
        Notification('success', t('Promjene su uspješno spremljene.'));
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Promjene nisu uspjele.'));
      });
  };

  const addNewPromotion = data => (
    createPromotion(locationId, data)
      .then((res) => {
        const promotionID = get(res, 'data.id');
        history.push(`/promocije/${promotionID}`);
        Notification('success', t('Promjene su uspješno spremljene.'));
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Promjene nisu uspjele.'));
      })
  );

  const onSubmit = (data) => {
    if (loading) {
      return false;
    }

    if (!timeStart || !timeEnd) {
      if (!timeStart) {
        setTimeStartError(t('Ovo polje je obavezno'));
      }
      if (!timeEnd) {
        setTimeEndError(t('Ovo polje je obavezno'));
      }
      return false;
    }

    setLoading(true);
    const formData = {
      name: get(data, 'name'),
      time_start: timeStart.format(),
      time_end: timeEnd.format(),
      location: locationId,
    };

    if (promotionToEdit) {
      editExistingPromotion(formData);
    } else {
      addNewPromotion(formData);
    }
    return true;
  };

  return (
    <Modal
      closeCb={closeCb}
      isOpen={isOpen}
      size="sm"
      disabled={loading}
      title={title}
    >
      <div className="PromotionPopup">
        <form
          className="promotion-form"
          id="promotion-add-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <TextInput
              label={t('Naziv promocije')}
              id="promotion-name"
              name="name"
              disabled={loading}
              register={register}
              error={t(get(errors, 'name.message'))}
            />
          </div>
          <div>
            <Label
              inputId="promotion-time-start"
              text={t('Datum početka')}
            />
            <DatePicker
              date={timeStart}
              onDateSelect={val => setTimeStart(val)}
              id="time-start-input"
              disabled={loading}
              readOnly
              isOutsideRange={() => false}
            />
            { timeStartError && <InputErrorMessage text={timeStartError} /> }
          </div>
          <div>
            <Label
              inputId="promotion-time-end"
              text={t('Datum završetka')}
            />
            <DatePicker
              date={timeEnd}
              onDateSelect={val => setTimeEnd(val)}
              id="time-end-input"
              disabled={loading}
              readOnly
              isOutsideRange={() => false}
            />
            { timeEndError && <InputErrorMessage text={timeEndError} /> }
          </div>
          <div className="promotion-actions">
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              {t('Potvrdi')}
            </Button>
            <Button
              type="button"
              theme="error"
              priority="tertiary"
              onClick={closeCb}
              disabled={loading}
            >
              {t('Poništi')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

PromotionPopup.propTypes = {
  closeCb: PropTypes.func.isRequired,
  updatePromotionsList: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  locationId: PropTypes.number.isRequired,
  promotionToEdit: PropTypes.object,
  title: PropTypes.string,
};

PromotionPopup.defaultProps = {
  promotionToEdit: null,
  title: '',
};

export default PromotionPopup;
