import React from 'react';
import PropTypes from 'prop-types';

const IconLock = ({ color, size }) => (
  <div className="IconLock">
    <svg fill={color} width={size} height={size} viewBox="0 0 192 192">
      <path
        stroke={color}
        strokeWidth={5}
        d="M140 72V60c0-24.262-19.738-44-44-44S52 35.738 52 60v12H32v104h128V72h-20zM60 60c0-19.85 16.15-36 36-36s36 16.15 36 36v12H60V60zm92 108H40V80h112v88zm-68-20h24v-21.437c2.561-2.908 4-6.666 4-10.563 0-4.542-1.94-8.885-5.325-11.918a16.061 16.061 0 0 0-12.47-3.983c-7.398.812-13.335 6.778-14.115 14.187-.479 4.547.935 8.929 3.91 12.282V148zm4.046-32.876c.383-3.634 3.406-6.675 7.033-7.073 2.317-.258 4.542.451 6.257 1.989A8.015 8.015 0 0 1 104 116a8.007 8.007 0 0 1-2.67 5.948l-1.33 1.193V140h-8v-16.86l-1.33-1.192a7.951 7.951 0 0 1-2.624-6.824z"
      />
    </svg>
  </div>
);

IconLock.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconLock.defaultProps = {
  color: '#37383B',
  size: '16px',
};

export default IconLock;
