import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Spinner } from 'shared/components';
import {
  getInnerContainerStyle,
  getItemStyle,
} from 'shared/helpers';
import { getSalesAreas } from './actions';
import StepTitle from '../StepTitle';
import BackBtn from '../BackBtn';
import SingleAnswerBox from '../SingleAnswerBox';

const InitialStep = ({
  salesArea,
  handleSelection,
  handleProgressChange,
  handleBack,
  ui,
}) => {
  const companies = useSelector(state => get(state, 'account.user.companies') || []);
  const locationId = get(companies, '[0].id');
  const topSalesAreaID = get(salesArea, 'id');

  const [loading, setLoading] = useState(true);
  const [salesAreas, setSalesAreas] = useState([]);

  useEffect(() => {
    getSalesAreas(locationId, topSalesAreaID)
      .then((res) => {
        const data = get(res, 'data.results') || [];
        setSalesAreas(data);
        handleProgressChange(0, 3);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  if (loading) {
    return (
      <div className="InitialStep">
        <div className="KioskStep-answers-loading">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div
      onDragStart={() => false}
      onDrop={() => false}
      className="InitialStep"
    >
      <StepTitle title={get(ui, 'mainTitle.title')} />
      <div className="KioskStep-answers">
        <BackBtn ui={ui} handleBack={handleBack} />
        <div className="inner-container" style={getInnerContainerStyle(salesAreas)}>
          {salesAreas.map((a) => (
            <SingleAnswerBox
              key={a.name}
              answer={a}
              handleSelection={handleSelection}
              customStyle={getItemStyle(salesAreas)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

InitialStep.propTypes = {
  salesArea: PropTypes.object.isRequired,
  handleSelection: PropTypes.func.isRequired,
  handleProgressChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

export default InitialStep;
