// No image placeholder
import NoImage from '../../assets/imgs/noImagePlaceholder.jpg';

export const getImage = (img) => {
  if (!img) {
    return NoImage;
  }

  return img;
};
