import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Button, Spinner, IconChevronLeft } from 'shared/components';
import {
  getInnerContainerStyle,
  getItemStyle,
} from 'shared/helpers';
import { getRecommendedProducts } from './actions';
import StepTitle from '../StepTitle';
import RecommendedProduct from '../RecommendedProduct';
import AddToCartPopup from '../AddToCartPopup';
import RestartBtn from '../RestartBtn';
import BackBtn from '../BackBtn';
import RecommendedProductListItem from '../RecommendedProductListItem';
import { ReactComponent as NoRecommendationImage } from '../../assets/imgs/empty-recommendation.svg';
import { getMainPhoto } from '../../assets/helpers';
import './styles.scss';

const RecommendationStep = ({
  answers,
  handleProductSelection,
  handleProgressChange,
  handleRestart,
  handleBack,
  showCartPage,
  removeCartProduct,
  ui,
  productsCategories,
}) => {
  const lastIndex = answers.length - 1;
  const lastAnswer = get(answers, `[${lastIndex}]`);
  const companies = useSelector(state => get(state, 'account.user.companies') || []);
  const locationId = get(companies, '[0].id');

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showAddToCartPopup, setAddToCartPopupShow] = useState(false);

  const fetchRecommendations = () => {
    setLoading(true);

    getRecommendedProducts(locationId, lastAnswer, productsCategories)
      .then((res) => {
        const data = get(res, 'data.results') || [];
        setProducts(data);
        handleProgressChange(answers.length, answers.length);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    fetchRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastIndex, lastAnswer]);

  const handleProductSelect = (product) => {
    handleProductSelection(product);
    setSelectedProduct(product);
    setAddToCartPopupShow(true);
  };

  if (loading) {
    return (
      <div className="RecommendationStep">
        <div className="KioskStep-answers-loading">
          <Spinner />
        </div>
      </div>
    );
  }

  if (!products.length) {
    return (
      <div className="RecommendationStep empty">
        <StepTitle title={get(ui, 'recommendationPage.empty_title')} />
        <div className="inner-container">
          <div className="RecommendationStep-products">
            <NoRecommendationImage />
            <BackBtn ui={ui} handleBack={handleBack} />
            <RestartBtn ui={ui} handleRestart={handleRestart} />
          </div>
        </div>
      </div>
    );
  }

  const formattedProducts = products.map((p) => ({
    ...p,
    img: getMainPhoto(p.photos),
    quantity: 1,
  }));

  const isAccessoriesSelected = get(answers, '[0].id') === 11;

  return (
    <div className="RecommendationStep">
      <StepTitle title={get(ui, 'recommendationPage.title')} />
      <div className="inner-container">
        <div className="RecommendationStep-products">
          <BackBtn ui={ui} handleBack={handleBack} />
          <RestartBtn ui={ui} handleRestart={handleRestart} />
          <div className="inner-container" style={getInnerContainerStyle(formattedProducts)}>
            {formattedProducts.map((p, i) => {
              if (i > 16) {
                return null;
              }
              if (isAccessoriesSelected) {
                return (
                  <RecommendedProductListItem
                    product={p}
                    key={p.ean}
                    onProductSelect={handleProductSelect}
                    ctaText={get(ui, 'recommendationPage.cta_btn')}
                  />
                );
              }
              return (
                <RecommendedProduct
                  product={p}
                  key={p.ean}
                  onProductSelect={handleProductSelect}
                  ctaText={get(ui, 'recommendationPage.cta_btn')}
                  customStyle={getItemStyle(formattedProducts)}
                />
              );
            })}
          </div>
        </div>
      </div>
      {showAddToCartPopup && (
        <AddToCartPopup
          isOpen={showAddToCartPopup}
          handleClose={() => setAddToCartPopupShow(false)}
          ui={ui}
          selectedProduct={selectedProduct}
        >
          <div>
            <div className="popup-header">
              {get(ui, 'addToCartPopup.title')}
              <div className="product-name">{get(selectedProduct, 'title')}</div>
            </div>
            <div className="popup-actions">
              <RestartBtn ui={ui} handleRestart={handleRestart} />
              <div className="continue-btn">
                <button
                  className="action-btn continue"
                  type="button"
                  onClick={() => setAddToCartPopupShow(false)}
                >
                  <IconChevronLeft />
                  <div className="action-btn-text">{get(ui, 'addToCartPopup.continue_btn')}</div>
                </button>
              </div>
              <Button
                onClick={() => {
                  setAddToCartPopupShow(false);
                  showCartPage(true);
                }}
                size="lg"
              >
                {get(ui, 'addToCartPopup.view_order_btn')}
              </Button>
              <Button
                onClick={() => {
                  setAddToCartPopupShow(false);
                  removeCartProduct(selectedProduct);
                }}
                size="lg"
                theme="error"
              >
                {get(ui, 'addToCartPopup.cancel_btn')}
              </Button>
            </div>
            <div className="close-instructions">{get(ui, 'addToCartPopup.close_text')}</div>
          </div>
        </AddToCartPopup>
      )}
    </div>
  );
};

RecommendationStep.propTypes = {
  answers: PropTypes.array.isRequired,
  handleProductSelection: PropTypes.func.isRequired,
  handleProgressChange: PropTypes.func.isRequired,
  handleRestart: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  showCartPage: PropTypes.func.isRequired,
  removeCartProduct: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  productsCategories: PropTypes.array,
};

RecommendationStep.defaultProps = {
  productsCategories: [],
};

export default RecommendationStep;
