import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconEdit } from 'shared/components';
import NoImage from 'screens/kiosk/assets/imgs/noImagePlaceholder.jpg';
import './styles.scss';

const OptionItem = ({
  disabled,
  handleEditClick,
  handleSelectClick,
  isSelected,
  value,
}) => {
  const { t } = useTranslation();
  const optionClassName = `Option-item ${isSelected ? 'selected' : ''}`;

  return (
    <div
      className={optionClassName}
    >
      <div className={`image-container ${value.photo ? '' : 'empty'}`}>
        <img src={value.photo || NoImage} alt={value.name} />
      </div>
      <div className="meta-container">
        <div className="content">{value.name}</div>
        <div className="actions">
          <button
            className="edit-btn"
            type="button"
            onClick={() => handleEditClick(value)}
            disabled={disabled || value.name.includes('od ')}
          >
            <IconEdit />
            {t('Uredi')}
          </button>
          <button
            className="select-btn"
            type="button"
            onClick={() => handleSelectClick(value)}
            disabled={disabled}
          >
            {t('Odaberi')}
          </button>
        </div>
      </div>
    </div>
  );
};

OptionItem.propTypes = {
  disabled: PropTypes.bool,
  handleEditClick: PropTypes.func.isRequired,
  handleSelectClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  value: PropTypes.object.isRequired,
};

OptionItem.defaultProps = {
  disabled: false,
  isSelected: false,
};

export default OptionItem;
