import React from 'react';
import PropTypes from 'prop-types';
// import { get } from 'lodash';
import StepTitle from '../StepTitle';
import BackBtn from '../BackBtn';
import './styles.scss';

const InitialStep = ({
  customStep,
  handleBack,
  ui,
}) => (
  <div
    onDragStart={() => false}
    onDrop={() => false}
    className="InitialStep CustomStep"
  >
    <StepTitle title={customStep.message} />
    <div className="KioskStep-answers">
      <BackBtn ui={ui} handleBack={handleBack} />
    </div>
  </div>
);

InitialStep.propTypes = {
  customStep: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

export default InitialStep;
