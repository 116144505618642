import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { useWindowSize } from 'shared/hooks';
import './styles.scss';

const SnackBar = (type, text, heading = '') => {
  const windowSize = useWindowSize();
  const config = {
    hideAfter: 3,
    position: windowSize.width < 500 ? 'bottom-center' : 'bottom-right',
    heading,
  };

  switch (type) {
    case 'success': {
      return cogoToast.success(text, config);
    }
    case 'error': {
      return cogoToast.error(text, config);
    }
    case 'info': {
      return cogoToast.info(text, config);
    }
    case 'warn': {
      return cogoToast.warn(text, config);
    }
    case 'loading': {
      return cogoToast.loading(text, config);
    }
    default: {
      return cogoToast.info(text, config);
    }
  }
};

SnackBar.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  heading: PropTypes.string,
  type: PropTypes.oneOf(['success', 'warn', 'info', 'error', 'loading']),
};

SnackBar.defaultProps = {
  body: null,
  type: 'info',
  heading: '',
};

export default SnackBar;
