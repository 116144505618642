import * as actionTypes from './actionTypes';

const initialState = {
  // lang: 'hr',
  lang: 'slo',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.changeLang:
      return { ...state, lang: action.lang };
    default:
      return { ...state };
  }
}
