import React from 'react';
import PropTypes from 'prop-types';
import { InputErrorMessage } from 'shared/components';
import './styles.scss';

const Checkbox = ({
  disabled,
  error,
  inputId,
  label,
  register,
  name,
  tableRef,
  ...rest
}) => (
  <div className={`Checkbox ${disabled ? 'Checkbox-disabled' : ''}`}>
    <label htmlFor={inputId}>
      <input
        type="checkbox"
        className={error ? 'has-error' : ''}
        id={inputId}
        disabled={disabled}
        ref={register || tableRef}
        name={name}
        {...rest}
      />
      {label}
    </label>
    {error && <InputErrorMessage text={error} />}
  </div>
);

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  label: PropTypes.string,
  register: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    // eslint-disable-next-line no-undef
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  name: PropTypes.string.isRequired,
  tableRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    // eslint-disable-next-line no-undef
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

Checkbox.defaultProps = {
  disabled: false,
  error: '',
  label: '',
  register: null,
  tableRef: null,
};

export default Checkbox;
