import api from 'shared/api';

export const updateProductImage = (productID, locationID, data) => {
  const url = `/api/v1/products/${productID}/main_photo/?location=${locationID}`;

  return api.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
