import { mapUserLocations } from 'shared/helpers';
import * as actionTypes from './actionTypes';

const initialState = {
  userLoading: true,
  checkingUser: false,
  user: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getUserInit:
      return { ...state, userLoading: true };
    case actionTypes.getUserSuccess:
      return {
        ...state,
        userLoading: false,
        user: mapUserLocations(action.user),
        error: undefined,
      };
    case actionTypes.getUserError:
      return { ...state, userLoading: false };
    case actionTypes.loginInit:
      return { ...state, checkingUser: true };
    case actionTypes.loginSuccess:
      return {
        ...state,
        user: mapUserLocations(action.user),
        checkingUser: false,
        error: undefined,
      };
    case actionTypes.loginError:
      return { ...state, error: action.payload, checkingUser: false };
    case actionTypes.logoutSuccess: {
      return { ...initialState, userLoading: false, error: undefined };
    }
    case actionTypes.logoutError: {
      return { ...state, error: action.payload };
    }
    default:
      return { ...state };
  }
}
