import React from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './styles.scss';

const TableExpandedRow = ({
  data,
}) => {
  const { t } = useTranslation();
  const { status, result } = data;
  const traceback = get(result, 'traceback');

  return (
    <div
      className="TableExpandedRow"
    >
      <div className="data-container">
        <div className="details-heading">{`${t('Dodatni detalji')}:`}</div>
        {
          status === 'success' && (
            <table className="details-table">
              <tbody>
                <tr>
                  <td>{t('Total rows')}</td>
                  <td>{get(result, 'rows.total') || '-'}</td>
                </tr>
                <tr>
                  <td>{t('Valid rows')}</td>
                  <td>{get(result, 'rows.valid') || '-'}</td>
                </tr>
                <tr>
                  <td>{t('To import')}</td>
                  <td>{get(result, 'import.to_import') || '-'}</td>
                </tr>
                <tr>
                  <td>{t('Created')}</td>
                  <td>{get(result, 'import.created') || '-'}</td>
                </tr>
                <tr>
                  <td>{t('In location')}</td>
                  <td>{get(result, 'import.in_location') || '-'}</td>
                </tr>
                <tr>
                  <td>{t('Added to location')}</td>
                  <td>{get(result, 'import.added_to_location') || '-'}</td>
                </tr>
                <tr>
                  <td>{t('To connect')}</td>
                  <td>{get(result, 'connect.to_connect') || '-'}</td>
                </tr>
                <tr>
                  <td>{t('Connected')}</td>
                  <td>{get(result, 'connect.connected') || '-'}</td>
                </tr>
                <tr>
                  <td>{t('Not in database')}</td>
                  <td>{get(result, 'connect.not_in_db') || '-'}</td>
                </tr>
                <tr>
                  <td>{t('No new images')}</td>
                  <td>{get(result, 'connect.no_new_images') || '-'}</td>
                </tr>
              </tbody>
            </table>
          )
        }
        {
          status === 'error' && traceback && (
            <div className="DescriptionList-errors">
              {traceback}
            </div>
          )
        }
        {
          status === 'error' && !traceback && (
            <div className="DescriptionList-errors">
              errors here
            </div>
          )
        }
        {
          status === 'running' && (
            <div className="DescriptionList-errors">
              Import process is running
            </div>
          )
        }
        {
          status === 'pending' && (
            <div className="DescriptionList-errors">
              Import process is waiting to be run
            </div>
          )
        }
      </div>
    </div>
  );
};

TableExpandedRow.propTypes = {
  data: PropTypes.object,
};

TableExpandedRow.defaultProps = {
  data: {},
};

export default TableExpandedRow;
