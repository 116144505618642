/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  Button,
  ConfirmationPopup,
  IconDelete,
  IconEdit,
  IconLock,
  Notification,
  Table,
  TablePagination,
} from 'shared/components';
import TopHeader from 'topHeader';
import {
  getUsers,
  deleteUser,
} from './actions';
import AddUserForm from './components/AddUserForm';
import RoleLabel from './components/RoleLabel';
import ResetPasswordForm from './components/ResetPasswordForm';
import './styles.scss';

const UsersList = ({
  userRole,
}) => {
  const { t } = useTranslation();
  const companyID = useSelector(state => get(state, 'kiosk.activeCompany.id') || []);
  const loggedInUserID = useSelector(state => get(state, 'account.user.id'));

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAddUserFormDisplayed, setAddUserFormDisplay] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userDeleteLoading, setUserDeleteLoading] = useState(false);
  const [isUserDeleteConfirmationPopupDisplay, setUserDeleteConfirmationPopupDisplay] = useState(false);
  const [isResetPasswordFormDisplay, setResetPasswordFormDisplay] = useState(false);
  // get users params
  const [totalRows, setTotalRows] = useState(0);
  const [tablePage, setTablePage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [sortAsc, setSortAsc] = useState('asc');
  const [sortType, setSortType] = useState('');

  const fetchUsers = async (page, isLoading = true) => {
    setLoading(isLoading);
    const response = await getUsers(companyID, page, perPage, sortAsc, sortType);

    setData(response.data.results);
    setTotalRows(response.data.count);
    setTablePage(page);
    setLoading(false);
  };

  const handlePageChange = page => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await getUsers(companyID, page, newPerPage, sortAsc, sortType);

    setData(response.data.results);
    setPerPage(newPerPage);
    setTablePage(page);
    setLoading(false);
  };

  const handleSort = async (column, sortDirection) => {
    setLoading(true);
    const { selector } = column;
    const response = await getUsers(companyID, 1, perPage, sortDirection, selector);

    setSortType(selector);
    setSortAsc(sortDirection);
    setData(response.data.results);
    setTotalRows(response.data.count);
    setTablePage(1);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserDelete = () => {
    if (userDeleteLoading) {
      return false;
    }
    setUserDeleteLoading(true);
    const userID = get(userToDelete, 'id');

    deleteUser(companyID, userID)
      .then(() => {
        fetchUsers(tablePage);
        setUserDeleteConfirmationPopupDisplay(false);
        setUserToDelete(null);
        setUserDeleteLoading(false);
        Notification('success', t('Promjene su uspješno spremljene'));
      })
      .catch(() => {
        setUserDeleteLoading(false);
        Notification('error', t('Dogodila se pogreška'));
      });
    return true;
  };

  const columns = [
    {
      name: t('Email'),
      selector: 'email',
      sortable: true,
    },
    {
      name: t('Ime i prezime'),
      selector: 'name',
      sortable: true,
    },
    {
      name: t('Rola'),
      selector: 'role',
      sortable: true,
      cell: row => <RoleLabel role={get(row, 'role')} />,
    },
    {
      name: t('Promijeni lozinku'),
      selector: 'id',
      sortable: false,
      center: true,
      ignoreRowClick: true,
      cell: row => (
        <div className="delete-cell">
          <Button
            theme="link"
            priority="tertiary"
            onClick={() => {
              setUserToEdit(row);
              setResetPasswordFormDisplay(true);
            }}
          >
            <IconLock />
          </Button>
        </div>
      ),
    },
    {
      name: t('Uredi'),
      selector: 'id',
      sortable: false,
      center: true,
      ignoreRowClick: true,
      maxWidth: '120px',
      cell: row => (
        <div className="delete-cell">
          <Button
            theme="link"
            priority="tertiary"
            onClick={() => {
              setUserToEdit(row);
              setAddUserFormDisplay(true);
            }}
          >
            <IconEdit />
          </Button>
        </div>
      ),
    },
    {
      name: t('Obriši'),
      selector: 'id',
      sortable: false,
      center: true,
      ignoreRowClick: true,
      maxWidth: '120px',
      cell: row => (
        <div className="user-delete-cell">
          <Button
            theme="link"
            priority="tertiary"
            onClick={() => {
              setUserToDelete(row);
              setUserDeleteConfirmationPopupDisplay(true);
            }}
            disabled={loggedInUserID === row.id}
          >
            <IconDelete />
          </Button>
        </div>
      ),
    },
  ];

  const usersList = data.filter(d => d.email !== 'admin@admin.com');

  return (
    <div className="UsersList">
      <TopHeader userRole={userRole} />
      <div className="UserList-actions">
        <Button
          size="sm"
          disabled={false}
          onClick={() => setAddUserFormDisplay(true)}
        >
          {t('Dodaj korisnika')}
        </Button>
      </div>
      <div className="UsersList-table">
        <Table
          columns={columns}
          data={usersList}
          loading={loading}
          pagination={false}
          paginationServer
          onSort={handleSort}
          defaultSortField={sortType}
          defaultSortAsc={sortAsc === 'asc'}
          // eslint-disable-next-line no-console
          onRowClicked={() => {}}
          // clickable
        />
        <TablePagination
          rowsPerPage={perPage}
          rowCount={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          currentPage={tablePage}
          loading={loading}
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
        />
      </div>
      {
        isAddUserFormDisplayed && (
          <AddUserForm
            isOpen={isAddUserFormDisplayed}
            closeCb={() => {
              setAddUserFormDisplay(false);
              setUserToEdit(null);
            }}
            companyID={companyID}
            updateUsersList={() => fetchUsers(tablePage)}
            user={userToEdit}
            title={userToEdit ? t('Uredi korisnika') : t('Dodaj korisnika')}
          />
        )
      }
      {
        isResetPasswordFormDisplay && (
          <ResetPasswordForm
            isOpen={isResetPasswordFormDisplay}
            closeCb={() => {
              setResetPasswordFormDisplay(false);
              setUserToEdit(null);
            }}
            companyID={companyID}
            updateUsersList={() => fetchUsers(tablePage)}
            user={userToEdit}
            title={t('Promijeni lozinku')}
          />
        )
      }
      {
        isUserDeleteConfirmationPopupDisplay && (
          <ConfirmationPopup
            closeCb={() => {
              setUserToDelete(null);
              setUserDeleteConfirmationPopupDisplay(false);
            }}
            confirmCb={handleUserDelete}
            confirmText={t('Izbriši')}
            cancelText={t('Poništi')}
            disabled={userDeleteLoading}
            theme="error"
            title={t('Jeste li sigurni da želite izbrisati ovog korisnika?')}
          >
            <div className="user-delete-popup-name">{userToDelete.name}</div>
          </ConfirmationPopup>
        )
      }
    </div>
  );
};

UsersList.propTypes = {
  userRole: PropTypes.string,
};

UsersList.defaultProps = {
  userRole: '',
};

export default UsersList;
