import React from 'react';
import PropTypes from 'prop-types';
import { TabPanel } from 'react-tabs';
import './styles.scss';

const TabContent = ({
  children,
  ...rest
}) => (
  <TabPanel {...rest}>
    {children}
  </TabPanel>
);

TabContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

TabContent.defaultProps = {
  children: null,
};

TabContent.tabsRole = 'TabPanel';

export default TabContent;
