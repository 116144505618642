import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import './styles.scss';

const InactivityPreview = ({
  kioskUI,
}) => (
  <div className="InactivityPreview PreviewPane">
    <div className="content">
      <div className="popup">
        <div className="title">{get(kioskUI, 'inactivityPopup.title')}</div>
        <div className="seconds">5s</div>
        <div className="close">{get(kioskUI, 'inactivityPopup.close_text')}</div>
      </div>
    </div>
  </div>
);

InactivityPreview.propTypes = {
  kioskUI: PropTypes.object.isRequired,
};

export default InactivityPreview;
