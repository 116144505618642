import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  ActionBtnsPreview,
  AddToCartPreview,
  CartBtnPreview,
  CartPagePreview,
  InactivityPreview,
  MainTitlePreview,
  OrderPopupPreview,
  RecommendationPreview,
} from './components';
import './styles.scss';

const KioskPreview = ({
  kioskUI,
  selectedStep,
}) => {

  const getPreviewComponent = () => {
    const selected = get(selectedStep, '[0].value');
    switch (selected) {
      case 'mainTitle': {
        return <MainTitlePreview kioskUI={kioskUI} />;
      }
      case 'actionBtns': {
        return <ActionBtnsPreview kioskUI={kioskUI} />;
      }
      case 'recommendationPage': {
        return <RecommendationPreview kioskUI={kioskUI} />;
      }
      case 'addToCartPopup': {
        return <AddToCartPreview kioskUI={kioskUI} />;
      }
      case 'cartBtn': {
        return <CartBtnPreview kioskUI={kioskUI} />;
      }
      case 'cartPage': {
        return <CartPagePreview kioskUI={kioskUI} />;
      }
      case 'orderPopup': {
        return <OrderPopupPreview kioskUI={kioskUI} />;
      }
      case 'inactivityPopup': {
        return <InactivityPreview kioskUI={kioskUI} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className="KioskPreview">
      <div className="preview-selected-step">{get(selectedStep, '[0].label')}</div>
      <div className="area-bounds">
        {getPreviewComponent()}
      </div>
    </div>
  );
};

KioskPreview.propTypes = {
  kioskUI: PropTypes.object.isRequired,
  selectedStep: PropTypes.array.isRequired,
};

export default KioskPreview;
