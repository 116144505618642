import { get } from 'lodash';
import api from 'shared/api';
import * as actionTypes from './actionTypes';

// Kiosk ui
export const changeKioskUi = (kioskUi) => (dispatch) => {
  localStorage.setItem('kioskUI', JSON.stringify(kioskUi));

  return dispatch({
    type: actionTypes.changeKioskUi,
    kioskUi,
  });
};

export const updateTopSalesArea = (areaList) => (dispatch) => dispatch({
  type: actionTypes.updateTopSalesArea,
  payload: areaList,
});

// usages actions
const initGetUsages = (actions) => () => ({
  type: actions.getUsagesInit,
});

const getUsagesError = (actions) => () => ({
  type: actions.getUsagesError,
});

const getUsagesSuccess = (actions) => (res) => ({
  type: actions.getUsagesSuccess,
  usages: res,
});

export const getUsages = (locationID) => (dispatch) => {
  dispatch(initGetUsages(actionTypes)());
  return api.get(`/api/v1/usages/?location=${locationID}`).then(
    (res) => dispatch(getUsagesSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getUsagesError(actionTypes)()),
  );
};

export const updateUsages = (res) => ({
  type: actionTypes.refreshUsages,
  usages: res,
});

// materials actions
const initGetMaterials = (actions) => () => ({
  type: actions.getMaterialsInit,
});

const getMaterialsError = (actions) => () => ({
  type: actions.getMaterialsError,
});

const getMaterialsSuccess = (actions) => (res) => ({
  type: actions.getMaterialsSuccess,
  materials: res,
});

export const getMaterials = (locationID) => (dispatch) => {
  dispatch(initGetMaterials(actionTypes)());
  return api.get(`/api/v1/materials/?location=${locationID}`).then(
    (res) => dispatch(getMaterialsSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getMaterialsError(actionTypes)()),
  );
};

export const updateMaterials = (res) => ({
  type: actionTypes.refreshMaterials,
  materials: res,
});

// categories actions
const initGetCategories = (actions) => () => ({
  type: actions.getCategoriesInit,
});

const getCategoriesError = (actions) => () => ({
  type: actions.getCategoriesError,
});

const getCategoriesSuccess = (actions) => (res) => ({
  type: actions.getCategoriesSuccess,
  categories: res,
});

export const getCategories = (locationID) => (dispatch) => {
  dispatch(initGetCategories(actionTypes)());
  return api.get(`/api/v1/categories/?location=${locationID}`).then(
    (res) => dispatch(getCategoriesSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getCategoriesError(actionTypes)()),
  );
};

export const updateCategories = (res) => ({
  type: actionTypes.refreshCategories,
  categories: res,
});

// segments actions
const initGetSegments = (actions) => () => ({
  type: actions.getSegmentsInit,
});

const getSegmentsError = (actions) => () => ({
  type: actions.getSegmentsError,
});

const getSegmentsSuccess = (actions) => (res) => ({
  type: actions.getSegmentsSuccess,
  segments: res,
});

export const getSegments = (locationID) => (dispatch) => {
  dispatch(initGetSegments(actionTypes)());
  return api.get(`/api/v1/products/distinct_segments/?location=${locationID}`).then(
    (res) => dispatch(getSegmentsSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getSegmentsError(actionTypes)()),
  );
};

export const updateSegments = (res) => ({
  type: actionTypes.refreshSegments,
  segments: res,
});
