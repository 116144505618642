import React from 'react';
import PropTypes from 'prop-types';

const IconChevronRightDouble = ({
  color, size, strokeWidth, disabled,
}) => (
  <div className="IconChevronRight" style={{ display: 'flex' }}>
    <svg viewBox="0 0 192 192" fill={disabled ? '#BFC0C2' : color} width={size} height={size}>
      <path
        stroke={disabled ? '#BFC0C2' : color}
        strokeWidth={strokeWidth}
        d="M50.8 158.8l-5.6-5.6L102.3 96 45.2 38.8l5.6-5.6L113.7 96z"
      />
      <path
        stroke={disabled ? '#BFC0C2' : color}
        strokeWidth={strokeWidth}
        d="M90.8 158.8l-5.6-5.6L142.3 96 85.2 38.8l5.6-5.6L153.7 96z"
      />
    </svg>
  </div>
);

IconChevronRightDouble.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  strokeWidth: PropTypes.number,
};

IconChevronRightDouble.defaultProps = {
  color: '#37383B',
  disabled: false,
  size: '16px',
  strokeWidth: 2,
};

export default IconChevronRightDouble;
