import api from 'shared/api';

export const getUsers = (
  companyID, page, perPage, sortDirection, sortColumn,
) => {
  const offsetPage = page - 1;
  const offset = (!page || offsetPage < 1) ? 0 : offsetPage * perPage;
  const sortDir = sortDirection === 'desc' ? '-' : '';
  let sort;
  if (sortColumn === 'category.name') {
    sort = 'category__name';
  } else if (sortColumn === 'product_location.is_active') {
    sort = 'product_location__is_active';
  } else if (sortColumn === 'product_location.is_featured') {
    sort = 'product_location__is_featured';
  } else {
    sort = sortColumn;
  }
  const sortBy = (sortDirection && sortColumn) ? `&order_by=${sortDir}${sort}` : '';

  return api.get(`/api/v1/users/?company=${companyID}&offset=${offset}&limit=${perPage}${sortBy}`);
};

export const deleteUser = (companyID, userID) => api.delete(`/api/v1/users/${userID}/?company=${companyID}`);
