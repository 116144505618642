import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CustomPrompt, isUserAuthorized, Spinner } from 'shared/components';
import { useWindowSize } from 'shared/hooks';
import AppContainer from 'app/AppContainer';
import LoginContainer from 'screens/login/LoginContainer';
import { Helmet } from 'react-helmet';
import { getUser } from 'redux/account/actions';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const App = ({
  getUserAction, lang, loading, user,
}) => {
  const windowSize = useWindowSize();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    getUserAction();
  }, [getUserAction]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="App">
      <Helmet>
        <title>Bosch Smart Kiosk</title>
      </Helmet>
      <Router
        getUserConfirmation={(message, callback) => CustomPrompt(message, callback, t('Upozorenje'))}
      >
        <Switch>
          <Route exact path="/login" component={LoginContainer} />
          <Route path="/" component={isUserAuthorized(AppContainer, user)} />
        </Switch>
      </Router>
      <ToastContainer
        autoClose={3000}
        closeButton={false}
        position={windowSize.width < 500 ? 'bottom-center' : 'bottom-right'}
        toastClassName="Notification-container"
        hideProgressBar={false}
      />
    </div>
  );
};

App.propTypes = {
  getUserAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: get(state, 'account.userLoading'),
  user: get(state, 'account.user'),
  lang: get(state, 'ui.lang'),
});

const mapDispatchToProps = {
  getUserAction: getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
