/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import {
  AsyncSelector,
  Button,
  InputErrorMessage,
  IconDelete,
  Label,
  Modal,
  Notification,
  Table,
  Selector,
} from 'shared/components';
import { updatePromotions } from 'redux/promotions/actions';
import { getProducts, addProductsToPromotion } from './actions';
import './styles.scss';

const AddPromotionProductsPopup = ({
  closeCb,
  isOpen,
  locationId,
  promotion,
  title,
  updatePromotionsList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const promotionsList = useSelector(state => get(state, 'promotions.list') || []);
  const promotionProducts = useSelector(state => get(state, 'promotions.products') || []);

  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState({ value: 'model', label: t('Pretraži po nazivu proizvoda') });
  const [products, setProducts] = useState([]);

  const searchTypeOptions = [
    {
      value: 'model',
      label: t('Pretraži po nazivu proizvoda'),
    },
    {
      value: 'ean',
      label: t('Pretraži po EAN-u'),
    },
  ];

  const columns = [
    {
      name: t('Ime proizvoda'),
      selector: 'model',
      sortable: true,
    },
    {
      name: t('EAN'),
      selector: 'ean',
      sortable: true,
      hide: 'sm',
    },
    {
      name: t('Obriši'),
      selector: 'ean',
      sortable: false,
      center: true,
      ignoreRowClick: true,
      maxWidth: '120px',
      cell: row => (
        <div className="delete-cell">
          <Button
            theme="link"
            priority="tertiary"
            onClick={() => {
              const newProductsList = products.filter(p => p.ean !== row.ean);
              setProducts(newProductsList);
            }}
          >
            <IconDelete />
          </Button>
        </div>
      ),
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return false;
    }

    setLoading(true);
    const promotionID = get(promotion, 'id');
    const data = products.map(p => p.ean);

    addProductsToPromotion(locationId, promotionID, data)
      .then((res) => {
        const newPromotion = get(res, 'data');
        updatePromotionsList(newPromotion);
        closeCb();
        Notification('success', t('Promjene su uspješno spremljene.'));
        // update promotions list in redux
        const updatedPromotions = promotionsList.map(p => {
          if (p.id === promotionID) {
            return newPromotion;
          }
          return p;
        });
        dispatch(updatePromotions(updatedPromotions));
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Promjene nisu uspjele.'));
      });
    return true;
  };

  const checkIfProductInPromotions = (product) => {
    const isInPromotions = promotionProducts.find(p => p.ean === product.ean);
    return !isInPromotions;
  };

  return (
    <Modal
      closeCb={closeCb}
      isOpen={isOpen}
      disabled={loading}
      title={title}
    >
      <div className="AddPromotionProductsPopup">
        <form
          className="promotion-form"
          id="promotion-add-products-form"
          onSubmit={handleSubmit}
        >
          <div>
            <Label
              inputId="promotion-add-products"
              text={t('Pronađi proizvod')}
            />
            <Selector
              options={searchTypeOptions}
              values={[searchType]}
              onChange={(val) => setSearchType(get(val, '[0]'))}
            />
            <AsyncSelector
              isClearable
              labelKey="model"
              valueKey="ean"
              fetchInitialOptions={() => getProducts(locationId)}
              fetchOptions={val => getProducts(locationId, searchType, val)}
              placeholder={t('Pretraži')}
              handleChange={val => {
                const isAlreadyAdded = products.find(p => p.ean === val.ean);
                if (!isAlreadyAdded) {
                  setProducts([...products, val]);
                }
              }}
              filterCb={checkIfProductInPromotions}
            />
          </div>
          <div className="promotion-products-list">
            {
              products.length ? (
                <Table
                  columns={columns}
                  data={products}
                  sortServer={false}
                  paginationServer={false}
                  pagination={false}
                  clickable={false}
                  showNoDataComponent={false}
                />
              ) : null
            }
          </div>
          <div className="promotion-actions">
            <Button
              type="submit"
              onClick={() => { }}
              disabled={loading}
            >
              {t('Potvrdi')}
            </Button>
            <Button
              type="button"
              theme="error"
              priority="tertiary"
              onClick={closeCb}
              disabled={loading}
            >
              {t('Poništi')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

AddPromotionProductsPopup.propTypes = {
  closeCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  locationId: PropTypes.number.isRequired,
  promotion: PropTypes.object.isRequired,
  updatePromotionsList: PropTypes.func.isRequired,
  title: PropTypes.string,
};

AddPromotionProductsPopup.defaultProps = {
  title: '',
};

export default AddPromotionProductsPopup;
