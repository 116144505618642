/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Range, getTrackBackground } from 'react-range';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components';
import './styles.scss';

const SliderBox = ({
  handleSliderSelect,
  handleValueSubmit,
  handleCustomStepSubmit,
  min,
  max,
  step,
  initialValue,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(initialValue);

  return (
    <div className="SliderBox">
      <Range
        step={step}
        min={min}
        max={max}
        onChange={val => setValues(val)}
        onFinalChange={val => handleSliderSelect(val)}
        values={values}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className="SliderBox-track"
            role="slider"
            tabIndex={0}
            aria-valuemax={max}
            aria-valuemin={min}
            aria-valuenow={values[0]}
          >
            <div
              ref={props.ref}
              className="SliderBox-track-inner"
              style={{
                background: getTrackBackground({
                  values,
                  colors: ['#005691', '#BFC0C2'],
                  min,
                  max,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            className="SliderBox-thumb"
            style={{
              ...props.style,
              backgroundColor: isDragged ? '#008ECF' : '#005691',
            }}
          >
            {values[0]}
          </div>
        )}
      />
      <div className="actions">
        <div className="submit-btn">
          <Button
            onClick={() => {
              // console.log(values);
              if (values[0] > 100) {
                handleValueSubmit(values);
                handleCustomStepSubmit({ value: 'Klime', description: 'more than 100m2', message: t('Molimo kontaktirajte našu prodajnu podršku') });
              } else {
                handleValueSubmit(values);
              }
            }}
            size="lg"
            theme="success"
          >
            {t('Potvrdi')}
          </Button>
        </div>
        {/* <div className="custom-btn">
          <Button
            onClick={() => {
              handleValueSubmit([101]);
              handleCustomStepSubmit({ value: 'Klime', description: 'more than 100m2', message: t('Molimo kontaktirajte našu prodajnu podršku') });
            }}
            size="lg"
            theme="link"
          >
            {`${t('Više od')} 100m2`}
          </Button>
        </div> */}
      </div>
    </div>
  );
};

SliderBox.propTypes = {
  handleSliderSelect: PropTypes.func.isRequired,
  handleValueSubmit: PropTypes.func.isRequired,
  handleCustomStepSubmit: PropTypes.func.isRequired,
  initialValue: PropTypes.array,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
};

SliderBox.defaultProps = {
  initialValue: [20],
  max: 200,
  min: 0,
  step: 1,
};

export default SliderBox;
