import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  IconChevronLeft, IconReset,
} from 'shared/components';
import './styles.scss';

const ActionBtnsPreview = ({
  kioskUI,
}) => (
    <div className="ActionBtnsPreview PreviewPane">
      <div className="content">
        <div className="backBtn actionBtn">
          <IconChevronLeft />
          <div className="btnText">{get(kioskUI, 'actionBtns.back_btn')}</div>
        </div>
        <div className="restartBtn actionBtn">
          <IconReset />
          <div className="btnText">{get(kioskUI, 'actionBtns.restart_btn')}</div>
        </div>
      </div>
    </div>
  );

ActionBtnsPreview.propTypes = {
  kioskUI: PropTypes.object.isRequired,
};

export default ActionBtnsPreview;
