import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Selector from './Selector';

const AsyncSelector = ({
  dataQuery,
  disabled,
  fetchOptions,
  fetchInitialOptions,
  handleChange,
  labelKey,
  multi,
  value,
  valueKey,
  isClearable,
  filterCb,
  ...restProps
}) => {
  const mounted = useRef(false);
  const fetchIdRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const getInitialOptions = useCallback(() => {
    fetchInitialOptions()
      .then((res) => {
        if (mounted.current) {
          setOptions(get(res, `${dataQuery}`) || []);
        }
      })
      .catch(() => {
        console.error('Options could not be fetched'); // eslint-disable-line no-console
      });
  }, [fetchInitialOptions, dataQuery]);

  const handleSearch = (query) => {
    if (!query) {
      return;
    }
    setLoading(true);
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    fetchOptions(query)
      .then((res) => {
        if (fetchId === fetchIdRef.current) {
          setOptions(get(res, `${dataQuery}`) || []);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    mounted.current = true;
    getInitialOptions();

    return () => {
      mounted.current = false;
    };
  }, [getInitialOptions]);


  return (
    <div className="Selector">
      <Selector
        getOptionLabel={o => o[labelKey]}
        getOptionValue={o => o[valueKey]}
        onChange={option => handleChange(option)}
        isLoading={loading}
        isDisabled={disabled}
        onInputChange={handleSearch}
        options={options.filter(filterCb)}
        value={value}
        isClearable={isClearable}
        valueKey={valueKey}
        {...restProps}
      />
    </div>
  );
};

AsyncSelector.propTypes = {
  dataQuery: PropTypes.string,
  disabled: PropTypes.bool,
  fetchOptions: PropTypes.func.isRequired,
  fetchInitialOptions: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  multi: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  valueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isClearable: PropTypes.bool,
  filterCb: PropTypes.func,
};

AsyncSelector.defaultProps = {
  dataQuery: 'data.results',
  disabled: false,
  handleChange: () => {},
  multi: false,
  value: undefined,
  isClearable: false,
  filterCb: () => true,
};

export default AsyncSelector;
