import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ConfirmationPopup,
  Table,
  IconDelete,
  IconEdit,
  Notification,
} from 'shared/components';
import {
  defaultDateFormat,
} from 'shared/constants';
import TopHeader from 'topHeader';
import PromotionPopup from './PromotionPopup';
import { getPromotions, deletePromotion } from './actions';
import './styles.scss';

const Promotions = ({
  userRole,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const locationId = useSelector(state => get(state, 'kiosk.activeLocation.id') || []);

  const [loading, setLoading] = useState(true);
  const [promotions, setPromotions] = useState([]);
  const [promotionToEdit, setPromotionToEdit] = useState(null);
  const [promotionToDelete, setPromotionToDelete] = useState(null);
  const [promotionDeleteLoading, setPromotionDeleteLoading] = useState(false);
  const [promotionDeleteConfirmationPopupDisplay, setPromotionDeleteConfirmationPopupDisplay] = useState(false);
  const [isPromotionFormDisplayed, setPromotionFormDisplay] = useState(false);

  const updatePromotionsList = (promotionToUpdate) => {
    const newList = promotions.map(p => {
      if (p.id === promotionToUpdate.id) {
        return promotionToUpdate;
      }
      return p;
    });
    setPromotions(newList);
  };

  const fetchPromotions = useCallback(() => {
    setLoading(true);
    getPromotions(locationId)
      .then((res) => {
        const data = get(res, 'data.results');
        setPromotions(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [locationId]);

  useEffect(() => {
    fetchPromotions();
  }, [fetchPromotions, locationId]);

  const handlePromotionDelete = () => {
    const promotionToDeleteID = get(promotionToDelete, 'id');
    setPromotionDeleteLoading(true);

    deletePromotion(locationId, promotionToDeleteID)
      .then(() => {
        setPromotionDeleteLoading(false);
        const newPromotionsList = promotions.filter(p => p.id !== promotionToDeleteID);
        setPromotions(newPromotionsList);
        setPromotionDeleteConfirmationPopupDisplay(false);
        setPromotionToDelete(null);
        Notification('success', t('Promjene su uspješno spremljene.'));
      })
      .catch(() => {
        setPromotionDeleteLoading(false);
        Notification('error', t('Promjene nisu uspjele.'));
      });
  };

  const columns = [
    {
      name: t('Naziv promocije'),
      selector: 'name',
      sortable: true,
    },
    {
      name: t('Datum početka'),
      selector: 'time_start',
      sortable: true,
      format: row => moment(get(row, 'time_start')).format(defaultDateFormat),
    },
    {
      name: t('Datum završetka'),
      selector: 'time_end',
      sortable: true,
      format: row => moment(get(row, 'time_end')).format(defaultDateFormat),
    },
    {
      name: t('Broj proizvoda u promociji'),
      selector: 'products',
      sortable: true,
      center: true,
      cell: row => row.products.length,
    },
    {
      name: t('Uredi'),
      selector: 'product_location.is_active',
      sortable: false,
      center: true,
      ignoreRowClick: true,
      maxWidth: '120px',
      cell: row => (
        <div className="delete-cell">
          <Button
            theme="link"
            priority="tertiary"
            onClick={() => {
              setPromotionToEdit(row);
              setPromotionFormDisplay(true);
            }}
          >
            <IconEdit />
          </Button>
        </div>
      ),
    },
    {
      name: t('Obriši'),
      selector: 'product_location.is_active',
      sortable: false,
      center: true,
      ignoreRowClick: true,
      maxWidth: '120px',
      cell: row => (
        <div className="delete-cell">
          <Button
            theme="link"
            priority="tertiary"
            onClick={() => {
              setPromotionToDelete(row);
              setPromotionDeleteConfirmationPopupDisplay(true);
            }}
          >
            <IconDelete />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="Promotions">
      <TopHeader userRole={userRole} />
      <div>
        <Button
          disabled={loading}
          size="sm"
          onClick={() => setPromotionFormDisplay(true)}
        >
          {t('Dodajte promociju')}
        </Button>
        <Table
          columns={columns}
          data={promotions}
          loading={loading}
          onRowClicked={row => history.push(`/promocije/${row.id}`)}
          sortServer={false}
          paginationServer={false}
          clickable
        />
      </div>
      {
        promotionDeleteConfirmationPopupDisplay && (
          <ConfirmationPopup
            closeCb={() => {
              setPromotionToDelete(null);
              setPromotionDeleteConfirmationPopupDisplay(false);
            }}
            confirmCb={handlePromotionDelete}
            confirmText={t('Izbriši')}
            cancelText={t('Poništi')}
            disabled={promotionDeleteLoading}
            theme="error"
            title={t('Jeste li sigurni da želite izbrisati ovu promociju?')}
          >
            <div className="Promotion-popup-name">{promotionToDelete.name}</div>
          </ConfirmationPopup>
        )
      }
      {
        isPromotionFormDisplayed && (
          <PromotionPopup
            isOpen={isPromotionFormDisplayed}
            closeCb={() => {
              setPromotionFormDisplay(false);
              setPromotionToEdit(null);
            }}
            locationId={locationId}
            promotionToEdit={promotionToEdit}
            updatePromotionsList={updatePromotionsList}
            title={t('Dodajte promociju')}
          />
        )
      }
    </div>
  );
};

Promotions.propTypes = {
  userRole: PropTypes.string,
};

Promotions.defaultProps = {
  userRole: '',
};

export default Promotions;
