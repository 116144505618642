import * as actionTypes from './actionTypes';

const initialState = {
  promotionsLoading: true,
  list: null,
  products: [],
};

const getPromotionProducts = (promotions) => {
  let list = [];
  promotions.forEach(p => {
    const { products } = p;
    list = [...list, ...products];
  });
  return list;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getPromotionsInit:
      return { ...state, promotionsLoading: true };
    case actionTypes.getPromotionsSuccess:
      return {
        ...state,
        promotionsLoading: false,
        list: action.promotions,
        products: getPromotionProducts(action.promotions),
        error: undefined,
      };
    case actionTypes.getPromotionsError:
      return { ...state, promotionsLoading: false };
    case actionTypes.refreshPromotions:
      return { ...state, list: action.promotions, products: getPromotionProducts(action.promotions) };
    default:
      return { ...state };
  }
}
