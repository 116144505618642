import React from 'react';
import PropTypes from 'prop-types';

const IconReset = ({ color, size }) => (
  <div className="IconReset">
    <svg viewBox="0 0 192 192" fill={color} width={size} height={size}>
      <path
        d="M24,96c0,39.7,32.3,72,72,72s72-32.3,72-72s-32.3-72-72-72c-21.8,0-41,9.9-54.7,28H72v8H28V16h8v29.8 C51.2,26.5,72.2,16,96,16c44.1,0,80,35.9,80,80s-35.9,80-80,80s-80-35.9-80-80H24z"
      />
    </svg>
  </div>
);

IconReset.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconReset.defaultProps = {
  color: '#37383B',
  size: '16px',
};

export default IconReset;
