/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { capitalizeFirstLetter } from 'shared/helpers';
import './styles.scss';

const RecommendedProductListItem = ({
  product, onProductSelect, ctaText,
// eslint-disable-next-line arrow-body-style
}) => {
  return (
    <div
      role="button"
      className="RecommendedProductListItem"
      onClick={() => onProductSelect(product)}
    >
      <div className="RecommendedProductListItem-image">
        <img src={product.img} alt={product.model} />
      </div>
      <div className="RecommendedProductListItem-title">{product.model}</div>
      <div className="RecommendedProductListItem-description">
        {capitalizeFirstLetter(get(product, 'category.name'))}
      </div>
      <div className="RecommendedProductListItem-cta">{ctaText}</div>
    </div>
  );
};

RecommendedProductListItem.propTypes = {
  ctaText: PropTypes.string.isRequired,
  onProductSelect: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};

export default RecommendedProductListItem;
