import React from 'react';
import PropTypes from 'prop-types';
import { IoIosAdd } from 'react-icons/io';

const IconPlus = ({ color, size }) => (
  <div className="IconPlus">
    <IoIosAdd color={color} size={size} />
  </div>
);

IconPlus.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconPlus.defaultProps = {
  color: '#37383B',
  size: '16px',
};

export default IconPlus;
