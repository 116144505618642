import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import TopHeader from 'topHeader';
import KioskMetaConfig from './KioskMetaConfig';
import KioskBranchingConfig from './KioskBranchingConfig';
import KioskBranchingImport from './KioskBranchingImport';
import KioskBranchingProductsImport from './KioskBranchingProductsImport';
import './styles.scss';

const KioskConfiguration = ({
  userRole,
}) => {
  const { t } = useTranslation();

  return (
    <div className="KioskConfiguration">
      <TopHeader userRole={userRole} />
      <Tabs forceRenderTabPanel={false}>
        <TabsHeader>
          <Tab>{t('Korisničko sučelje')}</Tab>
          <Tab>{t('Grananje')}</Tab>
          <Tab>{t('Uvoz kategorija grananja')}</Tab>
          <Tab>{t('Uvoz proizvoda za grananje')}</Tab>
        </TabsHeader>
        <TabContent>
          <KioskMetaConfig userRole={userRole} />
        </TabContent>
        <TabContent>
          <KioskBranchingConfig userRole={userRole} />
        </TabContent>
        <TabContent>
          <KioskBranchingImport />
        </TabContent>
        <TabContent>
          <KioskBranchingProductsImport />
        </TabContent>
      </Tabs>
    </div>
  );
};

KioskConfiguration.propTypes = {
  userRole: PropTypes.string,
};

KioskConfiguration.defaultProps = {
  userRole: null,
};

export default KioskConfiguration;
