import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const StatusCell = ({
  status,
}) => {
  switch (status) {
    case 'success':
      return (
        <div className="StatusCell">
          <svg width="18px" height="18px" fill="#306d12" viewBox="0 0 48 50">
            <path
              d="M24,5A19,19,0,1,0,43,24,19,19,0,0,0,24,5ZM36.41,18.41l-14,14a2,2,0,0,1-2.82,0l-6-6a2,2,0,0,1,2.82-2.82L21,28.17,33.59,15.59a2,2,0,0,1,2.82,2.82Z"
            />
          </svg>
          {status}
        </div>
      );
    case 'fail':
      return (
        <div className="StatusCell">
          <svg width="15px" height="15px" fill="#AE0721" viewBox="0 0 8 8">
            <path
              d="M4 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-1.5 1.78l1.5 1.5 1.5-1.5.72.72-1.5 1.5 1.5 1.5-.72.72-1.5-1.5-1.5 1.5-.72-.72 1.5-1.5-1.5-1.5.72-.72z"
            />
          </svg>
          {status}
        </div>
      );
    case 'error':
      return (
        <div className="StatusCell">
          <svg width="20px" height="20px" fill="#AE0721" viewBox="0 0 100 100">
            <path d="M50,18A32,32,0,1,0,82,50,32,32,0,0,0,50,18Zm0,50a4,4,0,1,1,4-4A4,4,0,0,1,50,68Zm4-14H46V32h8Z" />
          </svg>
          {status}
        </div>
      );
    case 'running':
      return (
        <div className="StatusCell">
          <svg width="18px" height="18px" fill="#21366D" viewBox="0 0 100 125">
            <g>
              <path d="M50,15c-19.33,0-35,15.67-35,35s15.67,35,35,35s35-15.67,35-35S69.33,15,50,15z M39,70V30l30,20L39,70z" />
              <path
                d="M50,87c-20.4,0-37-16.6-37-37c0-20.4,16.6-37,37-37c20.4,0,37,16.6,37,37C87,70.4,70.4,87,50,87z M50,17   c-18.2,0-33,14.8-33,33s14.8,33,33,33s33-14.8,33-33S68.2,17,50,17z M37,73.74V26.26L72.61,50L37,73.74z M41,33.74v32.53L65.39,50   L41,33.74z"
              />
            </g>
          </svg>
          {status}
        </div>
      );
    case 'pending':
      return (
        <div className="StatusCell">
          <svg width="18px" height="18px" fill="#717171" viewBox="0 0 30 27.5">
            <path
              d="M15,4C8.9,4,4,8.9,4,15s4.9,11,11,11s11-4.9,11-11S21.1,4,15,4z M21.7,16.8c-0.1,0.4-0.5,0.6-0.9,0.5l-5.6-1.1  c-0.2,0-0.4-0.2-0.6-0.3C14.2,15.7,14,15.4,14,15c0,0,0,0,0,0l0.2-8c0-0.5,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8l0.1,6.9l5.2,1.8  C21.6,15.8,21.8,16.3,21.7,16.8z"
            />
          </svg>
          {status}
        </div>
      );
    default:
      return (
        <div className="StatusCell">{status}</div>
      );
  }
};

StatusCell.propTypes = {
  status: PropTypes.string,
};

StatusCell.defaultProps = {
  status: '',
};

export default StatusCell;
