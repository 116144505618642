import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from './BOSCH_RGB.svg';
import RightHeaderMenu from './RightHeaderMenu';
import './styles.scss';

const TopHeader = ({ userRole }) => {
  const [t] = useTranslation();

  return (
    <header className="TopHeader">
      <div className="TopHeader-primary">
        <div className="logo">
          <NavLink to="/">
            <img src={Logo} alt="Logo" height="25x" />
          </NavLink>
        </div>
        {
          (userRole === 'admin' || !userRole) && (
            <nav>
              <NavLink
                to="/"
                // eslint-disable-next-line no-unused-vars
                isActive={(_, l) => {
                  const valid = ['/', '/proizvodi', '/import-proizvoda', '/promocije', '/korisnici'];
                  const path = get(l, 'pathname');

                  return valid.includes(path);
                }}
              >
                {t('Administrator')}
              </NavLink>
              <NavLink to="/blagajna">{t('Blagajna')}</NavLink>
              <NavLink to="/kiosk">{t('Kiosk')}</NavLink>
            </nav>
          )
        }
      </div>
      <div className="TopHeader-secondary">
        <RightHeaderMenu userRole={userRole} />
      </div>
    </header>
  );
};

TopHeader.propTypes = {
  userRole: PropTypes.string,
};

TopHeader.defaultProps = {
  userRole: undefined,
};

export default TopHeader;
