import React from 'react';
import PropTypes from 'prop-types';

const IconAddFilter = ({
  color, size, strokeWidth, disabled,
}) => (
  <div className="IconAddFilter" style={{ display: 'flex' }}>
    <svg viewBox="0 0 192 192" fill={disabled ? '#BFC0C2' : color} height={size} width={size}>
      <g>
        <polygon
          stroke={disabled ? '#BFC0C2' : color}
          strokeWidth={strokeWidth}
          points="148,128 140,128 140,140 128,140 128,148 140,148 140,160 148,160 148,148 160,148 160,140 148,140 "
        />
        <path
          stroke={disabled ? '#BFC0C2' : color}
          strokeWidth={strokeWidth}
          d="M144,112c-17.6,0-32,14.4-32,32s14.4,32,32,32s32-14.4,32-32S161.6,112,144,112z M144,168c-13.2,0-24-10.8-24-24 s10.8-24,24-24s24,10.8,24,24S157.2,168,144,168z"
        />
        <path
          stroke={disabled ? '#BFC0C2' : color}
          strokeWidth={strokeWidth}
          d="M88,160.5V86.8L47.4,24h97.3L104,86.8v48.3c1.4-6.1,4.2-11.8,8-16.5V89.2L159.4,16H32.6L80,89.2v86.3l25.6-17.1 c-1-2.6-1.7-5.3-2.2-8.2L88,160.5z"
        />
      </g>
    </svg>
  </div>
);

IconAddFilter.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  strokeWidth: PropTypes.number,
};

IconAddFilter.defaultProps = {
  color: '#37383B',
  disabled: false,
  size: '16px',
  strokeWidth: 2,
};

export default IconAddFilter;
