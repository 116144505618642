import { get } from 'lodash';
import NoImage from './imgs/noImagePlaceholder.jpg';

export const isInitialStep = (allAnswers, salesArea, customStep = null) => {
  if (salesArea === null) { return false; }
  if (customStep !== null) { return false; }
  const noAnswers = allAnswers.length === 0;
  return noAnswers;
};

export const isDynamicStep = (allAnswers, salesArea, customStep = null) => {
  if (salesArea === null) { return false; }
  if (customStep !== null) { return false; }
  const isMoreThanOne = allAnswers.length >= 1;
  return isMoreThanOne;
};

export const isRecommendationStep = (allAnswers, salesArea, customStep = null, productsCategories = []) => {
  if (salesArea === null) { return false; }
  if (customStep !== null) { return false; }
  if (productsCategories.length) { return true; } // less than 10 products left in remaining branching categories, force recommendation step
  const isMoreThanOne = allAnswers.length >= 1;

  const lastIndex = allAnswers.length - 1;
  const lastAnswer = get(allAnswers, `[${lastIndex}]`);
  const isHasChildrenPropIncluded = lastAnswer && 'has_children' in lastAnswer;
  const doesntHaveChildren = isHasChildrenPropIncluded && !get(lastAnswer, 'has_children');

  return isMoreThanOne && doesntHaveChildren;
};

export const getMainPhoto = (photos) => {
  let productPhoto = NoImage;
  photos.forEach(p => {
    if (p.main) {
      productPhoto = p.photo;
    }
  });
  return productPhoto;
};
