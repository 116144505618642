import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import {
  Spinner,
} from 'shared/components';
import { isRecommendationStep } from 'screens/kiosk/assets/helpers';
import { ReactComponent as NoRecommendationImage } from 'screens/kiosk/assets/imgs/empty-recommendation.svg';
import {
  getTopSalesAreas,
  getSalesAreas,
  getBranchingCategories,
  getRecommendedProducts,
} from './actions';
import OptionsRow from './components/OptionsRow';
import OptionItemEdit from './components/OptionItemEdit';
import RecommendationItem from './components/RecommendationItem';
import './styles.scss';

const KioskBranchingConfig = () => {
  const { t } = useTranslation();
  const locationId = useSelector(state => get(state, 'kiosk.activeLocation.id'));
  const recommendationContainer = useRef();

  const [initialLoading, setInitialLoading] = useState(true);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [topSalesAreas, setTopSalesAreas] = useState([]);
  const [selectedTopSalesArea, setSelectedTopSalesArea] = useState(null);
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState([]);
  const [recommended, setRecommended] = useState(null);

  const [optionToEdit, setOptionToEdit] = useState(null);
  const [isOptionEditFormDisplayed, setOptionEditFormDisplay] = useState(false);

  const scrollToBottom = () => recommendationContainer.current.scrollIntoView({ behavior: 'smooth' });

  const fetchTopSalesAreas = useCallback(() => {
    getTopSalesAreas(locationId)
      .then((res) => {
        const data = get(res, 'data.results') || [];
        setTopSalesAreas([{
          data,
          id: 0,
        }]);
        setSelected([]);
        setOptions(null);
        setRecommended(null);
        setInitialLoading(false);
      })
      .catch(() => {
        setInitialLoading(false);
      });
  }, [locationId]);

  const fetchSalesArea = useCallback((tsa) => {
    setFetchLoading(true);
    getSalesAreas(locationId, get(tsa, 'id'))
      .then((res) => {
        const data = get(res, 'data.results') || [];
        setOptions([{
          data,
          id: 1,
        }]);
        setSelected([]);
        setRecommended(null);
        setFetchLoading(false);
      })
      .catch(() => {
        setFetchLoading(false);
      });
  }, [locationId]);

  const fetchNewOptions = (lastSelected, state) => {
    setFetchLoading(true);

    getBranchingCategories(locationId, lastSelected)
      .then((res) => {
        const data = get(res, 'data.results') || [];
        const ID = state.newOptions.length + 1;
        setOptions([...state.newOptions, { data, id: ID }]);
        setSelected(state.newSelected);
        scrollToBottom();
        setFetchLoading(false);
      })
      .catch(() => {
        setFetchLoading(false);
      });
  };

  const fetchRecommendedProducts = lastSelected => {
    setFetchLoading(true);

    getRecommendedProducts(locationId, lastSelected)
      .then((res) => {
        const data = get(res, 'data.results') || [];
        setRecommended(data);
        scrollToBottom();
        setFetchLoading(false);
      })
      .catch(() => {
        setFetchLoading(false);
      });
  };

  const isTopAreaSelected = id => get(selectedTopSalesArea, 'id') === id;
  const isSelected = (id) => selected.find(s => s.id === id);

  const isTopSalesAreaToEdit = () => {
    if (!optionToEdit) { return false; }
    const optionToEditID = get(optionToEdit, 'id');
    const optionToEditName = get(optionToEdit, 'name');
    const topSalesAreaList = get(topSalesAreas, '[0].data') || [];
    const isTopSalesArea = topSalesAreaList.some(tsa => get(tsa, 'name') === optionToEditName && get(tsa, 'id') === optionToEditID);
    return isTopSalesArea;
  };

  const addNewOptions = (option, index) => {
    const optionsRow = options[index];
    optionsRow.data.push(option);
    const newOptions = options.slice(0, index);
    newOptions.push(optionsRow);
    setOptions(newOptions);
  };

  const setNewState = (val, optionsIndex) => {
    const newOptionsState = options.slice(0, optionsIndex + 1);
    const newSelected = selected.slice(0, optionsIndex);

    setOptions(newOptionsState);
    setSelected([...newSelected, val]);
    setRecommended(null);

    return {
      newOptions: newOptionsState,
      newSelected: [...newSelected, val],
    };
  };

  const handleAnswerSelect = (val, optionsIndex, shouldFetchNewOptions = false) => {
    const updatedState = setNewState(val, optionsIndex);
    const shouldRecommendProducts = isRecommendationStep(updatedState.newSelected);

    if (!shouldRecommendProducts || shouldFetchNewOptions) {
      fetchNewOptions(val, updatedState);
    } else {
      fetchRecommendedProducts(val);
    }
  };

  const handleOptionUpdate = updatedOption => {
    if (isTopSalesAreaToEdit()) {
      const optionID = get(updatedOption, 'id');
      const topSalesAreaList = get(topSalesAreas, '[0].data') || [];
      const updated = topSalesAreaList.map((tsa) => {
        if (tsa.id === optionID) { return updatedOption; }
        return tsa;
      });
      setTopSalesAreas([{
        data: updated,
        id: 0,
      }]);
      return true;
    }
    const updatedOptions = options.map(oArr => {
      const newOArr = oArr.data.map(o => {
        if (o.id === updatedOption.id) {
          const newO = { ...o, ...updatedOption };
          return newO;
        }
        return o;
      });
      return {
        data: newOArr,
        id: oArr.id,
      };
    });
    setOptions(updatedOptions);
    return true;
  };

  useEffect(() => {
    fetchTopSalesAreas();
  }, [locationId, fetchTopSalesAreas]);

  useEffect(() => {
    if (selectedTopSalesArea) {
      fetchSalesArea(selectedTopSalesArea);
    }
  }, [locationId, fetchSalesArea, selectedTopSalesArea]);

  if (initialLoading) {
    return <div className="KioskBranchingConfig"><Spinner /></div>;
  }

  const hasOptions = !!options && !!options.length;
  const hasRecommendedProducts = !!recommended && !!recommended.length;
  const noProductsToRecommend = !!recommended && !recommended.length;

  return (
    <div className="KioskBranchingConfig">
      {
        topSalesAreas && (
          <OptionsRow
            options={topSalesAreas}
            isSelected={isTopAreaSelected}
            fetchLoading={fetchLoading}
            handleAnswerSelect={val => setSelectedTopSalesArea(val)}
            handleEdit={(item) => {
              setOptionToEdit(item);
              setOptionEditFormDisplay(true);
            }}
            selected={[selectedTopSalesArea]}
            locationID={locationId}
            fetchTopSalesAreas={fetchTopSalesAreas}
            fetchSalesArea={fetchSalesArea}
            addNewOptions={addNewOptions}
          />
        )
      }
      {
        hasOptions && (
          <OptionsRow
            options={options}
            isSelected={isSelected}
            fetchLoading={fetchLoading}
            handleAnswerSelect={handleAnswerSelect}
            handleEdit={(item) => {
              setOptionToEdit(item);
              setOptionEditFormDisplay(true);
            }}
            selected={selected}
            selectedTopSalesArea={selectedTopSalesArea}
            locationID={locationId}
            fetchTopSalesAreas={fetchTopSalesAreas}
            fetchSalesArea={fetchSalesArea}
            addNewOptions={addNewOptions}
          />
        )
      }
      <div className="Recommendation-row" ref={recommendationContainer}>
        {
          hasRecommendedProducts && (
            <>
              <div className="step-indicator">
                {`${t('Preporučeni proizvodi')}:`}
              </div>
              <>
                {
                  recommended.map(rp => (
                    <RecommendationItem key={rp.ean} value={rp} />
                  ))
                }
              </>
            </>
          )
        }
        {
          noProductsToRecommend && (
            <div className="empty-recommendation">
              <div className="empty-recommendation-heading">{t('Na osnovu vašeg pretraživanja ne možemo pronaći nijedan proizvod')}</div>
              <NoRecommendationImage />
            </div>
          )
        }
      </div>
      {
        fetchLoading && (
          <div className="Fetch-loading">
            <Spinner />
          </div>
        )
      }
      {
        isOptionEditFormDisplayed && (
          <OptionItemEdit
            option={optionToEdit}
            isOpen={isOptionEditFormDisplayed}
            closeCb={() => {
              setOptionToEdit(null);
              setOptionEditFormDisplay(false);
            }}
            locationID={locationId}
            confirmCb={updatedOption => handleOptionUpdate(updatedOption)}
            isTopSalesArea={isTopSalesAreaToEdit}
          />
        )
      }
    </div>
  );
};

export default KioskBranchingConfig;
