import React from 'react';
import PropTypes from 'prop-types';

const IconChevronRight = ({
  color, size, strokeWidth, disabled,
}) => (
  <div className="IconChevronRight" style={{ display: 'flex' }}>
    <svg fill={disabled ? '#BFC0C2' : color} width={size} height={size} viewBox="0 0 192 192">
      <path
        stroke={disabled ? '#BFC0C2' : color}
        strokeWidth={strokeWidth}
        d="M70.8 158.8l-5.6-5.6L122.3 96 65.2 38.8l5.6-5.6L133.7 96z"
      />
    </svg>
  </div>
);

IconChevronRight.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  strokeWidth: PropTypes.number,
};

IconChevronRight.defaultProps = {
  color: '#37383B',
  disabled: false,
  size: '16px',
  strokeWidth: 2,
};

export default IconChevronRight;
