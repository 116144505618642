import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { IconClose } from 'shared/components';
import './styles.scss';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
    padding: '100px 0',
  },
  content: {
    position: 'relative',
  },
};

const Modal = ({
  children, closeCb, isOpen, title, size, ...rest
}) => (
  <ReactModal
    isOpen={isOpen}
    style={styles}
    onRequestClose={closeCb}
    closeTimeoutMS={150}
    {...rest}
    className={`Modal Modal-size-${size}`}
  >
    <div className="Modal-header">{title}</div>
    <div className="Modal-body">{children}</div>
    <button type="button" className="Modal-dismiss" onClick={closeCb}>
      <IconClose height="16px" />
    </button>
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  closeCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Modal.defaultProps = {
  isOpen: false,
  size: 'md',
  title: '',
};

export default Modal;
