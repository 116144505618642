import React from 'react';
import PropTypes from 'prop-types';

const IconArrowDown = ({
  color, size, strokeWidth, disabled,
}) => (
  <div className="IconArrowDown" style={{ display: 'flex' }}>
    <svg viewBox="0 0 192 192" fill={disabled ? '#BFC0C2' : color} height={size} width={size}>
      <path
        stroke={disabled ? '#BFC0C2' : color}
        strokeWidth={strokeWidth}
        d="M125.2 133.2L100 158.3V16h-8v142.3l-25.2-25.1-5.6 5.6L96 173.7l34.8-34.9z"
      />
    </svg>
  </div>
);

IconArrowDown.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  strokeWidth: PropTypes.number,
};

IconArrowDown.defaultProps = {
  color: '#37383B',
  disabled: false,
  size: '16px',
  strokeWidth: 2,
};

export default IconArrowDown;
