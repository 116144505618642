/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, NavLink } from 'react-router-dom';
import { Notification, Selector } from 'shared/components';
import { languageOptions } from 'shared/constants';
import { useOnClickOutside } from 'shared/hooks';
import HamburgerMenu from 'react-hamburger-menu';
import { logout, logoutSuccess, logoutError } from 'redux/account/actions';
import { changeLang } from 'redux/ui/actions';
import version from 'version';
import './styles.scss';

const RightHeaderMenu = ({
  changeLangAction,
  lang,
  logoutAction,
  logoutSuccessAction,
  logoutErrorAction,
  userRole,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const ref = useRef();
  const btnRef = useRef();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [logoutDisabled, setLogoutDisabled] = useState(false);
  useOnClickOutside(ref, () => setDropdownOpen(false), btnRef);

  const handleLogout = () => {
    setLogoutDisabled(true);

    logoutAction()
      .then(() => {
        logoutSuccessAction();
        history.push('/login');
        Notification('success', t('Uspješno ste odjavljeni'));
      })
      .catch(() => {
        setLogoutDisabled(true);
        logoutErrorAction();
        Notification('error', t('Dogodila se pogreška'), t('Odjava nije uspjela'));
      });
  };

  const handleLanguageChange = data => {
    const lg = get(data, '[0].value') || 'hr';
    changeLangAction(lg);
  };

  const selectedLanguageOption = languageOptions.filter(l => l.value === lang);

  return (
    <div className="RightHeaderMenu">
      <div className="RightHeaderMenu-lang">
        <Selector
          values={selectedLanguageOption}
          options={languageOptions}
          dropdownHandle={false}
          onChange={val => handleLanguageChange(val)}
          dropdownGap={0}
        />
      </div>
      <div className="RightHeaderMenu-hamburger">
        <div className="Hamburger-wrapper" ref={btnRef}>
          <HamburgerMenu
            isOpen={isDropdownOpen}
            menuClicked={() => setDropdownOpen(!isDropdownOpen)}
            width={20}
            height={14}
            strokeWidth={2}
            color="#474E56"
            animationDuration={0.1}
          />
        </div>
        {isDropdownOpen && (
          <div ref={ref} className="Dropdown">
            <div className="version">{`${version}`}</div>
            {
              (userRole === 'admin' || userRole === undefined) && (
                <div className="Dropdown-mobile">
                  <div className="Dropdown-item">
                    <NavLink exact to="/" onClick={() => setDropdownOpen(false)}>
                      {t('Administrator')}
                    </NavLink>
                  </div>
                  <div className="Dropdown-item">
                    <NavLink to="/blagajna" onClick={() => setDropdownOpen(false)}>{t('Blagajna')}</NavLink>
                  </div>
                  <div className="Dropdown-item">
                    <NavLink to="/kiosk" onClick={() => setDropdownOpen(false)}>{t('Kiosk')}</NavLink>
                  </div>
                </div>
              )
            }
            <div className="Dropdown-item">
              <button
                className="logout"
                onClick={handleLogout}
                disabled={logoutDisabled}
                type="button"
              >
                {t('Odjava')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

RightHeaderMenu.propTypes = {
  changeLangAction: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  logoutAction: PropTypes.func.isRequired,
  logoutErrorAction: PropTypes.func.isRequired,
  logoutSuccessAction: PropTypes.func.isRequired,
  userRole: PropTypes.string,
};

RightHeaderMenu.defaultProps = {
  userRole: undefined,
};

const mapStateToProps = (state) => ({
  lang: get(state, 'ui.lang'),
});

const mapDispatchToProps = {
  changeLangAction: changeLang,
  logoutAction: logout,
  logoutErrorAction: logoutError,
  logoutSuccessAction: logoutSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightHeaderMenu);
