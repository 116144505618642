import React from 'react';
import PropTypes from 'prop-types';

const IconDelete = ({ color, size }) => (
  <div className="IconDelete">
    <svg fill={color} width={size} height={size} viewBox="0 0 192 192">
      <path
        stroke={color}
        strokeWidth={5}
        d="M152 56H40V40h112v16zm-12 104c0 4.411-3.59 8-8 8H60c-4.412 0-8-3.589-8-8V64h16v56h8V64h16v56h8V64h16v56h8V64h16v96zM80 24h32c4.411 0 8 3.588 8 8H72c0-4.412 3.589-8 8-8m80 8h-32c0-8.822-7.178-16-16-16H80c-8.823 0-16 7.178-16 16H32v32h12v96c0 8.822 7.178 16 16 16h72c8.822 0 16-7.178 16-16V64h12V32z"
      />
    </svg>
  </div>
);

IconDelete.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconDelete.defaultProps = {
  color: '#EA0016',
  size: '16px',
};

export default IconDelete;
