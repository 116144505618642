import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

const sizes = {
  sm: {
    height: 18,
    width: 34,
  },
  md: {
    height: 28,
    width: 56,
  },
  lg: {
    height: 36,
    width: 72,
  },
};

class Switcher extends Component {
  constructor() {
    super();
    this.state = { checked: false };
    this.handleSwitcherChange = this.handleSwitcherChange.bind(this);
  }

  handleSwitcherChange(checked) {
    const { handleChange } = this.props;

    this.setState({
      checked,
    });
    handleChange(checked);
  }

  render() {
    const { checked } = this.state;
    const {
      desc,
      size,
      ...rest
    } = this.props;
    const { handleChange, ...restProps } = rest;

    return (
      <div className="Switcher">
        <Switch
          aria-label={desc}
          checked={checked}
          onChange={this.handleSwitcherChange}
          onColor="#10ac84"
          height={sizes[size].height}
          width={sizes[size].width}
          {...restProps}
        />
      </div>
    );
  }
}

Switcher.propTypes = {
  desc: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Switcher.defaultProps = {
  desc: 'switcher label',
  size: 'md',
};

export default Switcher;
