import { isJSON, mapUserLocations } from 'shared/helpers';
import { get } from 'lodash';
import * as actionTypes from './actionTypes';

// eslint-disable-next-line quotes
// const initialUI = "{\"step0\":{\"title\":\"Prodajno područje\",\"subtitle\":\"\"},\"step1\":{\"title\":\"Koji Bosch alat trebaš?\"},\"step2\":{\"title\":\"Kaj želiš delati?\"},\"step3\":{\"title\":\"Koji materijal?\",\"subtitle\":\"(odaberi sve koje trebaš)\",\"multiError\":\"Odaberi barem jednu kategoriju\"},\"step4\":{\"title\":\"Kakvo napajanje?\",\"subtitle\":\"\"},\"cartPage\":{\"title\":\"Košarica\",\"confirmOrder\":\"Naruči\",\"backToKioskBtn\":\"Natrag\"},\"actionBtns\":{\"back\":\"Natrag\",\"forward\":\"Potvrdi\"},\"orderPopup\":{\"text\":\"Javite se s ovim brojem na blagajnu.\",\"popupTitle\":\"Vaš broj narudžbe:\"},\"addToCartPopup\":{\"orderBtn\":\"Pregled narudžbe\",\"cancelBtn\":\"Poništi odabir\",\"popupTitle\":\"Proizvod dodan u košaricu\",\"restartBtn\":\"Počni ispočetka\"},\"recommendation\":{\"title\":\"Preporučamo ove Bosch proizvode\",\"restart\":\"Počni ispočetka\",\"ctaText\":\"Stavi u košaricu\"}}";

const initialUI2 = {
  mainTitle: {
    title: 'Kaj potrebujete?',
  },
  cartBtn: {
    title: 'Košarica',
    empty_cart_title: 'Prazna košarica',
    view_order_btn: 'Pregled narudžbe',
    cancel_btn: 'Poništi odabir',
    close_text: 'Zatvori pritiskom bilo gdje izvan skočnog prozora',
  },
  addToCartPopup: {
    title: 'Proizvod dodan u košaricu',
    view_order_btn: 'Pregled narudžbe',
    cancel_btn: 'Poništi odabir',
    continue_btn: 'Nastavi s odabirom',
    close_text: 'Zatvori pritiskom bilo gdje izvan skočnog prozora',
  },
  cartPage: {
    title: 'Košarica',
    order_btn: 'Naruči',
    empty_cart_description: 'Prazna košarica',
  },
  orderPopup: {
    title: 'Vaš broj narudžbe:',
    directions: 'Javite se s ovim brojem na blagajnu.',
    restart_btn: 'Vrati na početak',
  },
  actionBtns: {
    back_btn: 'Natrag',
    restart_btn: 'Počni ispočetka',
  },
  recommendationPage: {
    title: 'Preporučamo ove Bosch proizvode',
    cta_btn: 'Stavi u košaricu',
    empty_title: 'Na osnovu vašeg pretraživanja ne možemo pronaći nijedan proizvod',
  },
  inactivityPopup: {
    title: 'Jesi li još tu? Kiosk će se vratiti na prvi korak za 30 sekundi',
    close_text: 'Zatvori pritiskom bilo gdje izvan skočnog prozora',
  },
};

const getKioskUI = kioskMeta => {
  const isValid = isJSON(kioskMeta);
  if (!isValid) {
    return initialUI2;
  }
  return JSON.parse(kioskMeta);
};

const getActiveCompany = (user) => {
  const mappedUser = mapUserLocations(user);
  return get(mappedUser, 'companies[0]');
};

const getActiveLocation = (user) => {
  const mappedUser = mapUserLocations(user);
  return get(mappedUser, 'companies[0].locations[0]');
};

const initialState = {
  ui: {},
  topSalesArea: null,
  usagesLoading: false,
  usages: [],
  materialsLoading: false,
  materials: [],
  categoriesLoading: false,
  categories: [],
  segmentsLoading: false,
  segments: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.changeKioskUi:
      return { ...state, ui: action.kioskUi };
    case actionTypes.changeActiveCompany:
      return {
        ...state,
        activeCompany: action.company,
        activeLocation: get(action, 'company.locations[0]') || state.activeLocation,
        ui: getKioskUI(get(action, 'company.locations[0].kiosk_meta')),
      };
    case actionTypes.changeActiveLocation:
      return {
        ...state,
        activeLocation: action.location,
        ui: getKioskUI(get(action, 'location.kiosk_meta')),
      };
    case actionTypes.loginSuccess:
    case actionTypes.getUserSuccess:
      return {
        ...state,
        ui: getKioskUI(get(action, 'user.locations[0].kiosk_meta')),
        // activeCompany: get(action, 'user.companies[0]'),
        activeCompany: getActiveCompany(action.user),
        // activeLocation: get(action, 'user.companies[0].locations[0]'),
        activeLocation: getActiveLocation(action.user),
      };
    case actionTypes.updateTopSalesArea:
      return { ...state, topSalesArea: action.payload };
    case actionTypes.getUsagesInit:
      return { ...state, usagesLoading: true };
    case actionTypes.getUsagesSuccess:
      return {
        ...state,
        usagesLoading: false,
        usages: action.usages,
      };
    case actionTypes.getUsagesError:
      return { ...state, usagesLoading: false };
    case actionTypes.refreshUsages:
      return { ...state, usages: action.usages };
    case actionTypes.getMaterialsInit:
      return { ...state, materialsLoading: true };
    case actionTypes.getMaterialsSuccess:
      return {
        ...state,
        materialsLoading: false,
        materials: action.materials,
      };
    case actionTypes.getMaterialsError:
      return { ...state, materialsLoading: false };
    case actionTypes.refreshMaterials:
      return { ...state, materials: action.materials };
    case actionTypes.getCategoriesInit:
      return { ...state, categoriesLoading: true };
    case actionTypes.getCategoriesSuccess:
      return {
        ...state,
        categoriesLoading: false,
        categories: action.categories,
      };
    case actionTypes.getCategoriesError:
      return { ...state, categoriesLoading: false };
    case actionTypes.refreshCategories:
      return { ...state, categories: action.categories };
    case actionTypes.getSegmentsInit:
      return { ...state, segmentsLoading: true };
    case actionTypes.getSegmentsSuccess:
      return {
        ...state,
        segmentsLoading: false,
        segments: action.segments,
      };
    case actionTypes.getSegmentsError:
      return { ...state, segmentsLoading: false };
    case actionTypes.refreshSegments:
      return { ...state, segments: action.segments };
    default:
      return { ...state };
  }
}
