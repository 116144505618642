import React from 'react';
import PropTypes from 'prop-types';
import SlidingPane from 'react-sliding-pane';
import { IconClose } from 'shared/components';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './styles.scss';

const SlidePane = ({
  children,
  isOpen,
  closeCb,
  subtitle,
  title,
  widthPerc,
}) => (
  <SlidingPane
    className="SlidePane"
    overlayClassName="SlidePane-overlay"
    isOpen={isOpen}
    title={title}
    subtitle={subtitle}
    onRequestClose={closeCb}
    closeIcon={<IconClose size="16px" />}
    width={`${widthPerc}%`}
  >
    <div className="SlidePane-inner">{children}</div>
  </SlidingPane>
);

SlidePane.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  closeCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  widthPerc: PropTypes.number,
};

SlidePane.defaultProps = {
  isOpen: false,
  subtitle: '',
  title: '',
  widthPerc: 70,
};

export default SlidePane;
