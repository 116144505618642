import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Page = ({ children }) => (
  <section className="Page">
    <main className="Page-content">{children}</main>
  </section>
);

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]),
};

Page.defaultProps = {
  children: null,
};

export default Page;
