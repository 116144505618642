import api from 'shared/api';

export const addTopSalesAreas = (locationID, data) => {
  const url = `/api/v1/topsalesareas/?location=${locationID}`;

  // --form 'name="TSA Test 1"' \
  // --form 'location="1"' \
  // --form 'photo=@"/Users/tonisredanovic/Downloads/test.jpg"'
  return api.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const addSalesAreas = (locationID, data) => {
  const url = `/api/v1/salesareas/?location=${locationID}`;

  // --form 'name="SA Test 1"' \
  // --form 'location="1"' \
  // --form 'photo=@"/Users/tonisredanovic/Downloads/test.jpg"' \
  // --form 'top_sales_area="3"'
  return api.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const addBranchingCategory = (locationID, data) => {
  const url = `/api/v1/branchingcategories/?location=${locationID}`;

  // root branching
  // --form 'name="BA Test 2"' \
  // --form 'parent=""' \
  // --form 'sales_area="16"' \
  // --form 'location="1"' \
  // --form 'photo=@"/Users/tonisredanovic/Downloads/test.jpg"'

  // child branching
  // --form 'name="BA Child Test 6"' \
  // --form 'parent="1371"' \
  // --form 'sales_area="16"' \
  // --form 'location="1"' \
  // --form 'photo=@"/Users/tonisredanovic/Downloads/test.jpg"'
  return api.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
