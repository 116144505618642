import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const ProgressIndicator = ({
  activeStep,
  totalSteps,
}) => {
  const interval = Number(100 / totalSteps).toFixed(2);
  const percentage = Math.round(interval * activeStep);

  return (
    <div className="ProgressIndicator">
      <div className="ProgressIndicator-fill" style={{ width: `${percentage || 5}%` }} />
    </div>
  );
};

ProgressIndicator.propTypes = {
  activeStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default ProgressIndicator;
