import React from 'react';
import PropTypes from 'prop-types';
// import { get } from 'lodash';
import ReactModal from 'react-modal';
import './styles.scss';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
    padding: '50px 0',
  },
  content: {
    position: 'relative',
    top: '25vh',
  },
};

const AddToCartPopup = ({
  isOpen, handleClose, children, ui, ...rest
}) => (
  <ReactModal
    isOpen={isOpen}
    style={styles}
    onRequestClose={handleClose}
    closeTimeoutMS={150}
    {...rest}
    className="AddToCartPopup"
  >
    <div className="AddToCartPopup-inner">
      <div className="popup-body">
        {children}
      </div>
    </div>
  </ReactModal>
);

AddToCartPopup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

export default AddToCartPopup;
