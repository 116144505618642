import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'shared/components';
import './styles.scss';

const Button = ({
  children,
  disabled,
  onClick,
  size,
  theme,
  loading,
  priority,
  ...rest
}) => (
  <button
    className={`Button Button-size-${size} Button-theme-${theme} Button-priority-${priority} ${loading ? 'Button-loading' : ''}`}
    type="button"
    disabled={disabled}
    onClick={onClick}
    {...rest}
  >
    <>
      {loading && <Loader />}
      {children}
    </>
  </button>
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  theme: PropTypes.oneOf(['default', 'warning', 'success', 'error', 'link']),
  priority: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  onClick: null,
  size: 'md',
  theme: 'default',
  priority: 'primary',
};

export default Button;
