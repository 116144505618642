import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  IconEdit,
  IconDelete,
  Spinner,
  Notification,
  Table,
} from 'shared/components';
import {
  defaultDateFormat,
} from 'shared/constants';
import TopHeader from 'topHeader';
import { getPromotion, removeProductsFromPromotion } from './actions';
import PromotionPopup from '../PromotionPopup';
import AddPromotionProductsPopup from '../AddPromotionProductsPopup';
import './styles.scss';

const PromotionDetails = () => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const userRole = useSelector(state => get(state, 'account.user.role') || null);
  const activeLocation = useSelector(state => get(state, 'kiosk.activeLocation'));
  const locationID = get(activeLocation, 'id');

  const [loading, setLoading] = useState(true);
  const [promotion, setPromotion] = useState(null);
  const [isPromotionFormDisplayed, setPromotionFormDisplay] = useState(false);
  const [isAddProductsFormDisplayed, setAddProductsFormDisplay] = useState(false);
  const [removingProduct, setRemovingProduct] = useState(false);

  const fetchPromotion = () => {
    const { promotionID } = params;

    getPromotion(promotionID, locationID)
      .then((res) => {
        setPromotion(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPromotion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletePromotionProduct = (product) => {
    if (removingProduct) {
      return false;
    }

    setRemovingProduct(true);
    const promotionID = get(promotion, 'id');
    const data = [product.ean];

    removeProductsFromPromotion(locationID, promotionID, data)
      .then((res) => {
        const newPromotion = get(res, 'data');
        setPromotion(newPromotion);
        setRemovingProduct(false);
        Notification('success', t('Promjene su uspješno spremljene.'));
      })
      .catch(() => {
        setRemovingProduct(false);
        Notification('error', t('Promjene nisu uspjele.'));
      });
    return true;
  };

  const columns = [
    {
      name: t('Ime proizvoda'),
      selector: 'model',
      sortable: true,
    },
    {
      name: t('EAN'),
      selector: 'ean',
      sortable: true,
      hide: 'sm',
    },
    {
      name: t('Obriši'),
      selector: 'product_location.is_active',
      sortable: false,
      center: true,
      ignoreRowClick: true,
      maxWidth: '120px',
      cell: row => (
        <div className="delete-cell">
          <Button
            theme="link"
            priority="tertiary"
            onClick={() => deletePromotionProduct(row)}
            disabled={removingProduct}
          >
            <IconDelete />
          </Button>
        </div>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="PromotionDetails">
        <TopHeader userRole={userRole} />
        <div className="PromotionDetails-main">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="PromotionDetails">
      <TopHeader userRole={userRole} />
      <div className="PromotionDetails-main">
        <div className="breadcrumb">
          <button
            type="button"
            onClick={() => history.push('/promocije')}
          >
            {t('Natrag na listu promocija')}
          </button>
        </div>
        <div className="promotion-body">
          <div className="promotion-edit">
            <Button
              disabled={loading}
              size="sm"
              onClick={() => setPromotionFormDisplay(true)}
            >
              <>
                <IconEdit color="#fff" />
                {t('Uredi')}
              </>
            </Button>
          </div>
          <div className="promotion-heading">
            <div className="promotion-name promotion-row">
              <span className="title">{t('Naziv promocije')}</span>
              <span className="content">{promotion.name}</span>
            </div>
            <div className="promotion-date promotion-row">
              <span className="title">{t('Datum početka')}</span>
              <span className="content">{moment(promotion.time_start).format(defaultDateFormat)}</span>
            </div>
            <div className="promotion-date promotion-row">
              <span className="title">{t('Datum završetka')}</span>
              <span className="content">{moment(promotion.time_end).format(defaultDateFormat)}</span>
            </div>
            <div className="promotion-products-number promotion-row">
              <span className="title">{t('Broj proizvoda u promociji')}</span>
              <span className="content">{promotion.products.length}</span>
            </div>
          </div>
          <div className="promotion-products">
            <div className="promotion-products-title">{t('Lista proizvoda')}</div>
            <div className="promotion-add-products">
              <Button
                disabled={loading}
                size="sm"
                onClick={() => setAddProductsFormDisplay(true)}
              >
                {t('Dodaj proizvode')}
              </Button>
            </div>
            <Table
              columns={columns}
              data={promotion.products || []}
              loading={loading}
              // onRowClicked={row => history.push(`/proizvodi/${row.id}`)}
              sortServer={false}
              paginationServer={false}
            // clickable
            />
          </div>
        </div>
      </div>
      {
        isPromotionFormDisplayed && (
          <PromotionPopup
            isOpen={isPromotionFormDisplayed}
            closeCb={() => setPromotionFormDisplay(false)}
            locationId={locationID}
            promotionToEdit={promotion}
            updatePromotionsList={val => setPromotion(val)}
            title={t('Uredi promociju')}
          />
        )
      }
      {
        isAddProductsFormDisplayed && (
          <AddPromotionProductsPopup
            isOpen={isAddProductsFormDisplayed}
            closeCb={() => setAddProductsFormDisplay(false)}
            locationId={locationID}
            promotion={promotion}
            title={t('Dodaj proizvode')}
            updatePromotionsList={val => setPromotion(val)}
          />
        )
      }
    </div>
  );
};

export default PromotionDetails;
