import React from 'react';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';

const isUserAuthorized = (WrappedComponent, user = {}) => {
  const wrapped = () => {
    if (!get(user, 'id')) {
      return <Redirect to="/login" />;
    }

    return <WrappedComponent user={user} />;
  };

  return wrapped;
};

export default isUserAuthorized;
