import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconDownload,
  FileUploader,
  Notification,
  Notice,
  StatusCell,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  Table,
} from 'shared/components';
import {
  formatDate,
} from 'shared/helpers';
import api from 'shared/api';
import {
  getImportList,
  getExportList,
  exportBranching,
} from './actions';
import TableExpandedRow from './components/TableExpandedRow';
import './styles.scss';

const KioskBranchingImport = () => {
  const { t } = useTranslation();
  const fetchImportIdRef = useRef(0);
  const fetchExportIdRef = useRef(0);
  const locationId = useSelector(state => get(state, 'kiosk.activeLocation.id'));
  const validFileTypes = '.xlsx';

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  // import table state
  const [listImportData, setListImportData] = useState([]);
  const [listImportLoading, setListImportLoading] = useState(true);
  // export table state
  const [exportListData, setExportListData] = useState([]);
  const [exportListLoading, setExportListLoading] = useState(true);

  const [branchingExportLoading, setBranchingExportLoading] = useState(false);

  // table methods
  const fetchImportData = async () => {
    // Give this fetch an ID
    const fetchId = ++fetchImportIdRef.current;
    const response = await getImportList(locationId);

    // Only update the data if this is the latest fetch
    if (fetchId === fetchImportIdRef.current) {
      setListImportData(response.data.results);
      setListImportLoading(false);
    }
  };

  // export table methods
  const fetchExportData = async () => {
    // Give this fetch an ID
    const fetchId = ++fetchExportIdRef.current;
    const response = await getExportList(locationId);

    // Only update the data if this is the latest fetch
    if (fetchId === fetchExportIdRef.current) {
      setExportListData(response.data.results);
      setExportListLoading(false);
    }
  };

  useEffect(() => {
    fetchImportData();
    fetchExportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchImportData();
      fetchExportData();
    }, 30 * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const handleWrongFileType = () => {
    // Notification('error', 'Pogrešna vrsta datoteke', Samo .xlsx datoteke se mogu uvesti');
    Notification('error', t('Pogrešna vrsta datoteke'), `${t('Mogu se prenositi samo datoteke')}: ${validFileTypes}`);
  };

  const handleSubmitClick = (val) => {
    const file = get(val, 'file');
    setUploading(true);
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', file);

    api.post(`/api/v1/locations/${locationId}/import_branching_categories/`, data, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      },
    })
      .then(() => {
        Notification('success', t('Datoteka uvezena'), t('Postupak uvoza je započeo'));
        val.remove();
        setUploading(false);
      })
      .catch(() => {
        Notification('error', t('Prijenos nije uspio'), t('Pokušajte ponovo ili promijenite datoteku'));
        setUploading(false);
      });
  };

  const handleItemRemove = () => {
    setUploading(false);
    setProgress(0);
  };

  const handlBranchingExport = () => {
    setBranchingExportLoading(true);
    exportBranching(locationId)
      .then(() => {
        setBranchingExportLoading(false);
        Notification('success', t('Izvoz datoteke je započeo'), t('Link na dokument će ubrzo biti prikazan u listi izvoza'));
        fetchExportData();
      })
      .catch(() => {
        setBranchingExportLoading(false);
        Notification('error', t('Dogodila se pogreška'));
      });
  };

  const columns = [
    {
      name: t('Ime dokumenta'),
      selector: 'file_name',
      sortable: true,
    },
    {
      name: t('Status'),
      selector: 'status',
      sortable: true,
      cell: row => <StatusCell status={row.status} />,
    },
    {
      name: t('Datum'),
      selector: 'time_create',
      sortable: true,
      format: row => formatDate(get(row, 'time_create')),
    },
    {
      name: t('Korisnik'),
      selector: 'user.email',
      sortable: true,
    },
  ];

  const exportColumns = [
    {
      name: t('Ime dokumenta'),
      selector: 'file_name',
      sortable: true,
    },
    {
      name: t('Status'),
      selector: 'status',
      sortable: true,
      cell: row => <StatusCell status={row.status} />,
    },
    {
      name: t('Datum'),
      selector: 'time_create',
      sortable: true,
      format: row => formatDate(get(row, 'time_create')),
    },
    {
      name: t('Korisnik'),
      selector: 'user.email',
      sortable: true,
    },
    {
      name: t('Preuzmi'),
      id: 'edit',
      sortable: false,
      ignoreRowClick: true,
      maxWidth: '120px',
      cell: row => {
        const isSuccess = get(row, 'status') === 'success';
        if (isSuccess) {
          return (
            <div className="product-active-cell">
              <a href={get(row, 'file')} target="_blank" rel="noopener noreferrer">
                <IconDownload size="24px" />
              </a>
            </div>
          );
        }
        return '-';
      },
    },
  ];

  return (
    <div className="KioskBranchingImport">
      <div className="KioskBranchingImport-notice">
        <Notice size="sm" theme="warning" title={t('Upozorenje')}>
          {t('Imajte na umu da će ovo promijeniti postojeće kategorije grananja.')}
        </Notice>
      </div>
      <FileUploader
        disabled={uploading}
        progress={progress}
        onSubmit={handleSubmitClick}
        onWrongFileTypeReject={handleWrongFileType}
        onItemRemove={handleItemRemove}
        label={t('Povucite datoteke ili kliknite da biste ih pregledali')}
        subLabel={`${t('Vrste datoteka')}:`}
        validFileTypes={validFileTypes}
      />
      <Tabs>
        <TabsHeader>
          <Tab>{t('Lista uvoza')}</Tab>
          <Tab>{t('Lista izvoza')}</Tab>
        </TabsHeader>
        <TabContent>
          <div className="KioskBranchingImport-table">
            <Table
              columns={columns}
              data={listImportData}
              loading={listImportLoading}
              defaultSortField="time_create"
              defaultSortAsc={false}
              pagination
              paginationServer={false}
              sortServer={false}
              expandableRows={false}
              expandableRowsComponent={<TableExpandedRow />}
            // clickable
            />
          </div>
        </TabContent>
        <TabContent>
          <Button loading={branchingExportLoading} disabled={branchingExportLoading} size="sm" onClick={handlBranchingExport}>{t('Izvezi novi dokument')}</Button>
          <div className="KioskBranchingImport-table">
            <Table
              columns={exportColumns}
              data={exportListData}
              loading={exportListLoading}
              defaultSortField="time_create"
              defaultSortAsc={false}
              pagination
              paginationServer={false}
              sortServer={false}
              expandableRows={false}
              expandableRowsComponent={<TableExpandedRow />}
            // clickable
            />
          </div>
        </TabContent>
      </Tabs>
    </div>
  );
};

export default KioskBranchingImport;
