import React from 'react';
import PropTypes from 'prop-types';
import { IoIosDownload } from 'react-icons/io';

const IconDownload = ({ color, size }) => (
  <div className="IconDownload">
    <IoIosDownload color={color} size={size} />
  </div>
);

IconDownload.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconDownload.defaultProps = {
  color: '#37383B',
  size: '16px',
};

export default IconDownload;
