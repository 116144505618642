import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ReactComponent as NoRecommendationImage } from 'screens/kiosk/assets/imgs/empty-recommendation.svg';
import NoImage from 'screens/kiosk/assets/imgs/noImagePlaceholder.jpg';
import './styles.scss';

const RecommendationPreview = ({
  kioskUI,
}) => (
    <div className="RecommendationPreview PreviewPane">
      <div className="content">
        <div className="full-recommendation">
          <div className="title">{get(kioskUI, 'recommendationPage.title')}</div>
          <div className="products">
            <div className="product-item">
              <img src={NoImage} alt="placeholder"/>
              <div className="ctaBtn">{get(kioskUI, 'recommendationPage.cta_btn')}</div>
            </div>
            <div className="product-item">
              <img src={NoImage} alt="placeholder"/>
              <div className="ctaBtn">{get(kioskUI, 'recommendationPage.cta_btn')}</div>
            </div>
            <div className="product-item">
              <img src={NoImage} alt="placeholder"/>
              <div className="ctaBtn">{get(kioskUI, 'recommendationPage.cta_btn')}</div>
            </div>
          </div>
        </div>
        <div className="empty-recommendation">
          <div className="title">{get(kioskUI, 'recommendationPage.empty_title')}</div>
          <div className="image-container">
            <NoRecommendationImage />
          </div>
        </div>
      </div>
    </div>
  );

RecommendationPreview.propTypes = {
  kioskUI: PropTypes.object.isRequired,
};

export default RecommendationPreview;
