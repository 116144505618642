/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import {
  Button, TextInput, PasswordInput, Checkbox, Notification,
} from 'shared/components';
import { login, loginError, loginSuccess } from 'redux/account/actions';
import Logo from './BOSCH_RGB.svg';
import { validationSchema } from './validation';
import './styles.scss';

const LoginContainer = ({
  isLoading,
  loginAction,
  loginErrorAction,
  loginSuccessAction,
  userId,
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({ validationSchema });
  const [passVisible, setPassVisible] = useState(false);

  useEffect(() => {
    if (userId) {
      history.push('/');
    }
  }, [history, userId]);

  const onSubmit = (data) => {
    const { email, password } = data;

    if (isLoading) {
      return;
    }

    const loginData = {
      email,
      password,
    };

    loginAction(loginData)
      .then((res) => {
        loginSuccessAction(get(res, 'data.user'));
        history.push('/');
      })
      .catch((err) => {
        loginErrorAction(err);
        Notification('error', t('Nije moguća prijava'), t('Dali ste pogrešne podatke'));
      });
  };

  return (
    <div className="LoginContainer">
      <div className="form-container">
        <form
          className="login-form"
          id="login-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <fieldset disabled={false}>
            <div className="row logo-cont">
              <img src={Logo} alt="bosch logo" height="38px" />
            </div>
            <div className="row input-cont">
              <TextInput
                label={t('Email')}
                id="login-email"
                name="email"
                type="email"
                disabled={isLoading}
                register={register}
                error={t(get(errors, 'email.message'))}
              />
            </div>
            <div className="row input-cont">
              <PasswordInput
                label={t('Lozinka')}
                id="login-pass"
                name="password"
                type={passVisible ? 'text' : 'password'}
                disabled={isLoading}
                register={register}
                showVisibilityBtn={false}
                error={t(get(errors, 'password.message'))}
              />
            </div>
            <div className="row forgot-pass">
              <Checkbox
                onChange={(e) => setPassVisible(e.target.checked)}
                inputId="pass-visibility"
                name="pass-visibility"
                label={t('Prikaži lozinku')}
                disabled={isLoading}
              />
              {/* <a className="login-forgot" href="#">
                {t('Zaboravljena lozinka?')}
              </a> */}
            </div>
            <div className="row btn-cont">
              <Button disabled={isLoading} size="lg" type="submit">
                {t('Prijava')}
              </Button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

LoginContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loginAction: PropTypes.func.isRequired,
  loginErrorAction: PropTypes.func.isRequired,
  loginSuccessAction: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

LoginContainer.defaultProps = {
  userId: null,
};

const mapStateToProps = (state) => ({
  isLoading: get(state, 'account.checkingUser'),
  userId: get(state, 'account.user.id'),
});

const mapDispatchToProps = {
  loginAction: login,
  loginErrorAction: loginError,
  loginSuccessAction: loginSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
