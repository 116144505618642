/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BoxImage from '../BoxImage';
import './styles.scss';

const SingleAnswerBox = ({
  handleSelection, answer, customStyle, isLengthOver15,
}) => {
  const { t } = useTranslation();

  const answerTitle = get(answer, 'name');
  const answerPhoto = get(answer, 'photo');

  const additionalStyle = isLengthOver15 ? { marginBottom: '20px' } : {};

  return (
    <div
      tabIndex={0}
      role="button"
      className="SingleAnswerBox"
      onClick={() => handleSelection(answer)}
      style={{ ...customStyle, ...additionalStyle }}
    >
      <div className="SingleAnswerBox-image">
        <BoxImage img={answerPhoto} />
      </div>
      <div className="SingleAnswerBox-title">{answerTitle || t('Ostalo')}</div>
    </div>
  );
};

SingleAnswerBox.propTypes = {
  handleSelection: PropTypes.func.isRequired,
  answer: PropTypes.object.isRequired,
  customStyle: PropTypes.object,
  isLengthOver15: PropTypes.bool,
};

SingleAnswerBox.defaultProps = {
  customStyle: {},
  isLengthOver15: false,
};

export default SingleAnswerBox;
