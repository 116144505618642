/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  Button,
  DirtyFormAlert,
  Label,
  FileUploader,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import { categoryTypes } from 'shared/constants';
import { addTopSalesAreas, addSalesAreas, addBranchingCategory } from './actions';
import './styles.scss';

const AddOptionForm = ({
  categoryType,
  rowOptions,
  isOpen,
  closeCb,
  confirmCb,
  locationID,
}) => {
  const [loading, setLoading] = useState(false);
  const [isImageRowDirty, setImageRowDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [file, setFile] = useState(null);

  const validImageFormats = '.png,.jpg,.jpeg';

  const [t] = useTranslation();
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(t('Ovo polje je obavezno.')),
  });

  const {
    register, handleSubmit, errors, formState,
  } = useForm({
    validationSchema,
    defaultValues: {
      name: '',
    },
  });

  const handlePatchSuccess = (res) => {
    const newData = get(res, 'data');
    // console.log(newData);
    setLoading(false);
    confirmCb(newData);
    closeCb();
    Notification('success', t('Promjene su uspješno spremljene'));
    // TODO refetch options OR add new one directly in place
  };

  const handlePatchError = () => {
    setLoading(false);
    // console.log('error');
    Notification('error', t('Dogodila se pogreška'));
  };

  const postTopSalesArea = (formData) => {
    addTopSalesAreas(locationID, formData)
      .then(res => handlePatchSuccess(res))
      .catch(err => handlePatchError(err));
  };

  const postSalesArea = (formData) => {
    addSalesAreas(locationID, formData)
      .then(res => handlePatchSuccess(res))
      .catch(err => handlePatchError(err));
  };

  const postBranchingCategory = (formData) => {
    addBranchingCategory(locationID, formData)
      .then(res => handlePatchSuccess(res))
      .catch(err => handlePatchError(err));
  };

  const onSubmit = (data) => {
    const { name } = data;

    if (loading) {
      return;
    }

    setLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('name', name);
    formData.append('location', locationID);
    if (file) {
      formData.append('photo', file);
    }

    switch (categoryType) {
      case categoryTypes.topSalesArea:
        postTopSalesArea(formData);
        break;
      case categoryTypes.salesArea:
        formData.append('top_sales_area', get(rowOptions, '[0].top_sales_area'));
        postSalesArea(formData);
        break;
      case categoryTypes.rootBranchingCategory:
        formData.append('sales_area', get(rowOptions, '[0].sales_area'));
        formData.append('parent', '');
        postBranchingCategory(formData);
        break;
      case categoryTypes.childBranchingCategory:
        formData.append('sales_area', get(rowOptions, '[0].sales_area'));
        formData.append('parent', get(rowOptions, '[0].parent'));
        postBranchingCategory(formData);
        break;
      default:
        break;
    }
  };

  const handleWrongFileType = () => {
    Notification('error', t('Pogrešna vrsta datoteke'), `${t('Mogu se prenositi samo datoteke')}: ${validImageFormats}`);
  };

  const { dirty } = formState;

  const handleClose = () => {
    if (dirty) {
      setDirtyFormAlertDisplay(true);
    } else {
      closeCb();
    }
  };

  return (
    <Modal
      closeCb={handleClose}
      isOpen={isOpen}
      size="sm"
      title={t('Dodaj novu kategoriju')}
    >
      <div className="AddOptionForm">
        <div className="AddOptionForm-row">
          <TextInput
            label={t('Naslov')}
            id="option-name"
            name="name"
            type="text"
            disabled={loading}
            register={register}
            error={get(errors, 'name.message')}
          />
        </div>
        <div className="AddOptionForm-row">
          <Label inputId="file-upload" text={t('Slika')} />
          <FileUploader
            disabled={loading}
            onUploadSuccess={(_file, allFiles) => {
              const val = get(allFiles, '[0].file');
              setImageRowDirty(true);
              setFile(val);
            }}
            onWrongFileTypeReject={handleWrongFileType}
            onItemRemove={() => setFile(null)}
            label={t('Povucite datoteke ili kliknite da biste ih pregledali')}
            subLabel={`${t('Vrste datoteka')}:`}
            validFileTypes={validImageFormats}
          />
        </div>
        <div className="AddOptionForm-row btn-cont">
          <Button disabled={loading} onClick={handleSubmit(onSubmit)}>
            {t('Spremi')}
          </Button>
          <Button disabled={loading} theme="link" priority="tertiary" onClick={handleClose}>
            {t('Poništi')}
          </Button>
        </div>
      </div>
      {
        isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty || isImageRowDirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={() => {
              setDirtyFormAlertDisplay(false);
              closeCb();
            }}
          />
        )
      }
    </Modal>
  );
};

AddOptionForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  locationID: PropTypes.number.isRequired,
  categoryType: PropTypes.string,
  rowOptions: PropTypes.array,
};

AddOptionForm.defaultProps = {
  isOpen: false,
  rowOptions: [],
  categoryType: null,
};

export default AddOptionForm;
