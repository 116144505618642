import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const formatDataDetails = (details) => {
  const data = [];
  Object.entries(details).forEach(([key, value]) => {
    const item = { label: key, value };
    data.push(item);
  });
  return data;
};

const TableExpandedRow = ({
  data,
}) => {
  const { t } = useTranslation();
  const details = formatDataDetails(get(data, 'product.details') || {});

  return (
    <div
      className="TableExpandedRow"
    >
      <div className="data-container">
        <div className="details-heading">{`${t('Dodatni detalji')}:`}</div>
        <table className="details-table">
          <tbody>
            <tr>
              <td>{t('Kod')}</td>
              <td>{get(data, 'product.code') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Opseg isporuke')}</td>
              <td>{get(data, 'product.scope_of_delivery') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Izvor napajanja')}</td>
              <td>{get(data, 'product.power_source') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Napon')}</td>
              <td>{get(data, 'product.voltage') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Snaga')}</td>
              <td>{get(data, 'product.power') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Opseg isporuke')}</td>
              <td>{get(data, 'product.scope_of_delivery') || '-'}</td>
            </tr>
            <tr>
              <td>{t('Upotreba')}</td>
              <td>{data.product.usages.map(u => u.code).join(', ')}</td>
            </tr>
            <tr>
              <td>{t('Materijal')}</td>
              <td>{data.product.materials.map(u => u.code).join(', ')}</td>
            </tr>
            {
              details.filter(d => d.label).map(d => (
                <tr key={d.label}>
                  <td>{t(d.label)}</td>
                  <td>{d.value}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

TableExpandedRow.propTypes = {
  data: PropTypes.object,
};

TableExpandedRow.defaultProps = {
  data: {},
};

export default TableExpandedRow;
