import { get } from 'lodash';
import api from 'shared/api';
import * as actionTypes from './actionTypes';

// promotions actions
const initGetPromotions = (actions) => () => ({
  type: actions.getPromotionsInit,
});

const getPromotionsError = (actions) => () => ({
  type: actions.getPromotionsError,
});

const getPromotionsSuccess = (actions) => (res) => ({
  type: actions.getPromotionsSuccess,
  promotions: res,
});

export const getPromotions = (locationID) => (dispatch) => {
  dispatch(initGetPromotions(actionTypes)());
  return api.get(`/api/v1/featuredgroups/?location=${locationID}&offset=0&limit=100`).then(
    (res) => dispatch(getPromotionsSuccess(actionTypes)(get(res, 'data.results'))),
    () => dispatch(getPromotionsError(actionTypes)()),
  );
};

export const updatePromotions = (res) => ({
  type: actionTypes.refreshPromotions,
  promotions: res,
});
