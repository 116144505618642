import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const RoleLabel = ({
  role,
}) => {
  const { t } = useTranslation();
  if (role === 'admin') {
    return <span>{t('Admin')}</span>;
  }
  if (role === 'store') {
    return <span>{t('Blagajna')}</span>;
  }
  if (role === 'kiosk') {
    return <span>{t('Kiosk')}</span>;
  }
  return <span />;
};

RoleLabel.propTypes = {
  role: PropTypes.string,
};

RoleLabel.defaultProps = {
  role: '',
};

export default RoleLabel;
