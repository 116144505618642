import React from 'react';
import PropTypes from 'prop-types';
import { GoSearch } from 'react-icons/go';

const IconSearch = ({ color, size }) => (
  <div className="IconSearch">
    <GoSearch color={color} size={size} />
  </div>
);

IconSearch.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconSearch.defaultProps = {
  color: '#37383B',
  size: '16px',
};

export default IconSearch;
