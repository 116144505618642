import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Spinner } from 'shared/components';
import {
  getInnerContainerStyle,
  getItemStyle,
  handleACRoomSizeSelect,
} from 'shared/helpers';
import { getBranchingCategories } from './actions';
import StepTitle from '../StepTitle';
import BackBtn from '../BackBtn';
import SliderBox from '../SliderBox';
import SingleAnswerBox from '../SingleAnswerBox';
import { ReactComponent as NoRecommendationImage } from '../../assets/imgs/empty-recommendation.svg';

const DynamicStep = ({
  answers,
  handleProgressChange,
  handleBack,
  handleSelection,
  handleCustomStepSubmit,
  handleLessThan10Products,
  ui,
}) => {
  const lastIndex = answers.length - 1;
  const lastAnswer = get(answers, `[${lastIndex}]`);
  const companies = useSelector(state => get(state, 'account.user.companies') || []);
  const locationId = get(companies, '[0].id');
  const salesArea = get(lastAnswer, 'sales_area') || get(lastAnswer, 'id');
  const hasParent = 'parent' in lastAnswer;

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const fetchNewCategories = () => {
    setLoading(true);

    getBranchingCategories(locationId, lastAnswer)
      .then((res) => {
        const data = get(res, 'data.results') || [];
        const count = get(res, 'data.count') || 3;
        const forceRecommendationStep = get(res, 'data.no_children_bcs_products_count') <= 9;

        if (forceRecommendationStep) {
          const categoriesList = get(res, 'data.no_children_bcs_ids') || [];
          handleLessThan10Products(categoriesList);
          setLoading(false);
        } else {
          setCategories(data);
          handleProgressChange(answers.length, count);
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    fetchNewCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastIndex, lastAnswer]);

  if (loading) {
    return (
      <div className="DynamicStep">
        <div className="KioskStep-answers-loading">
          <Spinner />
        </div>
      </div>
    );
  }

  // check if klime and kvadratura prostora slider
  if (salesArea === 9 && !hasParent) {
    return (
      <div className="DynamicStep">
        <StepTitle title="Kvadratura prostora" />
        <div className="KioskStep-answers">
          <BackBtn ui={ui} handleBack={handleBack} />
          <div className="inner-container">
            <SliderBox handleSliderSelect={() => {}} handleCustomStepSubmit={handleCustomStepSubmit} handleValueSubmit={val => handleACRoomSizeSelect(val, categories, handleSelection)} step={5} />
          </div>
        </div>
      </div>
    );
  }

  // handle custom title here when this will be needed
  const stepTitle = '' || get(ui, 'mainTitle.title');
  const missingBranchingTitle = 'Razvejanje za to kategorijo še ni določeno'; // Grananje za ovu kategoriju još nije definirano

  if (!categories.length) {
    return (
      <div
        className="DynamicStep"
        onDragStart={() => false}
        onDrop={() => false}
      >
        <StepTitle title={missingBranchingTitle} />
        <div className="KioskStep-answers">
          <BackBtn ui={ui} handleBack={handleBack} />
          <div className="inner-container" style={getInnerContainerStyle(categories)}>
            <NoRecommendationImage />
          </div>
        </div>
      </div>
    );
  }

  const isLengthOver15 = categories.length > 15;

  return (
    <div
      className="DynamicStep"
      onDragStart={() => false}
      onDrop={() => false}
    >
      <StepTitle title={stepTitle} />
      <div className="KioskStep-answers">
        <BackBtn ui={ui} handleBack={handleBack} />
        <div className="inner-container" style={getInnerContainerStyle(categories)}>
          {categories.map((c) => (
            <SingleAnswerBox
              key={c.name}
              answer={c}
              handleSelection={handleSelection}
              customStyle={getItemStyle(categories)}
              isLengthOver15={isLengthOver15}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

DynamicStep.propTypes = {
  answers: PropTypes.array.isRequired,
  handleProgressChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
  handleCustomStepSubmit: PropTypes.func.isRequired,
  handleLessThan10Products: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

export default DynamicStep;
