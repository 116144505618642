import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  IconReset,
} from 'shared/components';

const RestartBtn = ({
  disabled,
  handleRestart,
  ui,
}) => (
  <div className="RestartBtn">
    <button
      className="action-btn restart"
      type="button"
      onClick={handleRestart}
      disabled={disabled}
    >
      <IconReset />
      <div className="action-btn-text">{get(ui, 'actionBtns.restart_btn')}</div>
    </button>
  </div>
);

RestartBtn.propTypes = {
  disabled: PropTypes.bool,
  handleRestart: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
};

RestartBtn.defaultProps = {
  disabled: false,
};

export default RestartBtn;
