/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Breadcrumbs = ({
  answers,
  salesArea,
  handleBreadcrumbClick,
  handleRestart,
}) => {
  if (!answers) {
    return null;
  }
  const lastI = answers.length - 1;

  return (
    <div className="Breadcrumbs">
      {
        salesArea && (
          <div
            tabIndex={0}
            role="button"
            className="Breadcrumbs-item"
            key={salesArea}
            onClick={handleRestart}
          >
            {salesArea.name}
            <div className="right-arrow" />
          </div>
        )
      }
      {
        answers.map((a, i) => {
          if (!a || !a.name) {
            return null;
          }
          return (
            <div
              tabIndex={0}
              role="button"
              className="Breadcrumbs-item"
              key={a.id}
              onClick={() => handleBreadcrumbClick(a.id)}
            >
              {a.name}
              <div className="left-arrow" />
              {i !== lastI && <div className="right-arrow" />}
            </div>
          );
        })
      }
    </div>
  );
};

Breadcrumbs.propTypes = {
  answers: PropTypes.array.isRequired,
  salesArea: PropTypes.object,
  handleBreadcrumbClick: PropTypes.func.isRequired,
  handleRestart: PropTypes.func.isRequired,
};

Breadcrumbs.defaultProps = {
  salesArea: null,
};

export default Breadcrumbs;
