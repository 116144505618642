import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Page,
  PageHeader,
  Table,
  LoadingIndicator,
  Button,
  IconSearch,
  TablePagination,
  Label,
  Selector,
} from 'shared/components';
import { isKiosk } from 'shared/helpers';
import {
  defaultDateTimeFormat,
} from 'shared/constants';
import {
  getOrders,
} from './actions';
import TopHeader from '../../../topHeader';
import './styles.scss';

const StoreContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [tablePage, setTablePage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [sortAsc, setSortAsc] = useState('asc');
  const [sortType, setSortType] = useState('');
  const [searchType, setSearchType] = useState({ value: 'id', label: t('Pretraži po broju narudžbe') });
  const [searchQuery, setSearchQuery] = useState('');

  const userRole = useSelector(state => get(state, 'account.user.role') || null);
  const companies = useSelector(state => get(state, 'account.user.companies') || []);
  const activeCompany = useSelector(state => get(state, 'kiosk.activeCompany'));
  const activeLocation = useSelector(state => get(state, 'kiosk.activeLocation'));
  const locationId = get(activeLocation, 'id');

  const fetchOrders = async (page, isLoading = true) => {
    setLoading(isLoading);
    const response = await getOrders(locationId, page, perPage, sortAsc, sortType, searchType, searchQuery);

    setData(response.data.results);
    setTotalRows(response.data.count);
    setTablePage(page);
    setLoading(false);
  };

  const handlePageChange = page => {
    fetchOrders(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await getOrders(locationId, page, newPerPage, sortAsc, sortType, searchType, searchQuery);

    setData(response.data.results);
    setPerPage(newPerPage);
    setTablePage(page);
    setLoading(false);
  };

  const handleSort = async (column, sortDirection) => {
    setLoading(true);
    const { selector } = column;
    const response = await getOrders(locationId, 1, perPage, sortDirection, selector, searchType, searchQuery);

    setSortType(selector);
    setSortAsc(sortDirection);
    setData(response.data.results);
    setTotalRows(response.data.count);
    setTablePage(1);
    setLoading(false);
  };

  const handleEmptySearch = async () => {
    setLoading(true);
    setSearchLoading(true);
    setSearchQuery('');

    const response = await getOrders(locationId, 1, perPage, sortAsc, sortType, searchType, '');

    setData(response.data.results);
    setTotalRows(response.data.count);
    setTablePage(1);
    setLoading(false);
    setSearchLoading(false);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSearchLoading(true);

    const response = await getOrders(locationId, 1, perPage, sortAsc, sortType, searchType, searchQuery);

    setData(response.data.results);
    setTotalRows(response.data.count);
    setTablePage(1);
    setLoading(false);
    setSearchLoading(false);
  };

  useEffect(() => {
    fetchOrders(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isKiosk()) {
    return <Redirect to="/kiosk" />;
  }

  const columns = [
    {
      name: t('Broj narudžbe'),
      selector: 'id',
      sortable: true,
    },
    {
      name: t('Datum'),
      selector: 'created_at',
      sortable: true,
      format: row => moment(get(row, 'created_at')).format(defaultDateTimeFormat),
    },
    {
      name: t('Proizvodi'),
      selector: 'order_products',
      sortable: false,
      cell: row => {
        const val = row.order_products.map(p => p.product.model);
        return val.join(', ');
      },
    },
    {
      name: t('Količina'),
      selector: 'order_products',
      sortable: false,
      cell: row => {
        const quantity = row.order_products.reduce((a, b) => a + b.quantity, 0);
        return quantity;
      },
    },
  ];

  const searchTypeOptions = [
    {
      value: 'id',
      label: t('Pretraži po broju narudžbe'),
    },
  ];

  return (
    <Page>
      <div className="StoreContainer">
        <TopHeader userRole={userRole} />
        <PageHeader title={t('Blagajna')} description={t('Lista narudžbi')}>
          <div className="LocationSelectors">
            <div className="Selector-wrapper">
              <Label text={t('Tvrtka')} inputId="company-selector" />
              <Selector
                options={companies}
                values={[activeCompany]}
                onChange={() => {}}
                labelField="name"
                valueField="id"
                disabled
              />
            </div>
            <div className="Selector-wrapper">
              <Label text={t('Lokacija')} inputId="location-selector" />
              <Selector
                options={get(activeCompany, 'locations')}
                values={[activeLocation]}
                onChange={() => {}}
                labelField="name"
                valueField="id"
                disabled
              />
            </div>
          </div>
        </PageHeader>
        <div className="StoreContainer-search">
          <div className="SearchForm">
            <form className="SearchForm-form" onSubmit={handleSearchSubmit}>
              <Selector
                options={searchTypeOptions}
                values={[searchType]}
                onChange={(val) => setSearchType(get(val, '[0]'))}
              />
              <div className="search-input-wrapper">
                <input
                  disabled={searchLoading}
                  onChange={e => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  placeholder={t('Pretraži')}
                />
                {searchLoading && <LoadingIndicator />}
                {searchQuery && (
                  <button
                    type="button"
                    className="search-clear-btn"
                    onClick={handleEmptySearch}
                  >
                    &times;
                  </button>
                )}
              </div>
              <div className="submit-search-btn">
                <Button type="submit">
                  <IconSearch color="#fff" />
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="StoreContainer-table">
          <Table
            columns={columns}
            data={data}
            loading={loading}
            pagination={false}
            paginationServer
            onSort={handleSort}
            defaultSortField={sortType}
            defaultSortAsc={sortAsc === 'asc'}
            onRowClicked={row => history.push(`/blagajna/${row.id}`)}
            clickable
          />
          <TablePagination
            rowsPerPage={perPage}
            rowCount={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            currentPage={tablePage}
            loading={loading}
          />
        </div>
      </div>
    </Page>
  );
};

export default StoreContainer;
